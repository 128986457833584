var isSwipeEventsAttached = false;

if ($.ui) {
    $.ui.dialog.prototype._focusTabbable = function () {}
}
/**override default focus behavior of jquery ui dialog */

var /*lastDetailImage,*/ resx, upsObj, submitted = false, details, POP_CONT_DIFF = 52,
    /*params,*/swfobject, k = document, t = "", checkString = "cart|login|shipping|itemoptions|billing|payment",
    isiPad = navigator.userAgent.match(/iPad/i) != null, ref = document.referrer;
k.domain = "softsurroundings.com";
//this variable will be used to display different actions. we are assuming the user will be on a mobile phone
var isMobileDevice = window.innerWidth <= 767;
var isMobileOrTabletWidth = window.innerWidth <= 1024;
//var isMobileDevice = ($(window).width() <= 507);
var mediaQexsmall = 360, mediaQmobile = 490, mediaQtablet = 767, mediaQdesktop = 991, mediaQexlarge = 1200,
    mediaQverylarge = 1360;
var styliticsWidget;
/******* add empty alt attribute to any img tags without it for accessibility ***************/
$('img:not([alt])').attr("alt", "");
/************ GLOBAL FUNCTIONS ************/

/* push an event for google analytics / tag manger */
function getImageUrl(imageId, size) {
    return productImageSite + 'products/' + size + '/' + imageId + '.jpg';
}

function dataLayerPush(eventName, productInfo) {
    if (typeof (dataLayer) !== 'undefined') {
        if ((eventName == 'addToCart' || eventName == 'removeFromCart') && productInfo) {
            //Build the product object
            var productsObj = {
                'name': productInfo.name,
                'id': productInfo.id,
                'price': productInfo.price,
                'variant': productInfo.variant,
                'quantity': (productInfo.quantity * 1)
            };
            //Add some properties that we're not sure if they will be set
            if (productInfo.category) {
                productsObj.category = productInfo.category;
            }
            if (eventName == 'addToCart') {
                dataLayer.push({'event': eventName, 'ecommerce': {'add': {'products': [productsObj]}}});
            } else {
                dataLayer.push({'event': eventName, 'ecommerce': {'remove': {'products': [productsObj]}}});
            }
            // Adding to Send Purchase to GA with info on Order
        } else if (eventName == 'purchase') {
            var orderInfo = productInfo.split("|||");
            dataLayer.push({
                'event': 'purchase',
                'order_value': orderInfo[1],
                'order_id': orderInfo[0],
                'enhanced_conversion_data': {
                    "email": orderInfo[2]
                }
            })
        } else {
            dataLayer.push({'event': eventName});
        }
    }
}

//Coremetrics wrapper tags - fallback in case coremetrics doesn't load //SR 58246 //now deprecating these tags
//function callCmSetClientID(arg1,arg2,arg3,arg4){if(typeof(cmSetClientID)!=='undefined'){cmSetClientID(arg1,arg2,arg3,arg4);}}
//function callCmCreateElementTag(arg1,arg2){if(typeof(cmCreateElementTag)!=='undefined'){cmCreateElementTag(arg1,arg2);}}
//function callCmCreatePageviewTag(arg1,arg2,arg3,arg4){if(typeof(cmCreatePageviewTag)!=='undefined'){cmCreatePageviewTag(arg1,arg2,arg3,arg4);}}
//function callCmCreateConversionEventTag(arg1,arg2){if(typeof(cmCreateConversionEventTag)!=='undefined'){cmCreateConversionEventTag(arg1,arg2);}}

function jDialog(opts) {
    if (!$('#dialog-modal').length) {

        $('body').append('<div id="dialog-modal" style="display:none;"></div>');

        // Adding an Event Listener to document when launching a jDialog.
        // This listener will close the dialog (by clicking the x button) when clicking outside it.
        // I had to add an exception for size chart to get it to work right when launching it.
        // The listener is removed when the dialog is closed below.
        /*  document.addEventListener('click', closeBox);*/

        function closeBox(event) {
            if (!event.target.closest(".ui-dialog") && !event.target.closest("#sizechart.cop.sizechart")) {
                if ($(".ui-dialog-titlebar-close") != null) {
                    if ($(".ui-dialog-titlebar-close")[0] != null) {
                        $(".ui-dialog-titlebar-close")[0].click();
                    }
                }
            }
        }
    }
    var className = (opts.className) ? opts.className : 'dialog pop';
    var fluidBool = false;
    if (opts.width) {
        opts.width = (opts.width * 1 + 40); //account for margin on 58738 redesign
    }
    opts.maxWidth = opts.width;
    if (opts.noTop && opts.height && $.isNumeric(opts.height)) {
        opts.height = (opts.height * 1) + 106;
    } //account for title bar height so that passed value will be total height of popup
    if (isMobileDevice) {
        opts.width = 'auto';
        fluidBool = true;
        opts.height = 'auto';
    }

    $('#dialog-modal').html(opts.content).dialog({
        dialogClass: className,
        modal: true, //adds overlay
        title: opts.title,
        width: opts.width,
        height: opts.height,
        maxHeight: opts.height,
        maxWidth: opts.maxWidth,
        fluid: fluidBool,
        open: function (event, ui) {
            if (opts.id) {
                this.id = opts.id;
            }
            var d = $(this).closest('.ui-dialog');
            if (opts.noTop) {
                $(d).find(".ui-dialog-titlebar").hide();
                $(d).find('.ui-dialog-titlebar-close').prependTo('.ui-dialog');
            } else {
                //Add css for title bar
                //$(d).find('.ui-dialog-titlebar').addClass('cont3 bannerhv30'); //deprecated in 58738 redesign
            }
            if (opts.noClose) {
                $(d).find('.ui-dialog-titlebar-close').hide();
            }
            //Add border by creating div and moving elements inside it
            //var ch=$(d).children();
            /*if(!opts.noBorder) { //popBorder deprecated in 58738 redesign
                $(d).append('<div class="popBorder"></div>');
                $(d).find('div.popBorder').append(ch);
            }*/
            setTimeout(function () {
                document.addEventListener('click', closeBox);
            }, 0);
        },
        close: function (event, ui) {
            $(this).dialog('destroy').remove();
            if (opts.close) {
                opts.close();
            }
            // Removing above listener, so we do not continue to check every click.
            document.removeEventListener('click', closeBox);
        }
    });
}

function jFitDialog(opts) {
    if (!$('#dialog-modal')[0]) {
        $('body').append('<div id="dialog-modal" style="display:none;"></div>');
        // Adding an Event Listener to document when launching a jFitDialog.
        // This listener will close the dialog (by clicking the x button) when clicking outside it.
        // I had to add an exception for size chart to get it to work right when launching it.
        // The listener is removed when the dialog is closed below.
        document.addEventListener('click', closeBox);

        function closeBox(event) {
            if (!event.target.closest(".ui-dialog") && !event.target.closest("#sizechart.cop.sizechart")) {
                if ($(".ui-dialog-titlebar-close") != null) {
                    if ($(".ui-dialog-titlebar-close")[0] != null) {
                        $(".ui-dialog-titlebar-close")[0].click();
                    }
                }
            }
        }
    }
    var className = (opts.className) ? opts.className : 'dialog pop';
    var fluidBool = false;

    //formally mobile only options
    fluidBool = true;
    $('#dialog-modal').html(opts.content).dialog({
        position: opts.position,
        dialogClass: className,
        modal: true, //adds overlay
        title: opts.title,
        width: opts.width,
        height: opts.height,
        fluid: fluidBool,
        open: function (event, ui) {
            if (opts.id) {
                this.id = opts.id;
            }
            var d = $(this).closest('.ui-dialog');
            if (opts.noTop) {
                $(d).find(".ui-dialog-titlebar").hide();
                $(d).find('.ui-dialog-titlebar-close').prependTo('.ui-dialog');
            } else {
                //Add css for title bar
                //$(d).find('.ui-dialog-titlebar').addClass('cont3 bannerhv30'); //deprecated in 58738 redesign
            }
            if (opts.noClose) {
                $(d).find('.ui-dialog-titlebar-close').hide();
            }
            //Add border by creating div and moving elements inside it
            //var ch=$(d).children();
            /*if(!opts.noBorder) { //popBorder deprecated in 58738 redesign
                $(d).append('<div class="popBorder"></div>');
                $(d).find('div.popBorder').append(ch);
            }*/
        },
        close: function (event, ui) {
            $(this).dialog('destroy').remove();
            if (opts.close) {
                opts.close();
            }
            // Removing above listener, so we do not continue to check every click.
            document.removeEventListener('click', closeBox);
        }
    });
}

function jDialogClose(el) {
    if (el) { //close the dialog this element is in
        $(el).closest('.ui-dialog-content').dialog('close');
    } else { //close any dialogs
        $('.ui-dialog-content').dialog('close');
    }
}

// run function on all dialog opens
$(document).on("dialogopen", ".ui-dialog", function (event, ui) {
    fluidDialog();
});
// remove window resize namespace
$(document).on("dialogclose", ".ui-dialog", function (event, ui) {
    $(window).off("resize.responsive");
});

function fluidDialog() {
    var $visible = $(".ui-dialog:visible");
    // each open dialog
    $visible.each(function () {
        var $this = $(this);
        var dialog = $this.find(".ui-dialog-content").data("ui-dialog");
        // if fluid option == true
        if (dialog.options.maxWidth && dialog.options.width) {
            // fix maxWidth bug
            $this.css("max-width", dialog.options.maxWidth);
            //reposition dialog
            dialog.option("position", dialog.options.position);
        }
        if (dialog.options.fluid) {
            // namespace window resize
            var wWidth = $(window).width();
            // check window width against dialog width
            if (wWidth < dialog.options.maxWidth + 50) {
                // keep dialog from filling entire screen
                $this.css("width", "90%");
            }
            //reposition dialog
            dialog.option("position", dialog.options.position);

            $(window).on("resize.responsive", function () {
                var wWidth = $(window).width();
                // check window width against dialog width
                if (wWidth < dialog.options.maxWidth + 50) {
                    // keep dialog from filling entire screen
                    $this.css("width", "90%");
                }
                //reposition dialog
                dialog.option("position", dialog.options.position);
            });
        }

    });
}

function addNoEnterSubmit(id) {
    attach('#' + id, 'keydown', function (e) {
        if (e.keyCode == 13) {
            e.stop();
        }
    });
}

function homepageSlideshow() {
    attach(window, "load", function () {
        $('#loading').hide();
        var show = $('#slideshow')[0] && $('#slideshow').bjqs({
            animtype: "fade",
            animspeed: 5500,
            animduration: 1,
            hoverpause: true
        });

        $('#slideNav').on('mouseover', function (event) {
            var hoverRow = event.findElement('li');
            var index = hoverRow.id.substr(3, 1);
            show.gotoSlide(index - 1);
        });
    });
}

/*function iPadPopUp(){//called from index
    if(!localStorage){
        //alert ("no localStorage");
        return true; //true means either there is no local storage support so we don't display popup
    }
    try{
        if (localStorage.iPadPopUp){
            //alert("local stoarge is set");
            //alert(localStorage.iPadPopUp);
            return true; //or true means the popup has already been dismissed
        }
        $("#ipadPopUp").html("<img src=\""+imagessite+"graphics/ipad_app.jpg\" usemap=\"#ipadMap\" alt=\"Ipad App\" />");
        localStorage.iPadPopUp = 1; //1 means it as been dismissed
    } catch (ex){
        return true;
    }
}*/

//new window for home popups //SR 57228 //could either group other new window functions into this one or their own groups
function newWindowHome(opts) {
    if (opts && opts.id) {
        var /*widthX = opts.w, heightY = opts.h,win,*/ pars = '', URL = opts.url;
        /*win = new Window({id:opts.id, className:"pop", width:widthX+"px", height:heightY+"px", resizable:false,destoryOnClose:true, recenterAuto:false, showEffect:Element.show,hideEffect:Element.hide});
         win.element.className='dialog pop';
         win.setZIndex(100);
         win.maxZIndex=100;*/
        //$(opts.id+'_row1').setStyle('display:none');
        //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
        $.ajax({
            type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
                //updateWin(win,t.responseText);
                jDialog({width: opts.w, height: opts.h, id: opts.id, noTop: true, content: t});
            }
        });
    }
}

function updateWin(win, str) {
    $(win).getContent().update(str);
    win.showCenter();
    win.updateHeight();
    win.updateWidth();
}

//sr# 56481 E-mail Popup for Pinterest Visitors on the index page
function pinterestPopup() {
    var opts = {id: 'homePop', w: '434', h: '460', url: '/ajax/pinterestPopup.php'};
    newWindowHome(opts);
}

//sr# 56869 E-mail Popup for Visitors on the index page
function emailSubPopup() {
    //selectCmEventTag('popUpEmailBoxIni');
    var opts = {id: 'homePop', w: '434', h: '350', url: '/ajax/emailSubPopup.php'};
    newWindowHome(opts);
}

function canadaShipPopUp() {
    var opts = {id: 'homePop', w: '434', h: '400', url: '/ajax/intlShippingPopup.php'};
    newWindowHome(opts);
}

function addEmailSub(addFlag, storage) {
    if (addFlag) {
        var URL = "/ajax/subscribe.php", email = $('#emailAddress').val(),
            pars = "&emailAddress=" + email + "&radio=1&theAction=add";
        //var ajax = new Ajax.Request(url,{method: 'POST',parameters: pars,onSuccess: function(data){
        $.ajax({
            type: 'POST', url: URL, dataType: 'json', data: pars, success: function (data) {
                //var data = t.responseText.evalJSON();
                if (data.success == 'true') {
                    updateStorage(storage);
                } else {
                    $('#errors').html(data.errors);
                }
            }
        });
    } else {
        updateStorage(storage);
    }

    function updateStorage(storage) {
        if (storage == 'pin') {
            updateStoragePin();
        } else { //sub
            updateStorageSub();
        }
    }

    function updateStoragePin() {
        if (localStorage) {
            localStorage.ssPinterestEmailPopup = 2;
        } else {
            setCookie('pinPopupCookie', 2, 365);
        }
        $('#homePop').dialog('close');//Windows.close('homePop');
    }

    function updateStorageSub() {
        if (localStorage) {
            localStorage.ssSubEmailPopup = 1;
        } else {
            setCookie('emailSubPopupCookie', 1, 365);
        }
        $('#pin_email_pop')[0].src = "" + imagessite + "/graphics/emailthx_header.jpg";
        $('#pop_text').css('marginTop', "35px");
        $('#pop_text').html("You will receive your first email shortly.");
    }
}

function setCookie(cName, value, exdays, path) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var cValue = value + "; expires=" + exdate.toGMTString();
    if (path) cValue += ";path=" + path;
    document.cookie = cName + "=" + cValue;
}

function setSoftCookie(name, value, mins) {
    var expires = "";
    var secure = "";

    if (location.protocol == "https:") {
        secure = "secure";
    }

    if (mins) {
        var date = new Date();
        date.setTime(date.getTime() + (mins * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }

    if (sameSiteCompatible === "true") {
        document.cookie = name + "=" + value + ";" + expires + ";path=/;samesite=none;" + secure;
    } else {
        document.cookie = name + "=" + value + ";" + expires + ";path=/;" + secure;
    }
}

function pinterestPinItClick() {
    var prodName;
    if ($('#productName')[0]) {
        prodName = $('#productName').text();
    }
    //callCmCreateElementTag('PinterestPinItButton',prodName);
}

/*function sendCmJanrain(e){
 var ele=Event.element(e)
 var socialNetwork = ele.parentElement.id;
 var page = getCurrentPage();
 if(page == 'login'){
 page = 'checkout/login';
 }
 cmCreateElementTag('JanrainSocialLogin_' + socialNetwork + '_' + page,'janrain');
 }*/
function selectCmEventTag(eventName) {
    //var referer = ref.split('/').pop();
    //referer = referer.replace(".php","");
    switch (eventName) {
        case "writeAReviewCom"://event 1; iniation tag in bvDetailWriteReviewClick()
            //callCmCreateConversionEventTag('WriteAReview','2');
            break;
        case "topFooterIni"://event 2 - footer.php
            //callCmCreateConversionEventTag('TopFooter','1');
            break;
        case "topFooterCom"://subscribe.php
            //callCmCreateConversionEventTag('TopFooter','2');
            break;
        case "faqSubscribeIni"://event 3 -- webadmin
            //callCmCreateConversionEventTag('FAQsSubscribe','1');
            break;
        case "faqSubscribeCom": //webadmin
            //callCmCreateConversionEventTag('FAQsSubscribe','2');
            break;
        case "newAccountIni"://event 4 -- header
            //callCmCreateConversionEventTag('NavigationCreateNewAccount','1');
            break;
        case "newAccountCom": // loginpage.php
            //callCmCreateConversionEventTag('NavigationCreateNewAccount','2');
            break;
        case "footerLinkCom": //may have to wait for proactive chat -- js actually called in JS in libchat.php -- not added
            //callCmCreateConversionEventTag('FooterLink','2');
            break;
        case "popUpEmailBoxIni": //SR 56869 - email pop up
            //callCmCreateConversionEventTag('PopUpEmailBox','1');
            break;
        case "popUpEmailBoxCom": //SR 56869 - email pop up
            //callCmCreateConversionEventTag('PopUpEmailBox','2');
            break;
    }
}

function selectCmElementTag(eventName, value) {
    if (value != '') {
        var name = value.substr(0, value.length - 1);
        var brand = name.substr(name.lastIndexOf("/") + 1);
    }
    switch (eventName) {
        case "beautyBrandsClick":
            //callCmCreateElementTag('Beauty Brands','beautyBrands - beauty pages');
            break;
        case "beautyBrandsChoice":
            //callCmCreateElementTag('Beauty Brands Choice',brand);
            break;
        case "forgotPassword":
            //callCmCreateElementTag('forgotPassword','forgot password');
            break;
        case "pinterestEmailBox"://SR 56575 CM Tagging on Pinterest Pop-Up
            //callCmCreateElementTag('pinterestPopUp','pinterest pop up');
            break;
        case "emailSignUpHeader":
            //callCmCreateElementTag('EmailSignUpHeader','email sign up link - header');
            break;
        case "guestCheckOut":
            //callCmCreateElementTag('guestCheckOut','guest check out');
            break;
        case "footerLinkIni"://event 5 - header.php
            //callCmCreateElementTag('LiveChat','live chat - footer');
            break;
    }
}

function recentInfo() {
    if ($("#recentlyviewed2_rr")[0]) {
        if (typeof recent === 'undefined') {
            recent = $("#recentlyviewed2_rr").html();
        } else
            $("#recentlyviewed2_rr").html(recent);
    }
}

function mayAlsoLikeInfo() {
    if (typeof details === 'undefined') {
        details = $("#product_ms2_rr").html();
    } else
        $("#product_ms2_rr").html(details);
}

/* add overlay to page for loading*/
function showOverlay(msg) {
    $('body').prepend('<div class="overlay"><div class="loader"></div><div class="overlaytext">' + msg + '</div></div>');
}

function removeOverlay() {
    $('.overlay').remove();
}

function updateOverlayMsg(msg) {
    $('.overlaytext').html(msg);
}

function getRadioValue(radioObj) {
    if (!radioObj) {
        return "";
    }
    var radioLength = radioObj.length;
    if (radioLength == undefined)
        if (radioObj.checked) {
            return radioObj.value;
        } else {
            return "";
        }
    for (var i = 0; i < radioLength; i++) {
        if (radioObj[i].checked) {
            return radioObj[i].value;
        }
    }
    return "";
}


var talCustProp = "";//Must be globalized for proactive chat js
setProactiveVariables();

function setProactiveVariables() {
    var delim = "#:Prop!", checkVariables = [/*"cartTotalDollars",*/"noResultsCount", "checkoutErrorCount"];
    for (var i = 0; i < checkVariables.length; i++) {
        if ($('#' + checkVariables[i])[0]) {
            var value = $('#' + checkVariables[i]).html();
            talCustProp += checkVariables[i] + "=" + value + delim;
        }
    }
}

function createQuickOrderUids() {
    if ($('#quickOrderForm')[0]) {
        var ele = $('div.detailOutfit'), uIdCount, parentEleInfo, parentTd;
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].tagName == 'DIV') {
                var elementInfo = ele[i].id.split("_");
                if (!$('#oneofakindsold_' + ele[i].id)[0]) {
                    if (elementInfo.length == 2) {
                        uIdCount = $('#uIdCount')[0];
                        uIdCount.value++;
                        ele[i].id = ele[i].id + '_' + uIdCount.value;
                        $('#skus')[0].value += ele[i].id + ',';
                    }
                }
            }
        }
        ele = $('select.dtl_bd_sel,input.dtl_bd_sel,input.hiddenMono,a.hiddenMono');
        for (i = 0; i < ele.length; i++) {
            if (ele[i].tagName == 'SELECT' || ele[i].tagName == 'INPUT' || ele[i].tagName == 'A') {
                var eleUniIdSplit = getEleUniqueId(ele[i]);
                var divider = "_";
                if (eleUniIdSplit != undefined) {
                    var uniId = eleUniIdSplit;
                }
                elementInfo = ele[i].id.split("_");
                var descLength = 3, addBeforeUid = false;
                /*if(elementInfo[0]=='personalizeId'||elementInfo[0]=='customText'){
                    descLength=2;
                }*/
                if (elementInfo[0] == 'detailmono' || elementInfo[0] == 'monoplus') {
                    descLength = 1;
                    addBeforeUid = true;
                    divider = "-";
                }
                if (elementInfo.length == descLength) {
                    parentTd = getParentTd(ele[i]);
                    if (uniId == "undefined") {
                        parentEleInfo = parentTd.id.split("_");
                        if (addBeforeUid) {
                            ele[i].id += divider + parentEleInfo[1];
                        }
                        ele[i].id += divider + parentEleInfo[2];
                    } else {
                        ele[i].id += divider + uniId;
                    }
                    ele[i].name = ele[i].id;
                }
            }
        }
    }
}

/*function addQuickOrderTextBoxs(E){
    E.preventDefault();
    $('#lastLine')[0].value++;
    var line=$('#lastLine')[0].value;
    for(var i=1;i<=3;i++){
        var textBox = document.createElement("input");
        textBox.setAttribute("type", "text");
        textBox.setAttribute("class", "gen_input");
        textBox.setAttribute("maxLength", "5");
        textBox.setAttribute("name", 'item'+line+'_'+i);
        var parent = $("#quickOrderTextBoxDiv")[0];
        parent.appendChild(textBox);
        parent.appendChild(document.createTextNode(" "));
    }
    parent.appendChild(document.createElement("br"));
}*/
/*if($("#quick_table")[0]){
    attach('#addLine','click',addQuickOrderTextBoxs);
    attach('#addLineImg','click',addQuickOrderTextBoxs);
}*/
/*if($("#quick_bt_additem")[0]){
    attach('#quick_bt_additem','click',addQuickOrderProduct);
    attachDetailBeddingEvents();
}*/
/*function addQuickOrderProductText(e){ //not used - commented out 10/15
 if (window.event){var key = window.event.keyCode;}else if (e){var key = e.which;}else{return true;}
 if(key==13){addQuickOrderProduct(e);}
 }*/

/*function addQuickOrderProduct(E){
    E.preventDefault();
    $('#errMsg').html('');
    var URL='/quickorder/',skus= $('#skus').val(),itemNo=$('#addItemText').val();
    //$('skus').value+=itemNo+',';
    $('#addItemText').val('');
    var uniqueIds=$("#uniqueIds").val();
    //if($('item_'+itemNo)){
    //$('errMsg').innerHTML='You have already added item #'+itemNo+'.';
    if(itemNo!=''){
        //$('errMsg').innerHTML='Please enter an item number.';
        //}else{

        //var ajax = new Ajax.Request('quickOrderReview.php?ajaxItem='+itemNo+'&skus='+skus,{method: 'POST',onComplete: function(t) {
        var pars='ajaxItem='+itemNo+'&skus='+skus+'&uniqueIds='+uniqueIds;
        $.ajax({type:'POST',url:URL,dataType:'json',data:pars,success:function(t){
            if(!t.success){
                $('#errMsg').html('Item(s) not found: #'+itemNo+'. For items that could not be found, please check to make sure you entered a valid 5-digit item number. If the item number is correct, the item is no longer in stock and is not available in our current catalog or outlet website.');
            }else{
                $('#detailOutfits').append('<div class="prod_spacer outfitSpacer"></div>');
                $('#detailOutfits').append(t.pageupdate);
                //var test=$("#uniqueIds").val();
                $("#uniqueIds").val(t.uniqueIds);
                //var test2=$("#uniqueIds").val();
                attachDetailBeddingEvents();
            }
        }});
    }
}*/
function getCurrentPage() {
    var page = window.location.pathname.toString().replace("checkout/", "");
    page = page.replace("/", "");
    page = page.replace(".php", "");
    //var pattern = /(p\/|detail)/i;
    //var outfit = /outfit\//i;
    //var orderentry = /orderentry\//i;
    //var productreviews = /productreview\//i;
    /*if(pattern.test(page) && page.charAt(page.length-1) == "/"){ page = "detail";}
    else if(outfit.test(page) && page.charAt(page.length-1) == "/"){page ="outfit";}
    else if(orderentry.test(page) && page.charAt(page.length-1) == "/"){page ="orderentry";}
    else if(productreviews.test(page) && page.charAt(page.length-1) == "/"){page ="productreviews";}
    else if(page.charAt(page.length-1) == "/"){page="search";}*/
    if (page.match("^p/") || page.match("detail")) {
        page = "detail";
    } //detail needs to go first so product with 'Cart' in name doesn't set as cart
    else if (page.match('cart/')) {
        page = "cart";
    } else if (page.match("faq")) {
        page = 'faq';
    } else if (page.match('shipping')) {
        page = "shipping";
    } else if (page.match("billing")) {
        page = "billing";
    } else if (page.match("payment")) {
        page = "payment";
    } else if (page.match("itemoptions")) {
        page = "itemOptions";
    } else if (page.match("login")) {
        page = "login";
    }
    //else if(page.match("orderentry/")){page="orderentry";}
    else if ($("#thumbs")[0] || $('#thumbheader')[0] || $('#main_cont_thumbs')[0]) {
        page = "thumbs";
    } else if (page.match("s/")) {
        page = "search";
    }
    return page;
}

var page = getCurrentPage();

function pop(newwin) {
    flyout = window.open(newwin, "flyout", "resizable=yes,scrollbars=yes,width=700,height=750,top=0,left=0,toolbar=no,status=no,menubar=no");
}

function pop2(newwin, wi, hi, xpos, ypos) {
    flyout = window.open(newwin, "flyout", "resizable=yes,scrollbars=yes,width=" + wi + ",height=" + hi + ",top=" + ypos + ",left=" + xpos + ",toolbar=no,status=no,menubar=no");
}

function f_filterResults(n_win, n_docel, n_body) {
    var n_result = n_win ? n_win : 0;
    if (n_docel && (!n_result || (n_result > n_docel))) {
        n_result = n_docel;
    }
    return n_body && (!n_result || (n_result > n_body)) ? n_body : n_result;
}

function f_scrollTop() {
    return f_filterResults(
        window.pageYOffset ? window.pageYOffset : 0,
        k.documentElement ? document.documentElement.scrollTop : 0,
        k.body ? k.body.scrollTop : 0
    );
}

/*function continueShopping(E){
    E.preventDefault();
    var selector=$('#contShop')[0];
    if(selector){
        // Wait for tag to be thrown
        if( (selector.options[ selector.selectedIndex].text,"Cart_Continue_Shopping","","1",window.location.toString())!=null){
            window.location = selector.options[ selector.selectedIndex].value;
        }
    }
}*/

function readCookie(name) {
    var nameEQ = name + "=", ca = k.cookie.split(';'), i, c;
    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function embedFlashVideo(url, vidWidth, vidHeight, divid, id) {
    var flashvars = {
        autostart: 'true',
        themeColor: '2b7186',
        mode: 'overlay',
        scaleMode: 'fit',
        frameColor: 'efebe2',
        fontColor: '804825'
    };
    var params = {
        allowFullScreen: 'true',
        loop: 'true',
        quality: 'high',
        bgcolor: '#ffffff',
        play: 'true',
        wmode: 'opaque',
        scale: 'showall',
        menu: 'true',
        devicefont: 'false',
        allowScriptAccess: 'always'
    };
    var attributes = {id: id, name: 'myPlayer'};
    swfobject.embedSWF(url, divid, vidWidth, vidHeight, '9.0.0', 'media/video/expressinstall.swf', flashvars, params, attributes);
}

//sr#56296 Detail page video on iOS systems
//This project intially started as making only mp4 files and using the flash fallback feature on OVP for the web,
//however that swf player doesn't provide us with the functionality CP wanted and that we already had(thumbnail video playing, looping)
//Now mp4 will be only played on ipads, and the regular swfs will be made as they usually are as well
//JWPlayer no longer needed to create videos. Handled in switchDetailVideo()
/*
function embedHTML5Video(url, vidWidth, vidHeight, divid, id, thumbVideo, baseId, controls, autostart, image){
    var /!*watermark='', thumbLink='',*!/ runOnce=true;
    var controls = typeof controls !== 'undefined' ? controls : false;
    var autostart = typeof autostart !== 'undefined' ? autostart : true;
    var image = typeof image !== 'undefined' ? image : false;
    /!*if(thumbVideo){
     watermark = '/media/video/watermark.png';
     thumbLink='javascript:switchDetailVideo("'+baseId+'")';
     }*!/

    if(isiPad){controls=true;}
        if(typeof(jwplayer)!=='undefined'){
            jwplayer(divid).setup({
                file: url,
                image: image,
                width: vidWidth,
                height: vidHeight,
                controls:controls,
                autostart:autostart,
                repeat:false,
                primary:'html5',
                abouttext:'Soft Surroundings Video Player',
                aboutlink:'http://www.softsurroundings.com'
            });
            jwplayer().onReady(function() {
                if(runOnce){
                    runOnce=false;
                    var videoTag = $('#'+divid);
                    if(jwplayer().getRenderingMode()=='flash'){
                        $(videoTag).parent().addClass(id);
                        var element = videoTag.parent();
                        //element.setStyle("z-index:40");
                        //element.up().setStyle("z-index:50");
                        $(element).append("<a class='thumb_video_link'></a>");
                    }else{
                        $(videoTag).addClass(id);
                    }
                    attach('.'+id,'click',function(){switchDetailVideo(baseId);});
                }

            });
            jwplayer().onComplete(function() {
                switchDetailPhoto(baseId,'','');
            });
    }
}
*/

function fileExists(url) {
    var httpFileTest = new XMLHttpRequest();
    httpFileTest.open('HEAD', url, false);
    httpFileTest.send();
    return httpFileTest.status != 404;
}

function getNumCartItems() {
    var cartCookie = decodeURI(readCookie("CartCookie")).split('|'), numItems = 0;
    if (cartCookie) {
        numItems = cartCookie[1];
    }
    return numItems;
}

function updateShoppingBagNumber() {
    var numItems = getNumCartItems();
    var str = (numItems * 1);
    $('#bagNumber').html(str);
}

function getConciergeShoppingBag() {
    var numItems = getNumCartItems();
    if (numItems == 1) {
        items = "item";
    }
    str = "<div id=\"bag\" class=\"png\"><img alt=\"bag\" src=\"" + imagessite + "graphics/alttext.gif\" /></div>";
    str += '<a id=\"shoplink\" href="/cart/" rel="nofollow">';
    str += "Shopping Bag</a>:&nbsp;" + (numItems * 1);
    str += '&nbsp;' + items + "";
    //str+= '&nbsp;'+items+'<span>Total&nbsp;$'+(price*1).toFixed(2)+'</span>';
    $('#shoppingBag').html(str);
}

function getGenericImage(img) {
    var i, j;
    i = img.substr(0, 1) * 1;
    j = i;
    //if(outlier==1){if(i==7||i==8||i==9){j=1;}}
    if (i == 3 || i == 4 || i == 5) {
        j = 2;
    }
    return j + img.substr(1, 4);
}

/** basic form validation: returns true if any input has a value, returns false if all are blank or if form has already been submitted (i.e. double-click)*/
function blankFormCheck(formObject) {
    var validForm = false;
    if (submitted !== true) {//keep the forms from being submitted more than once
        var field, skippedFields = new RegExp("(prefixId|nextPage|shipToId|businessFlag)");
        for (var x = 0; x < formObject.length; x++) {//loop through each form object
            field = formObject.elements[x];
            if (!(skippedFields.test(field.name))) { //not a skipped field
                if (!(field.type && (field.type == "hidden" || field.type == "submit"))) { //not non-editable input
                    if (field.type && field.type == "radio") {
                        if (field.checked) { //if any radio is checked
                            validForm = true;
                            break;
                        }
                    } else if (field.value.length > 0) { //if any input has a value
                        validForm = true;
                        break;
                    }
                }
            }
        }
        if (validForm) {
            submitted = true;
        }
    }
    return validForm;
}//end blankFormCheck()

function removeOptions(select, length) {
    length--;
    for (var i = length; i >= 0; i--) {
        select.remove(i);
    }
}

function loadDetailDataResponse(response, requstedParts) {
    for (var i = 0; i < requstedParts.length; i++) {
        var id = requstedParts[i];
        var divId = id + '-' + response.uniqueId;
        if ($('#' + divId)[0]) {
            $('#' + divId)[0].innerHTML = response[id];
        }
    }
}

function handleSwatchChangeSuccess(jsonDetail) {
    var ajaxTextHeader = decodeURIComponent(jsonDetail.productHeader).replace(/\+/g, ' ');
    var ajaxTextDesc = decodeURIComponent(jsonDetail.productDesc).replace(/\+/g, ' ');
    var ajaxTextBulk = decodeURIComponent(jsonDetail.productBulk).replace(/\+/g, ' ');
    var ajaxTextDetail = decodeURIComponent(jsonDetail.productDetail).replace(/\+/g, ' ');
    // var newFitAnalyticsData = jsonDetail.fitAnalyticsData;

    var isQuickView = ($("#quickWinDetail")[0] ? true : false);

    var headerSelector = (isQuickView) ? '#quickWinDetail' : '#detail';
    var bulkSelector = (isQuickView) ? '#quickWinDetail' : '#detail';

    if (!isQuickView) {
        var chat = $('#ssReactiveChatEleIdFooter').detach();//need to grab this element to add after detail part is updated
    }

    $(headerSelector + ' .dtlHeader').html(ajaxTextHeader);
    $(bulkSelector + ' .dtlFormBulk').html(ajaxTextBulk);
    if (!isQuickView) { //this one only exists on the detail page
        $('#detail .dtlDesc').html(ajaxTextDesc);
        $('#detail .detailItemCopy').html(ajaxTextDetail);
    }

    if (!isQuickView) {
        $('.lucy').before(chat);//insert after the html has been changed
    }
    //$('#quickWin').dialog("option", "height", "auto");
    // updateFitAnalyticsPDPData(newFitAnalyticsData);
    setTimeout(lastSavedSpecTwoHandler, 0);
}

function updateFitAnalyticsPDPData(newFitAnalyticsData) {
    //this variable will already exists - it is created at page load
    fitAnalyticsData = newFitAnalyticsData;
}

function loadUpPars(itemForm, skip) {
    var pars = "", add;
    if (itemForm) {
        for (var x = 0; x < itemForm.length; x++) {
            add = true;
            if (itemForm.elements[x].type == "radio" && !itemForm.elements[x].checked) {
                add = false;
            }
            if (itemForm.elements[x].type == "checkbox" && !itemForm.elements[x].checked) {
                add = false;
            }
            if (skip) {
                if (itemForm.elements[x].name == 'pageType' && pars.indexOf('pageType')) {
                    add = false;
                }
                if (itemForm.elements[x].name == 'action') {
                    add = false;
                }
            }
            if (add) {
                if (x !== 0) {
                    pars += "&";
                }
                pars += itemForm.elements[x].name + "=" + itemForm.elements[x].value.replace(/&/g, "%26"); //load up form parameters - encode the amp to prevent escaped values, may possibly need to encodeURIComponent() for more chars
            }
        }
        pars = pars.replace(/\?/g, '*.*');
    }
    return pars;
}


/*********************************
 * Function Name:detailSpecChange(
 * purpose: to change swatches and sizes using Ajax on the quickView and detail pages
 *********************************/
function detailSpecChange(E) {
    if (E.which === 1 || E.which === 13) { // allow mouse clicks and enter button - ADA compliance
        E.preventDefault();
        var element = E.target, itemForm = $(element).closest('form')[0], uniqid = itemForm.uniqid.value;
        if ($(element).closest(".swatchHover").length) { //if we clicked inside a swatchHover, find the matching color swatch //58932
            element = $(element).closest(".swatchlink").find(".color")[0];
        }
        var id = element.id.split("_");
        if (element.nodeName == "SELECT") {
            id[1] = element.value;
        }
        //while(itemForm.tagName!="FORM"){itemForm = itemForm.parentNode;}
        if (id[0] == "color") {
            itemForm.elements['specOne-' + uniqid].value = id[1];
        }
        if (id[0] == "size") {
            itemForm.elements['specTwo-' + uniqid].value = id[1];
        }
        if (id[0] == "sizecat") {
            itemForm.elements['sizecat-' + uniqid].value = id[1];
        }

        var baseId = itemForm.sizecat_desc.value.toLowerCase(); //the original baseId
        var sendUrl = loadUpPars(itemForm) + "&ajax=1",
            newBaseId = itemForm.elements['sizecat-' + uniqid].value.toLowerCase(); //the new baseId (may be the same)
        var clickFromWin = 0;
        if ($(itemForm).parent().hasClass('win_detail')) {
            clickFromWin = 1;
        }
        //var refreshReviews= ($(element).hasClass('tbs')) ? true : false;
        var URL = '/p/' + baseId + '/';
        var spec = itemForm.elements['specOne-' + uniqid].value, size = itemForm.elements['specTwo-' + uniqid].value;
        if (baseId == newBaseId) {
            URL += spec + size + "/";
        } else { //else changing baseIds, so don't pass size and spec
            spec = '';
            size = ''; //reset so it doesn't get passed to switchDetailPhoto below, in case other baseId doesn't have the color and thus the photo would not update - this is ok if new baseId is in same 2XXXX group, but not if it's different i.e. SR 58416
        }

        //if(oak){switchDetailPhotoOAK(getGenericImage(baseId,outlier),itemForm,spec);} //grouped into one function and moved to ajax response below so qoh will come from newly loaded inv instead of the one we're leaving
        //else{switchDetailPhoto(getGenericImage(baseId,outlier),spec);}

        //update Pinterest button - color may have changed
        var imageId = getGenericImage(newBaseId);
        $.ajax({
            type: 'POST', url: URL, dataType: 'json', data: sendUrl, success: function (json) {
                /*if(!refreshReviews){ //not needed with new ajax outputs that exclude reviews
                    //preserve reviews (temporary until power reviews script doesn't mess up page on ajax
                    reviewText = $("#detailReviewRow").html(); //store existing since we can't output script via ajax currently
                }*/

                handleSwatchChangeSuccess(json);

                /*if(!refreshReviews){
                    if(reviewText.length){
                        $("#detailReviewRow")[0].innerHTML = reviewText; //use innerHTML instead of .html() so script doesn't run again and erase the page
                    }
                }*/

                attachDetailEvents(clickFromWin);

                var refreshedItemForm = ($('#form' + baseId + spec)[0]) ? $('#form' + baseId + spec)[0] : $('#form' + baseId)[0];
                var sold = (refreshedItemForm && refreshedItemForm.oneofakind && refreshedItemForm.oneofakind.value <= 0) ? 'sold' : '';
                switchDetailPhoto(imageId, spec, sold);

                //loadEmptyBvContainers();

                //var media = 'https://softsurroundings.scene7.com/is/image/SoftSurroundings/'+imageId+spec;
                var media = getImageUrl(imageId + spec, '550x826');
                refreshPinterestButton(media);
                if (spec != '') { //spec will be empty when changing size category
                    var styliticsId = imageId + spec;
                    styliticsId = styliticsId.substring(1, 7).toUpperCase();
                    typeof refreshStylitics === "function" && refreshStylitics(styliticsId);
                }
            }
        });
    } // end if
}

function handlePRReadReviews() {
    $('#detailReviewRow').on('click', '.pr-snippet-review-count', function (e) {
        var reviewinfo = $('#reviewsinfo');
        if (!reviewinfo.is(':visible')) {//fix issue where if reviews where hidden wouldn't navigate to that spot on the page
            $('#reviewsTab').trigger('click');
        }
    });
}

/** TURN TO REVIEWS **/
function populateTeaser(counts) {
    var fragment = document.createDocumentFragment();
    if (counts.reviews > 0) { // has reviews
        fragment.appendChild(generateTeaserStars(counts.avgRating));
        fragment.appendChild(generateReadReviews(counts.reviews));

        fragment.appendChild(document.createTextNode(' | '));
        fragment.appendChild(generateWriteReview('Write a Review'));

        if (counts.questions > 0) {
            fragment.appendChild(document.createElement('br'));
            fragment.appendChild(generateQuestions(counts.questions, counts.answers));
        } else if (counts.comments > 3) {
            fragment.appendChild(document.createTextNode(' | '));
            fragment.appendChild(generateReadComments(counts.comments));
        }
    } else { // no reviews
        fragment.appendChild(generateTeaserStars(0));
        if (counts.questions > 0) {
            fragment.appendChild(generateQuestions(counts.questions, counts.answers));
            fragment.appendChild(document.createTextNode(' or '));
        } else if (counts.comments > 3) {
            fragment.appendChild(generateReadComments(counts.comments));
            fragment.appendChild(document.createTextNode(' or '));
        }
        fragment.appendChild(generateWriteReview('Be the first to write a review'));
    }
    document.getElementById('detailReviewRow').appendChild(fragment);
    // add event listener to handle click to open the write a review screen
    //document.querySelector(\'.TTteaser__write-review\').addEventListener(\'click\',
    //function(e) { TurnToCmd(\'reviewsList.writeReview\');}
    attach('.TTteaser__write-review', 'click', (function (E) {
        TurnToCmd('reviewsList.writeReview');
    }));
}

function createTeaserElement(tag, className, text) {
    var el = document.createElement(tag);
    el.setAttribute('class', className);
    if (text) {
        el.innerText = text;
    }
    return el;
}

function generateWriteReview(text) {
    var el = createTeaserElement('button', 'TTteaser__write-review', text);
    el.setAttribute('type', 'button'); /*override submit default*/
    return el;
}

function generateQuestions(num_questions, num_answers) {
    // Populate with the number of questions
    var text = num_questions + ' Question' + (num_questions > 1 ? 's' : '');

    // Populate the number answers
    if (num_answers > 0) {
        text = text + ', ' + num_answers + ' Answer' + (num_answers > 1 ? 's' : '');
    }

    var el = createTeaserElement('a', 'TTteaser__read-qa', text);
    el.setAttribute('href', window.location.pathname + '#relatedinfoRow2');
    el.setAttribute('onclick', '$("#answersTab").click();');

    //For the Q&A Instant Answers widget set to the following
    //el.setAttribute(\'href\', \'#tt-instant-answers-widget\');
    return el;
}

function generateReadComments(numComments) {
    // Populate the \'x Buyer Comments\' text with the number of comments and set
    var text = numComments + ' Buyer Comment' + (numComments > 1 ? 's' : '');
    var el = createTeaserElement('a', 'TTteaser__read-comments', text);
    el.setAttribute('href', window.location.pathname + '#tt-chatter-widget');
    return el;
}

function generateReadReviews(numReviews) {
    // Populate the \'Read x Reviews\' text with the number of reviews and set
    var text = 'Read ' + numReviews + ' Review' + (numReviews > 1 ? 's' : '');
    var el = createTeaserElement('a', 'TTteaser__read-reviews', text);
    el.setAttribute('href', window.location.pathname + '#relatedinfoRow2');
    el.setAttribute('onclick', '$("#reviewsTab").click();');
    return el;
}

function generateTeaserStar(starType) {
    var el = '<div class="tt-star star-' + starType + '-filled">';
    return el;
}

function generateTeaserStars(rating) {
    var el = createTeaserElement('div', 'tt-stars');
    var numFullStars = Math.floor(rating);
    for (var i = 0; i < numFullStars; i++) {
        $(el).append(generateTeaserStar('100'));
    }
    var hasHalfStar = (rating - numFullStars) >= 0.5;
    if (hasHalfStar) {
        $(el).append(generateTeaserStar('50'));
    }
    var emptyStarsStartIdx = numFullStars + (hasHalfStar ? 1 : 0);
    for (var i = emptyStarsStartIdx; i < 5; i++) {
        $(el).append(generateTeaserStar('0'));
    }
    return el;
}

function detailGiftCardChange(E) {
    E.preventDefault();
    var element = E.target, itemForm = $(element).closest("form")[0],
        persVal = element.value;
    itemForm.personalize2.value = $(element).data("personalize2");
    detailSpecChange(E);
}

/* Update a text counter that displays how many remaining characters can be added to the textbox
*  Assumes the text box has a maxlength and the counter has a max-counter class with both elements inside the same parent */
function updateMaxCounter(E) {
    var element = E.target, maxLen = $(element).prop('maxlength'),
        maxCounter = $(element).parent().find('.max-counter');
    var charsUsed = $(element).val().length,
        charsLeft = maxLen - charsUsed;
    $(maxCounter).text(charsLeft);
}

function refreshPinterestButton(media) {
    //var js, href; //html, pinJSarray, pinJS;
    //html = '<a href="' + href + '" class="pin-it-button"  data-pin-do="buttonPin" data-pin-config="beside"><img src="//assets.pinterest.com/images/pidgets/pin_it_button.png" /></a>';
    //html = '<a onclick="alert(\'test\');window.open(this.href, \'signin\', \'height=300,width=665\'); return false" href="' + href + '" count="none">Pin It</a></div>';
    if ($('div#pin-it a')[0]) {
        var url = encodeURIComponent(window.location.href), href;
        var description = $("title").html();
        href = 'http://pinterest.com/pin/create/button/?url=' + url + '&media=' + media + '&description=' + description;
        $('div#pin-it a')[0].href = href;
    }
    //$$('pin-it a').href=href;

    //remove and add pinterest js -- can be edited out in order to add coremetrics tagging by way of hack, messes with css
    //pinJSarray = $$('script[src*="assets.pinterest.com/js/pinit.js"]');
    //pinJS= pinJSarray[0];
    //pinJS.remove();
    //js = document.createElement('script');
    //js.src = pinJS.readAttribute('src');
    //js.src="http://assets.pinterest.com/js/pinit.js";
    //js.type = 'text/javascript';
    //document.body.appendChild(js);
}

/*function preswChange(itemForm,spec){ //not used - commented out 10/15
 var item = itemForm.parentId.value;
 item = item.toString().substr(0,5);
 itemForm.elements['color_desc_'+item].value = spec;
 detailSpecChange(itemForm);
 }*/
function toggleRelatedInfo(E) {
    if (E.which === 1 || E.which === 13) { // allow mouse clicks and enter key - ADA compliance
        E.preventDefault();
        var element = E.target, id = element.id.replace("Tab", ""),
            addClassName = "sel", toggleRow, rel;

        //get tab ID and determine what row it is on
        if (id == "answers" || id == "reviews") {
            toggleRow = 2;
            rel = $('#relatedinfoRow2')[0];
            /*if(id=="reviews") {addClassName = "sel tabSideShadowRt";} //no more shadows - all ids="sel"
            if(id=="answers") {addClassName = "sel tabSideShadowLt";}*/
        } else {
            toggleRow = 1;
            rel = $('#relatedinfo')[0];
        }

        if (rel != null) {
            //select/unselect tab
            for (var i = 0; i < rel.childNodes.length; i++) {
                if (rel.childNodes[i].className !== undefined && rel.childNodes[i].tagName == 'SPAN') {
                    rel.childNodes[i].className = "";
                }
            }
            if (element) {
                if (element.tagName == "A") {
                    element.parentNode.className = addClassName;
                } else {
                    element.className = addClassName;
                    if (element.firstChild.tagName != "A") {
                        element.firstChild.className = addClassName;
                        element = element.firstChild;
                    }
                }
            }

            //Turn content off
            switch (toggleRow) {
                case 1:
                    $('.dtlBox').hide();
                    /*if($('recentlyviewed2_rr')){$('recentlyviewed2_rr').style.display="none";}
                     if($('shopthislookinfo')){$('shopthislookinfo').style.display="none";}
                     if($('product_ms2_rr')){$('product_ms2_rr').style.display="none"}*/
                    break;
                case 2:
                    if ($('#answersinfo')[0]) {
                        $('#answersinfo').hide();
                    }
                    if ($('#reviewsinfo')[0]) {
                        $('#reviewsinfo').hide();
                    }
                    if ($('#reviewsImgReThumb')) {
                        $('#reviewsImgReThumb').hide();
                        $('#reviewItemDesc').hide();
                    }
                    break;
            }

            //Turn correct tab on
            if (id == "ymal") {
                $('#product_ms2_rr').show();
            } else if (id == "recent") {
                $('#recentlyviewed2_rr').show();
            } else if ($('#' + id + 'info')[0]) {
                $('#' + id + 'info').show();
            } //stl,answers,reviews
            //if(toggleRow==1){addQuickViewIcons();} //to run addquickview on the visible items //not after 58738 redesign

            switch (id) {
                case 'reviews':
                    //loadEmptyBvContainers();
                    if ($('#reviewsImgReThumb')[0] && !$('.pr-rd-no-reviews')[0]) {
                        $('#reviewsImgReThumb').show();
                        $('#reviewItemDesc').show()
                    }
                    if ($('#reviewsHeaderDesc')[0] && !$('#BVRRDisplayContentNoReviewsID')[0]) {
                        $('#reviewsHeaderDesc').show();
                    }
                    var prodName;
                    if ($('#productName')[0]) {
                        prodName = $('#productName').text();
                    }
                    //callCmCreateElementTag('ReadProductReview',prodName);
                    break;
                /*case 'shopthislook': //ajax STL - may bring this back in
                 if($(id+'info').innerHTML==""){
                 var outfit ="";
                 if($('outfit')){outfit = $('outfit').href.replace(window.location.protocol+"//"+window.location.hostname+"/outfit.php?",""); }
                 URL="/ajax/outfit_view.php";sendUrl=outfit;
                 ajax = new Ajax.Request(URL,{method: 'POST', parameters: sendUrl,onComplete:function(t) {
                 $(id+'info').innerHTML=t.responseText;
                 }});
                 }
                 break;*/
            }
        }
    }
}

function toggleTabs(E) {
    E.preventDefault();
    var thisTab = $(E.target).closest('.tab'),
        id = E.target.id.replace("Tab", "");
    if (id.length) {
        var wrapper = $(thisTab).closest('.tabWrapper'),
            allTabs = $(wrapper).find('.tab'),
            allContent = $(wrapper).find('.tabContent'),
            thisContent = $(wrapper).find('#' + id + 'Info'),
            addClassName = 'sel';

        //Change highlight to the new tab
        $(allTabs).removeClass(addClassName);
        $(thisTab).addClass(addClassName);

        //Display new content
        $(allContent).removeClass(addClassName);
        $(thisContent).addClass(addClassName);
    }
}

/*function fixWindowHeight(window){
    $('#'+window.getId()+"_content").css('height',(window.height-POP_CONT_DIFF)+"px");
}*/

/*********************************
 * Function Name:startWhiteGlove
 * purpose: build white glove pop up SR 56214
 * returns: null
 *********************************/

/*function startWhiteGlove(){
 //if(Windows.focusedWindow && $("#wishListItemsAjax")[0]){ Windows.focusedWindow.close();}
 win = new Window({className: "pop", title: "DELIVERY OPTION", width:'525px', height:'300px', destroyOnClose: true, resizable:false, recenterAuto:false,showEffect:Element.show,hideEffect:Element.hide});
 win.showCenter();
 win.id = "findwishlistWin";
 win.element.className='dialog pop';
 win.element.style.height="auto";
 win.setZIndex(50);
 buildWhiteGlove();
 }*/

function buildWhiteGlove() {
    /*var type = "wishlist",div = "wishlistView";
     if($('#winWG')[0]){
     type="win";
     div = 'winWG'; //win.getId().toString()+"_content";
     }*/
    var URL = '/ajax/buildWhiteGlove.php', pars;//="type="+type;
    //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: 525, height: 450, title: 'Delivery Option', id: 'winWG', content: t});
        }
    });
}

/*function wgInfoPop(){
    //used on quick order reivew order etnry page
    var str="<br/>";
    str+="\n<div id=\"whitegloveView\" class=\"whiteglovewindow\" style=\"width: 475px;margin-left: 30px;\">";
    str+="\n<h2 class=\"checkout\">Would You Like To Upgrade to White Glove Delivery?<br/><br/></h2>";
    str+="\nThis item has the option to be upgraded to a White Glove Delivery Service for an additional $80.00. White Glove Service ";
    str+="\nwill not only arrange convenient delivery for you by appointment, but unpack, set up and place your new furniture, removing all shipping materials.</p><br /><br />";
    str+="\n</div>";
    //win.getContent().update(str);
    jDialog({width:525,height:300,title:'Delivery Option',content:str});
}*/
function checkForWG(variable) { //check if white glove was added, and then runs the add to bag function
    $('input.hiddenWG').val(variable);
    $('#wgw').click();
    $('.ui-content-dialog').dialog('close');
}

function checkForWhiteGlove(E) { //event that either shows white glove popup, or adds to bag
    E.preventDefault();
    //var ele = Event.element(E);
    if ($('input.hiddenWG').length > 0) {
        var whiteglove = $('input.hiddenWG').val();
        if (whiteglove != "") {
            aybEvent(E);
        } else {
            buildWhiteGlove();
        }
    } else {
        aybEvent(E);
    }
}

/*function labelNoirPopUp(){
    var win = new Window({className: "labelNoir", title:"", width:"500px", height:"375px",resizable:false, destroyOnClose: true, recenterAuto:true,showEffect:Element.show,hideEffect:Element.hide});
    win.id="labelNoir";
    var str="<div id=\"popUpContent\" style=\"padding:10px;\" >";
    str+="<img src=\""+imagessite+"graphics/lnoir_popup.jpg\" alt=\"Label Noir\" style=\"margin-left:55px;\"/><br/>";
    str+="<p style=\"margin-left:18px;margin-top:7px;font-size:16px;font-family: georgia;\">Label Noir is Soft Surroundings' designer designation for our</br>";
    str+="more luxurious fabrics and sought after vintage inspired pieces.<br/>";
    str+="In an effort to maintain the integrity of these beautiful finds,<br/>";
    str+="we have invested in materials and embellishments, passing along<br/>";
    str+="to you all the romance and quality of the original inspiration.</p>";
    str+="</div>";
    win.getContent().update(str);
    win.showCenter();
    $('#'+win.getId()+"_content").css('height',(win.height-POP_CONT_DIFF)+"px");
    win.element.className='dialog labelPop';
}*/

/*********************************
 * Function Name:manageEmailEvent
 * purpose: handle manage email pop up
 * returns: null
 *********************************/
function manageEmailEvent(E) { //find a wish list event
    E.preventDefault();
    //var loc = window.location.toString();
    button = this;
    buildManageEmail();
}

/*********************************
 * Function Name:startManageEmail
 * purpose: to pop up a window to ask user to subscribe to email SR 56235
 * returns: null
 *********************************/

/*function startManageEmail(){
 //if(Windows.focusedWindow && $("#wishListItemsAjax")[0]){ Windows.focusedWindow.close();}
 win = new Window({className: "manageemail", id: "manageemailId", title: "Email Sign Up", width:'490px', height:'300px', destroyOnClose: true,draggable: false, recenterAuto:false,showEffect:Element.show,hideEffect:Element.hide});
 win.showCenter();
 win.id = "findwishlistWin";
 win.element.className='dialog manageemail';
 win.element.style.height="auto";
 win.setZIndex(50);
 buildManageEmail();
 }*/

/*********************************
 * Function Name:buildManageEmail
 * purpose: buildManageEmail SR 56235
 * returns: null
 *********************************/
function buildManageEmail() {
    /*var type = "wishlist", div = "wishlistView", URL = '/ajax/manageEmail.php';
     if(Windows.focusedWindow){
     type="win";
     div = win.getId().toString()+"_content";
     }*/
    var URL = '/ajax/manageEmail.php', pars, div = "manageemailId";
    var str = "<h2>Loading your preferences...</h2>";
    jDialog({width: 590, height: 450, id: div, title: 'Email Sign Up', content: str});
    //$('#'+div).html("<h2 class=\"checkout\">Loading your preferences...</h2>");
    //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            try {
                var response = $.parseJSON(t);
                var addedurl = '';
                if (response.tid) {
                    addedurl = '?tid=' + response.tid;
                }
                window.location.href = "/manageemailpreferences/" + addedurl;
            } catch (e) {
                //not json assume string
                $('#' + div).html(t);
            }
        }
    });
}

function manageSubscribe(type) {
    var URL = '/ajax/manageEmail.php', pars = "type=" + type;
    //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            if (type == 'scoop') { //no window popped yet
                /*win = new Window({className: "pop", title: "Beauty Scoop", width:"550px", height:"250px",resizable:false,destroyOnClose: true, recenterAuto:false,showEffect:Element.show,hideEffect:Element.hide});
             win.element.className='dialog pop';
             win.showCenter();
             $(win).getContent().update(t.responseText);*/
                jDialog({width: 550, height: 250, title: 'Beauty Scoop', content: t});
            } else {
                if ($(".ui-dialog-content")) {
                    //div = win.getId().toString()+"_content";
                    $(".ui-dialog-content").html(t);
                }
            }
        }
    });
}

/*********************************
 * Function Name:checkForAssociations (renamed from checkForMonogram 58984)
 * purpose: check if item has a monogram or gwp option before sending the add to bag event
 * @param1: e - the form of the item, could be detail page, quickView, cross-sell - contains most everything"online_price_66901=178.95&sku_66901=6690140500&CDFCatId=2&sizecat_desc=66901"
 * returns: null
 *********************************/
function checkForAssociations(E) {
    E.preventDefault();
    var ele = E.target;
//OP913 - Prevent monogram popup \/
//    if($('input.hiddenMono')[0]){ //check for monogram
//        var mono = $('input.hiddenMono').val();
//        if(mono != ""){ //if monogram has been filled in
//            aybEvent(E); //show add to bag
//        }else{ //go get it (will show add to bag when done)
//            promptForMonogram();
//        }
//    } else if(ele.form.gwpId && ele.form.gwpId.value){ //if a potential gwp offer exists //58984
    if (ele.form.gwpId && ele.form.gwpId.value) { //if a potential gwp offer exists //58984
//OP913 - Prevent monogram popup /\
        var gwpId = ele.form.gwpId.value;
        var gwpHandled = ele.form.gwpHandled.value;
        if (gwpId.length && !gwpHandled.length) { //if we have an offer and have not accepted or rejected it
            giftWithPurchasePopup(gwpId); //show the offer (will show add to bag when done)
        } else { //if there is no offer, or if we have already seen the offer
            aybEvent(E);
        }
    } else {
        aybEvent(E);
    }
}

function monogramPopUP(E) {
    var ele, form, parent, parentinfo, parentid, monoProd, monoQty, personalizeId, customText, uid;
    try {
        E.preventDefault();
        ele = E.target;
    } catch (ex) {
    }
    form = ele.parentNode;
    if ($('#quickOrderForm')[0]) {
        var eleInfo = getParentTd(ele).id.split('_');
        uid = getEleUniqueId(ele);
        parentid = eleInfo[1];
        //uid=+eleInfo[1]+'_'+eleInfo[2];
        $('#monoUid')[0].value = uid;
        monoProd = parentid + $('#specOne-' + uid)[0].value + $('#specTwo-' + uid)[0].value;
        monoQty = $('#quantity-' + uid)[0].value;
    } else {
        while (form.tagName != "FORM") {
            form = form.parentNode;
        }
        if ($('#dtl_bd_bar')[0] || $('#quick_bt_additem')[0]) {
            parent = ele.parentNode;
            while (parent.tagName != "TD") {
                parent = parent.parentNode;
            }
            parentinfo = parent.id.split("_");
            parentid = parentinfo[1];
        } else {
            parentid = form.parentId.value;
            parentid = parentid.substr(0, 5);
        }
        uid = form.elements['uniqid'].value;
        monoProd = form.elements['sku_' + parentid].value;
        if (form.elements['quantity-' + uid] != undefined) { //Detail page
            monoQty = form.elements['quantity-' + uid].value;
        } else if (form.elements['quantity_' + parentid] != undefined) { //?
            monoQty = form.elements['quantity_' + parentid].value;
        } else { //?
            monoQty = form.elements['quantity_q_' + parentid].value;
        }
    }
    personalizeId = $('#personalizeId_' + parentid)[0].value;
    customText = $('#customText_' + parentid)[0].value;
    if (monoProd.length != 10) {
        $('#orderProcessError.error').html('Please select a size and color before adding a monogram.');
    } else {
        var URL = '/ajax/monogram.php',
            pars = '&productid=' + monoProd + '&qty=' + monoQty + '&parentid=' + parentid + '&monogramid=' + personalizeId + '&customText=' + customText;
        //var ajax = new Ajax.Request(URL,{method: 'POST', parameters: sendUrl,onComplete: handleMonogramPopUPSuccess});
        $.ajax({type: 'POST', url: URL, dataType: 'text', data: pars, success: handleMonogramPopUPSuccess});
    }
}

var handleMonogramPopUPSuccess = function callBack(o) {
    jDialog({width: 945, height: 750, id: 'winMonoChoose', title: 'Monogramming', content: o});
    if ($('#style')[0] && $('#mono_color')[0]) {
        monogramGetFields('99007');
    }
};
var handleMonogramFieldSuccess = function callBack(o) {
    var monText = $('#monogramText'), t = o, tex = getMonogramText(), h4 = tex[0], p = tex[1];
    if (t.length > 7) {
        /*if(monText){
         monText.innerHTML= h4+"\n<div class=\"specs\">"+p+t+"</div>";
         monText.show();
         }*/
        $(monText).html(h4 + "\n<div>" + p + t + "</div>").show();
    } else {
        //if(monText){ monText.innerHTML= '';$(monText).hide();}
        $(monText).html('').hide();
    }
    $('#monogramRowCount').val($('input.monoLineId').length);
};

function changeSpec(text, spec) {
    var elem;
    if (spec == "color") {
        elem = $('#mono_color');
    } else {
        elem = $('#style');
    }
    $(elem).val(text);
    /*options = elem.getElementsByTagName("option");
     for(i=0;i< options.length; i++){
     if(elem.options[i].value == text){
     elem.options.selectedIndex = i;
     }
     }*/
    monogramGetFields($('#personalize').val());
}

function monogramGetFields(personalize) {
    var monoStyle = $('#style')[0], monoColor = $('#mono_color')[0], index = monoStyle.selectedIndex;
    var monogramType = monoStyle.value, monogramColor = monoColor.value;
    if (!monogramColor) {
        if ($('#errorList')[0]) {
            $('#errorList').html('<li class=\"highlight\">Please Select a Color</li>');
        } else {
            //var insertion = new Insertion.After('style', '<ul id=\"errorList\"><li class=\"highlight\">Please Select a Color</li></ul>');
            $('#style').after('<br/><br/><ul id=\"errorList\"><li class=\"highlight\">Please Select a Color</li></ul>');
        }
        $('#monogramText').html("");
        $('#monogramText').hide();
    } else {
        if ($('#errorList')[0]) {
            $('#errorList').remove();
        }
        var selectedOptionText = monoStyle.options[index].text;
        var singleFieldWords = /(NAME|SINGLE|LAUREL SPRAY)/i; //set up regular expression to match
        if (singleFieldWords.exec(selectedOptionText) === null) {//if none of the regular expression words are found
            var URL = '/ajax/monogramData.php',
                pars = '&monoId=' + personalize + monogramType + monogramColor + '&customText=' + $('#monoCustomTextRedisplay').val();
            //var ajax = new Ajax.Request(URL,{method: 'POST', parameters: sendUrl,onComplete: handleMonogramFieldSuccess});
            $.ajax({type: 'POST', url: URL, dataType: 'text', data: pars, success: handleMonogramFieldSuccess});
        } else {
            makeOne(selectedOptionText);
        }
    }
}

function validateMonogram() {
    var URL = '/ajax/monogram.php', monoStyle = $('#style')[0], monoColor = $('#mono_color')[0],
        monoRowCount = $('#monogramRowCount')[0];
    var personalize = $('#personalize')[0], parentId = $('#parentId')[0], customText = "";
    var sendUrl = '&personalize=' + personalize.value + '&style=' + monoStyle.value + '&color=' + monoColor.value + '&monogramRowCount=' + monoRowCount.value + '&Submit=SaveMonogramming';
    for (var i = 1; i <= monoRowCount.value; i++) {
        var monoLineId = $('#monogram' + i)[0];
        if (i > 1) {
            customText = customText + "|";
        }
        customText = customText + monoLineId.value;
        sendUrl += '&monogram' + i + '=' + monoLineId.value;
    }
    var argument = [personalize.value, monoStyle.value, monoColor.value, customText, parentId.value];
    //var ajax = new Ajax.Request(URL,{method: 'POST', parameters: sendUrl,onComplete:function(t) {
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: sendUrl, success: function (t) {
            handleMonogramValidate(t, argument);
        }
    });
}

function handleMonogramValidate(o, argument) {
    if (o == "") {
        var winText = getWinText(argument);
        jDialog({width: 520, height: 410, id: 'winMonoConfirm', title: 'Confirm Monogramming', content: winText});
    } else {
        if ($('#errorList')[0]) {
            $('#errorList').html(o);
        } else {
            if ($('#style').val() == "") {
                //var insertion = new Insertion.After('style', '<ul id=\"errorList\">'+o.responseText+'</ul>');
                $('#style').after('<ul id=\"errorList\">' + o + '</ul>');
            } else {
                //var insertion1 = new Insertion.After('monoH4', '<ul id=\"errorList\">'+o.responseText+'</ul>');
                $('#monoH4').after('<ul id=\"errorList\">' + o + '</ul>');
            }
        }
    }
}

function getWinText(argString) {
    var monoColor = $('#mono_color')[0], monoStyle = $('#style')[0],
        color = monoColor.options[monoColor.selectedIndex].text,
        styles = monoStyle.options[monoStyle.selectedIndex].text, g = "";
    for (i = 0; i < argString.length; i++) {
        if (i > 0) {
            g += ",";
        }
        g += "'" + argString[i] + "'";
    }
    var numLet = argString[3].split("|"), prompText = "", custText = "";
    for (i = 1; i <= numLet.length; i++) {
        if (i > 1) {
            prompText += "<br>";
            custText += "<br>";
        }
        prompText += $('#span' + i).html();
        custText += numLet[i - 1];
    }
    htmlStr = "<div class=\"space\"><p>Please review your monogram selections to make sure the information you entered is correct,";
    htmlStr += " as monogrammed items are <b>not returnable</b>. When you have finished, click the <b>Confirm</b>";
    htmlStr += " button to save or the <b>Edit</b> button to make any changes.</p>";
    htmlStr += " <table><tr><td>";
    htmlStr += " Thread Color:<br />Monogram Style:<br />" + prompText + "</td>";
    htmlStr += " <td> " + color + "<br> " + styles + "<br> " + custText + "</td></tr></table>";
    htmlStr += "<div class=\"textCenter\"><br/><br/><a href=\"javascript:void(0);\" class=\"button mono_bttn\" onclick=\"jDialogClose(this);return false;\">edit</a>";
    htmlStr += " &nbsp; <a href=\"javascript:void(0);\" class=\"button mono_bttn\" onclick=\"submitMonogram(" + g + ");return false;\">confirm</a></div></div>";
    return htmlStr;
}

function submitMonogram(personalize, color, styles, custText, parentId) {
    if ($("#form" + parentId)[0]) {
        var detailForm = document.forms['form' + parentId], uid = detailForm.uniqid.value, monoIdAppend = '';
    } else if ($("#quickOrderForm")[0]) {
        var detailForm = document.forms['quickOrderForm'];
    }
    if ($('#quickOrderForm')[0]) {//We are in quick order
        uid = $('#monoUid').val();
        monoIdAppend = '-' + uid;
    }
    if ($('#detailmono' + monoIdAppend)[0]) {
        $('#detailmono' + monoIdAppend).html("Monogrammed " + custText);
    }
    if ($('#monoplus' + monoIdAppend)[0]) {
        $('#monoplus' + monoIdAppend)[0].style.visibility = "hidden";
    }
    $('#personalizeId_' + parentId).val(personalize + color + styles);
    $('#customText_' + parentId).val(custText);
    /*win.destroy();
     winConfirm.close();*/
    jDialogClose();
    //Add to bag if Size and Color are already selected
    if ($('#aybl')[0]) {
        if (detailForm['specOne-' + uid].value != '' && detailForm['specTwo-' + uid].value != '') {
            $('#aybl').click();
        }
    }
}

function makeOne(selectedOptionText) {
    var wordField = /(NAME)/i, formText = "", siz, len, tex = getMonogramText(), h4 = tex[0], p = tex[1];
    if (wordField.exec(selectedOptionText) === null) {
        formText = formText + "<span id=\"span1\">Enter center initial: </span>";
        len = " maxlength=\"1\" ";
        siz = " size=\"1\" ";
    } else {
        formText = formText + "<span id=\"span1\">Enter your name: </span>";
        len = " maxlength=\"8\" ";
        siz = " size=\"8\" ";
    }
    inputTag = "<input type=\"text\" name=\"monogram1\"" + len + siz;
    inputTag += " id=\"monogram1\" value=\"\">";
    $('#monogramRowCount').val(1);
    var d = $('#monogramText')[0];
    d.innerHTML = h4 + "\n<div>" + p + formText + inputTag + "</div>";
    d.style.display = 'block';
}

function getMonogramText() {
    var tt = [];
    //tt[0] = "<div class=\"sec_header mono_options perpFont\" >Monogram Initials</div>";
    tt[0] = "<h2>3. Choose your monogram initials</h2>";
    tt[1] = "<p class=\"space\">Please enter the letters in the order in which you would like";
    tt[1] += " them to appear, as monogrammed items are <b>not returnable</b>. Note:";
    tt[1] += " We are not liable for misspellings or incorrect letters entered by the customer.</p>";
    tt[1] += "<div id=\"errorList\" class=\"error monoerror\"></div>";
    return tt;
}

function getParentTd(element) {
    var ele = $(element);
    while ((ele[0].tagName != 'TD' && !ele.hasClass('detailOutfit') && !ele.hasClass('mqvItem')) || ele.hasClass('quickSmallTd') || ele.hasClass('mqvTd')) {
        ele = $(ele).parent();
    }
    return ele[0];
}

/*********************************
 * Function Name:QuickSellItemChange
 * purpose: to change swatches and sizes using Ajax on the upsells and bedding ensembles pages
 * @param1: item - baseId
 * @param2: size - size of item
 * @param3: spec - color of item
 * @param4: qty - quantity ordered
 * @param5: divId - upsell or bedding ensemble
 * @param6: personalizedId - sku of personalization
 * @param7: customText - personalized text
 * returns: null
 *********************************/
function QuickSellItemChange(E) {
    //,size,spec,qty,divId,personalizeId,customText
    var personalizeId = "", customText = "", size = "", sizeType = "", spec = "", qty = "", ele = E.target,
        URL = '/collections/', item, ajaxTarget, pars, itemUid;
    var elementinfo = ele.id.split("-"), uId = elementinfo[1]; //NEW way

    item = $(ele).data('item');//item=elementinfo[2];
    if (!item && elementinfo[0] == 'newsizecat') { //if we are changing size type
        item = $(ele).val();
    }


    if ($('#specOne-' + uId)[0]) {
        spec = $('#specOne-' + uId).val();
    }
    if ($('#specTwo-' + uId)[0]) {
        size = $('#specTwo-' + uId).val();
    }
    if ($('#quantity-' + uId)[0]) {
        qty = $('#quantity-' + uId).val();
    }
    /*if($('#subItemNumber-'+uId)[0]){
        subItemNumber = $('#subItemNumber-'+uId).val();
    }*/
    /*if($('#size_desc'+itemUid)[0]){ //may be hidden input or select
        size = $('#size_desc'+itemUid).val();
    }
    if($('#color_desc'+itemUid)[0]){
        spec = $('#color_desc'+itemUid).val();
    }
    if($('#personalizeId'+itemUid)[0]){
        personalizeId = $('#personalizeId'+itemUid).val();
    }
    if($('#customText'+itemUid)[0]){
        customText = $('#customText'+itemUid).val();
    }
    if(!$('#quantity_q'+itemUid)[0]){
        qty=$('#quantity'+itemUid).val();
    }else{
        qty=$('#quantity_q'+itemUid).val();
    }*/
    ajaxTarget = getParentTd(ele);
    pars = 'item=' + item + '&size=' + size + '&spec=' + spec;

    if ($('#sizecat-' + uId)[0]) {
        sizeType = $('#sizecat-' + uId).val(); //get previous parentId
        if (sizeType != item) { //if changing size type need to refresh other options
            //item = sizeType; //comment out b/c we reversed logic - item is now the new var instead of the old one
            pars = 'item=' + item;
            if ($('#quickOrderForm')[0]) { //quickOrder
                var oldAjaxTargetId = ajaxTarget.id;
                ajaxTarget.id = ajaxTarget.id.replace(elementinfo[2], item);
                $('#skus').val($('#skus').val().replace(oldAjaxTargetId, ajaxTarget.id));
            }
        }
    }

    /*if($('#detailBedding')[0]||$('#quickOrderForm')[0]){ //deprecated in 58738 redesign
        pars+='&formType=bedqk';
    }else*/
    if ($('#detailOutfits')[0] || $('#popStl')[0]) {
        pars += '&formType=dtlstl';
    } else if ($('#olist')[0] || $('#ocont')[0]) {
        pars += '&formType=ocont';
    } else if ($('#mQV')[0]) {
        pars += '&formType=mqv';
    } else if ($('#giftWithPurchase')[0]) {
        pars += '&formType=gwp&gwpid=' + (ele.form.gwpId.value);
        ajaxTarget = $(ele).closest('.product');
    }
    pars += '&ajax=1&qty=' + qty + '&personalizeId_' + item + '=' + personalizeId + '&customText_' + item + '=' + customText + '&uniqid=' + uId;//+'&subItemNumber='+subItemNumber;
    //var ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            $(ajaxTarget).html(t);
            if ($('#mQV')[0]) {
                attachMultiQVEvents();
            } else if ($('#giftWithPurchase')[0]) { //58339
                attachGWPEvents();
            } else {
                attachDetailBeddingEvents();
            }
        }
    });
}

/*********************************
 * Function Name:startQuickView
 * purpose: to initiate QuickView on the thumbnail page and throughout checkout
 * @param1: parentId - baseId
 * @param2: size - size of item
 * @param3: spec - the color of item
 * @param4: qty - quantity ordered
 * @param5: personalizedId - sku of personalization
 * @param6: customText - personalized text
 * @param7: cartId - the id of the lineItem in shoppingCartTemp
 * returns: null
 *********************************/
function startQuickView(E, parentId, size, spec, qty, personalizeId, customText, cartId, gwpFlag) { //removed basicMode from third parameter 3/15, unused
    if (!isMobileDevice) {
        //var productName = $('#thm' + parentId).find('.longDesc').text();//get the actual product name from the longDesc class of anchor tag
        /*if ($('#quickOrderForm')[0]) {
            //These are used only on quick order
            var parentTdId;
            if (elementId != undefined) {
                parentTdId = getParentTd($('#' + elementId)).id;
            } else {
                parentTdId = getParentTd(E.target).id;
            }
            $('#quickViewTargetTdId').val(parentTdId);
            var eleInfo = parentTdId.split('_');
            var itemUid = '_' + eleInfo[1] + '_' + eleInfo[2];
            if($('#quickWin')[0]){$('#quickWin').dialog('close');} //if(Windows.focusedWindow && Windows.focusedWindow.id == "quickWin"){Windows.focusedWindow.destroy();}
            //var quickOrderForm=$('quickOrderForm');
            size = $('#size_desc' + itemUid).val();
            spec = $('#color_desc' + itemUid).val();
            qty = $('#quantity_q' + itemUid).val();
        }*/
        var currentURL = window.location.href;
        var extsize = currentURL.match(/(women|misses|petites|talls|plus-size)/gm); //grab extsize value from url if filtered on category page - SR 59276
        if (extsize == null) {
            extsize = "";
        }
        switch (extsize[0]) {
            case "misses":
                parentId = "2" + parentId.substr(1, parentId.length);
                break;
            case "petites":
                parentId = "3" + parentId.substr(1, parentId.length);
                break;
            case "talls":
                parentId = "4" + parentId.substr(1, parentId.length);
                break;
            case "women":
                parentId = "5" + parentId.substr(1, parentId.length);
                break;
            case "plus-size":
                parentId = "5" + parentId.substr(1, parentId.length);
                break;
            default:
                break;
        }
        parentId = parentId + '';//make sure this is a string
        var URL = '/p/' + parentId.toLowerCase() + "/"; //quickimg = k.getElementById("quickimg_" + parentId);
        /*if (quickimg !== null && quickimg.style.visibility == "visible") {
            nosearch = k.getElementById("noSearchResults");
            // if(page=="cart"){URL+="?suggest=1";}
            // else if(page=="thumbs" && nosearch !== null){URL+="?suggest=3";}
            // else if(page=="detail"){URL+="?suggest=2";}
        }*/
        var pars = "parentId=" + parentId + "&specOne=" + spec + "&specTwo=" + size;
        pars += "&quantity=" + qty + "&personalizeId=" + personalizeId;
        pars += "&customText=" + customText.replace(/&/g, "%26") + "&cartId=" + cartId + "&quickView=1&ajax=1"; //encode amp to prevent escaping
        if (gwpFlag > 0) { //59141
            pars += "&gwpFlag=" + gwpFlag;
        }
        $.ajax({
            type: 'POST', url: URL, dataType: 'json', data: pars, success: function (t) {
                loadQuickViewResponse(t, parentId, spec);
            }
        });
    } else {
        window.location.href = 'p/' + parentId + '/';
    }
}

function loadQuickViewResponse(response, parentId, spec) {//380
    var uniqueId = response.uniqueId;
    var imageId = getImageId(parentId);
    var size = '450x675';
    var wrapId = 'fullQuickView';
    var str = '' +
        '<div id="' + wrapId + '">' +
        '<div id="quickzoom">' +
        '<div id="quickViewPhotoWrapper">' +
        '<div id="flyoutzoom">' +
        '<img class="xzoom" src="' + getImageUrl(imageId, size) + '" xoriginal="' + getImageUrl(imageId, '1200x1802') + '" style="width:100%;">' +
        '</div>' +
        '<div id="detailVideo-' + imageId + '" class="detailVideo"></div>' +
        '</div>' +
        '<div id="quickDetailAltImgs">' + decodeURIComponent(response.altView).replace(/\+/g, ' ') + '</div>' +
        '</div>' +
        '<div id="quickWinDetail" class="win_detail">' +
        '<form id="form' + parentId + '" name="form' + parentId + '" method=\"post\">' +
        '<div class="dtlHeader">' +
        decodeURIComponent(response.productHeader).replace(/\+/g, ' ') +
        '</div>' +
        '<div class="dtlFormBulk">' +
        decodeURIComponent(response.productBulk).replace(/\+/g, ' ') +
        '</div>' +
        '</form>' +
        '</div>' +
        '</div>';

    jFitDialog({
        position: {my: "center center", at: "center center"},
        width: 900,
        height: 660,
        margin: 'auto',
        id: 'quickWin',
        title: 'QUICK VIEW',
        className: 'dialog pop quickwin ',
        content: str,
        noTop: true
    }); /* quickwin class used for GTM triggers */

    embedZoomSS(getGenericImage(parentId, '') + spec, 425, 335, true);
    var testHeight = $('#detailItemOrderOptions-' + uniqueId).height();
    if (testHeight > 319) {
        $('#copyInfo').css('height', "75px");
    } else if (testHeight < 280) {
        $('#copyInfo').css('height', "164px");
    }
    attachDetailEvents(1);

    attachAtlViewEvents();

    checkString = "cart|wishlist|login|shipping|itemoptions|billing|payment|addressbook";
    if (!window.location.toString().match(checkString)) {
        //callCmCreateElementTag('start_QuickView',parentId);
        if (typeof (resx) !== 'undefined') {
            resx.event = "quickview_opr";
            resx.itemid = parentId;
            //resx.pageid = ""; //do not set - global var will maintain the pageid set to subcategory_rr on page load
            //resx.links = "111|quickview_rr;222|quickview_rr;333|quickview_rr";
            /* Azure 767 - Remove Certona            certonaResx.run();*/
        }
    }
    /* Azure 767 - Remove Certona \/
    //load quickview_rr
    var pars = 'div=quickview_rr&parentId='+parentId;
    $.ajax({type:'POST',url:'/certona/',dataType:'text',data:pars,success:function(t){
        $('#quickview_rr').html(t);
    }});
Azure 767 /\ */
}

function loadQuickViewMulti(parentIds, offset) { //load popup in lookbook splash pages
    parentIds = parentIds.replace(/\+/g, ",");// calls from Zmags will '+' delimit parentIds, need to replace with commas
    if (isMobileDevice) {
        /*mobile device and only has multiple items*/
        window.location.href = 'quickviewmulti/?parentIds=' + parentIds;
    } else {
        if (!offset) {
            offset = 0;
        } //which 'slide' we are showing of the group of invs
        var URL = '/quickviewmulti/', pars = "parentIds=" + parentIds + "&offset=" + offset;
        $.ajax({
            type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
                if ($('#mQV')[0]) { //assume popup is already active
                    $('#mQV').parent().html(t);
                } else {
                    var qvWidth = '90%';
                    if ($(document).width() > 950) {//set to max at 900px
                        qvWidth = 950;
                    }
                    jDialog({
                        width: qvWidth,
                        height: '777', /* avoid auto, doens't calculate right on lookbook on some screens*/
                        title: 'Shop This Look',
                        id: 'mQVWin',
                        //className: 'dialog quickwin',
                        content: t
                    });
                }

                attachMultiQVEvents();
            }
        });
    }
}

function toggleStoreInventoryStatusDetail() {
    if ($('#storeInventoryExpanded').css('display') === 'none') {
        $('#storeInventoryExpanded').css('display', 'block');
        $('#storeInventoryPlusIcon').html('&nbsp;&#8722;');
    } else {
        $('#storeInventoryExpanded').css('display', 'none');
        $('#storeInventoryPlusIcon').html('&nbsp;&#43;');
    }
}

function expandFavoriteStore() {
    var URL = "/stores/expandfavoritestore/";
    var pars = "page=navbar";
    $.ajax({
        type: "POST", url: URL, dataType: "json", data: pars, success: function (json) {
            var content = decodeURIComponent(json.content);
            $('#headBar').after(content);   /* add div to page with display:none;*/
            var div = $('#favoriteStoreDivWrapper');
            if (div && div.css('display') === 'none') {
                div.slideDown({queue: true, duration: 750});    /* div appears with a slide down */
                this.handlerMethod = function (e) {
                    if ((e.which === 0 || e.which === 1 || e.which === 13) && !isDescendant($("#favoriteStoreDivWrapper")[0], e.target)) {
                        closeFavoriteStoreDivWrapper();
                        $('body').off('click', '#wrapper', this.boundHandlerMethod);
                        return false;
                    }
                }
                this.boundHandlerMethod = this.handlerMethod.bind(this);
                attach("#wrapper", "click", this.handlerMethod);
            }
        }
    });

}

function closeFavoriteStoreDivWrapper() {
    var div = $('#favoriteStoreDivWrapper')
    div.slideUp({queue: true, duration: 750});
    setTimeout(function () {
        div.remove();
        $('#wrapper').off();
    }, 750);
    return false;
}

function deleteFavoriteStore() {
    var URL = "/stores/setfavoritestore/";
    var pars = "favoriteStore=0&page=myaccount";
    $.ajax({
        type: "POST", url: URL, dataType: "json", data: pars, success: function (json) {
            var text = decodeURIComponent(json.text);
            $('.favoriteStoreTextWrapper').html(text);
        }
    });
}

function startFavoriteStorePopup(isDetailPage) {
    if (isDetailPage == null) {
        isDetailPage = 0;
    }
    var URL = "/stores/favoritestorepopup/";
    var pars = "isDetailPage=" + isDetailPage;
    var widthX = 0;
    var heightY = 0;
    if (isMobileOrTabletWidth) {
        widthX = 700;
        heightY = 520;
    } else if (isMobileDevice) {
        widthX = 350;
        heightY = 350;
    } else {
        widthX = 900;
        heightY = 520;
    }
    $.ajax({
        type: "POST", url: URL, dataType: "json", data: pars, success: function (json) {
            var popbody = decodeURIComponent(json.popBody);
            if (isMobileDevice || isMobileOrTabletWidth) {
                var header = "Choose a Favorite Store";
            } else {
                var header = "Choose Your Favorite Store";
            }
            jDialog({width: widthX, height: heightY, id: "favoriteStorePopup", title: header, content: popbody});
        }
    });
    return true;
}

function setFavoriteStoreFromPopup(retailFacilityId, page) {
    if (page == null) {   //gulp does not like default parameter values
        page = "myaccount";
    }
    var URL = '/stores/setfavoritestore/', pars = 'action=JSON&page=' + page + '&favoriteStore=' + retailFacilityId;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
            switch (page) {
                case 'myaccount':
                    var text = decodeURIComponent(json.text);
                    $('.favoriteStoreTextWrapper').html(text);
                    jDialogClose();
                    break;
                case 'detail':
                    jDialogClose();
                    location.reload();  //favorite store has been set via ajax, reload page to update content
                    break;
            }
        }
    });
}

function setFavoriteStoreFromPopupKeypress(E, storeId, page) { //ADA compliance
    if (E.which === 13) {
        if (page == null) {   //gulp does not like default parameter values
            page = "myaccount";
        }
        setFavoriteStoreFromPopup(storeId, page);
    }
}

function startShipToStorePopup() {
    var URL = "/stores/stspopup/";
    var pars = "";
    var widthX = 0;
    var heightY = 0;
    if (isMobileOrTabletWidth) {
        widthX = 700;
        heightY = 760;
    } else if (isMobileDevice) {
        widthX = 350;
        heightY = 550;
    } else {
        widthX = 900;
        heightY = 760;
    }
    $.ajax({
        type: "POST", url: URL, dataType: "json", data: pars, success: function (json) {
            var popbody = decodeURIComponent(json.popBody);
            jDialog({width: widthX, height: heightY, id: "storesPopup", title: "Choose Your Store", content: popbody});
        }
    });
}

function sendStoreIdFromPopup() {
    var doc = document;
    var text = document.childNodes[1].parentNode.activeElement.innerHTML;
    $("#storespopup").val("Change your Store");
    $('.changeStoreWrapper').attr('class', "changeStoreWrapper flex");//add flex class to wrapper
    $('.storeNameWrapper').css('display', 'flex');
    $('.storeNameWrapper').html(text);
    jDialogClose();
}

function sendStoreIdFromPopupKeypress(E) { //ADA compliance
    if (E.which === 13) {
        sendStoreIdFromPopup();
    }
}

//faqPopups throughout site
/*********************************
 * Function Name:faqPopUp
 * purpose: handle the faq pop up Events ( ie..whats this?,web security,gift card
 * @param1: faqId - the id of the faq that should be pop upped.
 * returns: null
 *********************************/

function faqPopUp(E) {
    var submitted = "false", element, elementinfo, faqId;
    //var loc = window.location.toString();
    E.preventDefault();
    E.stopPropagation(); //ex. to prevent icon inside an expand dropBox from triggering the dropBox event also
    element = E.target;
    while (element.tagName != 'A' && element.parentNode) {
        element = element.parentNode;
    }
    //id=element.id;
    elementinfo = element.id.split("_"); //ex. pop_58 => 58
    faqId = elementinfo[1];
    faqPopUpWindow(faqId);
}

function faqPopUpWindow(popUpId) {
    var widthX = "500", heightY = "360", URL = '/faq/';
    if (popUpId == "giftCert") {
        //callCmCreateElementTag('gift','COFAQ');
        URL = '/ajax/gcinqq.php';
    } else {
        popUpId = popUpId * 1;
        switch (popUpId) {
            case 27:
                //callCmCreateElementTag('return','COFAQ');
                break;
            case 28:
                //callCmCreateElementTag('shipping','COFAQ');
                break;
            case 7:
                //callCmCreateElementTag('security','COFAQ');
                break;
            case 168:
                widthX = "700";
                //callCmCreateElementTag('measurechart','COFAQ');
                break;
        }
    }
    jDialogClose();
    var pars = "popUpId=" + popUpId + "&ajax=1"; //+"&width="+widthX+"px"+"&submitted="+submitted;//target = win;
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: widthX, height: heightY, id: 'winFAQ', title: "FAQ's", content: t});
        }
    });
}

/*********************************
 * Function Name:mvcPopUpWindow
 * purpose: generate pop up window tied to provided classname.
 * @param1: popWidth - popUp Width
 * @param2: popHeight - popUp Height
 * @param3: mvcClass - Class that exists in project with full MVC implementation
 * @param4: popTitle - Title for popUp Window
 * returns: null
 *********************************/
function mvcPopUpWindow(popWidth, popHeight, mvcClass, popTitle) {
    var URL = '/' + mvcClass + '/', pars = "ajax=1", mvcID = mvcClass + 'PopID';
    jDialogClose();
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: popWidth, height: popHeight, id: mvcID, title: popTitle, content: t});
        }
    });
}

function pressPopUp(E) {
    var URL = '/pressroom/', element = E.target, title, pars;
    while (element.tagName != 'A' && element.parentNode) {
        element = element.parentNode;
    }
    pars = "article=" + $(element).data('article') + "&ajax=1";
    title = $('.cat.sel').text(); //selected tier - ex. "In Print"
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: 790, height: 700, title: title, content: t, className: "dialog pop pressRoomPop"});
        }
    });
}

/*function unsubscribePopUp(email,myAccountPage){
    //var loc = window.location.toString();
    var onCloseDisplay,widthX="510",heightY="250",URL = '/ajax/unsubscribe.php';
    if(myAccountPage){
        onCloseDisplay = location.href;
    }else{
        onCloseDisplay = "/email_pref.php?email="+email;
    }
    var pars = "email="+email+"&unsubscribe="+1;//target = win;
    //var ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({type:'POST',url:URL,dataType:'text',data:pars,success:function(t){
        //$(win).getContent().update(t.responseText);
        jDialog({width:widthX,height:heightY,title:'Email Unsubscribe',content:t,close:function(t){window.location=onCloseDisplay;}});
    }});
}*/

/*International shipping Project
 for Canada, users must accept checkbox for us to send promo emails
 */
function emailCheckPopUp(E) {
    E.preventDefault();
    //var loc = window.location.toString(),onCloseDisplay;
    var widthX = "510", heightY = "250";
    win = new Window({
        className: "pop",
        title: "Attention Canadians",
        width: widthX + "px",
        height: heightY + "px",
        resizable: false,
        destroyOnClose: true,
        recenterAuto: false,
        showEffect: Element.show,
        hideEffect: Element.hide
    });
    win.showCenter();
    win.element.className = 'dialog pop';
    var str = "<div id=\"canadianCheck\"><p>We wish to continue sending you important Soft Surroundings marketing information. Because of the new Canadian anti-spam law taking effect on July 1, 2014, we must obtain your consent to do so.</p>";
    str += "<p>In order to meet the law’s requirements, we ask you to give us your consent to receive our electronic communications such as marketing emails, newsletters and invitations to our events.</p>";
    str += "<p><input type=\"checkbox\" name=\"canadianConsent\" id=\"canadianConsent\" /> I Consent</p>";
    str += "</p><input type=\"image\" id=\"subscr_canadian_pu\" class=\"png\" src=\"/media/images/graphics/alttext.gif\" name=\"theAction=\" /></p></div>";
    $(win).getContent().update(str);
}

function jsonIt() {
    var item_count = 0;
}


function dropCart(E) {
    E.preventDefault();
    //Get number of items to pass to buildCart. All we really need to know is if it is greater than 0 or not
    //Exact number may be incorrect as other uses of item_count refer to number of line items, not sum of qty of line items
    //So we don't want to pass an incorrect higher number that may trigger adding a class that makes div unnecessarily taller
    //If the div really needs that class it will get it from ajax buildCart
    var numItems = getNumCartItems();
    if (numItems > 1) {
        numItems = 1;
    }
    var cartObj = {item_count: numItems};
    //var jsonFakeIt = new jsonIt();//not sure why we this needs to be initialized by a function/replaced with cartObj
    //jsonFakeIt.item_count=numItems;
    buildCart(cartObj, "detail", "");
    return false;
}

/*********************************
 * Function Name:removeInserted
 * purpose: to clean up after the quickCart has closed
 * returns:
 *********************************/
function removeInserted() {
    if ($('#quickCart')[0]) {
        $('#quickCart').html('');
    } //I think we can just clear all the content, not just random children? SR 57228
    /*var qCD = $('quickCartDir'),subTot = $('subTotals'),disc = $('grpDisc');
     if(qCD){$('quickCart').removeChild(qCD);}
     if(disc){$('quickCart').removeChild(disc);}
     if(subTot){$('quickCart').removeChild(subTot);}*/
    //if(qckS){qckS.style.display = "none";}
    //$('bag').style.backgroundPosition="-736px -167px"; //these don't do anything?
    //$('shoppingBag').style.backgroundPosition="-670px -223px";
    var pattern = /(billing|shipping|login|payment|finalize|cart|itemOptions|addressBook)/i;
    if (!pattern.test(page)) {
        //var shopLink = $('#shopLink')[0];
        attach('#shopLink', 'click', dropCart);
        //if(shopLink){shopLink.onclick = dropCart;}
    }
}

/*********************************
 * Function Name:closeQuickCart
 * purpose: to handle the closing of the quickCart
 * returns:
 *********************************/
function closeQuickCart() {
    //if(Event){ Event.stopObserving(k, 'click', body_bind);}
    //Effect.BlindUp('quickCart',{queue:'end',duration:1.0,afterFinish: function(t){removeInserted();}});
    $('#quickCart').slideUp({queue: true, duration: 1000, complete: removeInserted});
    return false;
}

/*********************************
 * Function Name:getQuickCartContents
 * purpose: to animate or update the quickCart
 * @param1: page - the current page
 *
 * @param2: type - itemOptions page - shippping or gift type
 * returns:
 *********************************/
function getQuickCartContents(page, type, numItems) {
    var cartURL = '/cart/';///ajax/buildCart.php
    if (numItems > 2) {
        numItems = 4;
    }
    if (type !== undefined) {
        type = "&" + type;
    }
    var pars = "ajax=1&page=dropcart" + type + "location=" + encodeURIComponent(window.location);
    //ajax = new Ajax.Request(cartURL,{method: 'POST', parameters: pars,requestHeaders:{Accept: 'application/json'},onComplete: function(t) {
    $.ajax({
        type: 'POST', url: cartURL, dataType: 'json', data: pars, success: function (jsonCart) {
            //var jsonCart = t.responseText.evalJSON(true);
            var cartText = decodeURIComponent(jsonCart.cart);
            cartText = cartText.replace(/\+/g, ' ');
            var subTotalText = decodeURIComponent(jsonCart.subtotals);
            subTotalText = subTotalText.replace(/\+/g, ' ');
            var quickCart = $('#quickCart')[0];
            var quickCartWrapper = $('#quickCartWrapper')[0];
            if (quickCart) {
                quickCart.innerHTML = cartText;
                if ($('#cart')[0]) {
                    $('#cart').addClass("quickCart" + numItems);
                }
            }
            var scrollpos = f_scrollTop();
            window.scrollTo(0, 25);
            var end = '';
            if (jsonCart.end) {
                end = decodeURIComponent(jsonCart.end).replace(/\+/g, ' ');
            }
            if (quickCart && quickCart.style.display == 'none') {
                $('#quickCart').slideDown({queue: true}); //Effect.SlideDown('quickCart',{queue:'end'});
                $('#cart').after(subTotalText + end); //var insertion = new Insertion.After('cart',subTotalText+end);
                //this function closed the shopping bag if you clicked anywhere outside of it. bag is now a hover
                this.handlerMethod = function (e) {
                    if (!isDescendant($("#quickCartWrapper")[0], e.target)) {
                        closeQuickCart();
                        $('body').off('click', '#wrapper', this.boundHandlerMethod);
                    }
                }
                this.boundHandlerMethod = this.handlerMethod.bind(this);
                attach("#wrapper", "click", this.handlerMethod);
            } else {
                if (quickCartWrapper) {
                    if ($('#subTotals')[0]) {
                        quickCart.removeChild($('#subTotals'));
                    }
                    if ($('#grpDisc')[0]) {
                        quickCart.removeChild($('#grpDisc'));
                    }
                }
                //var insertion1 = new Insertion.After('cart',subTotalText+end);
                $('#cart').after(subTotalText + end);
            }
            var pattern = /(billing|shipping|login|payment|finalize|cart|itemOptions|addressBook)/i;
            if (!pattern.test(page)) {
                var shopLink = $('#shopLink')[0];
                if (shopLink) {
                    shopLink.onclick = closeQuickCart;
                }
            }
            attachCartEvents();
        }, error: function () {
            //didn't get a json/valid response back so lets direct cust to /cart/ page
            location.href = '/cart/';
        }
    });
}

function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

/*********************************
 * Function Name:buildCart
 * purpose: to redisplay the cart after an add, edit, remove
 * @param1: xmlDoc - a parsable representation of the xml response - probably from /ajax/processOrder.php
 * @param2: page - the page that the request is happening on
 * @param3: type - itemOptions.php, gift or shipping options
 * returns: divId - the newly updated cart div
 *********************************/
function buildCart(json, page, type) {
    var divId, pattern = /(cart|login|shipping|billing|addressBook|itemOptions|payment|finalize|orderentry)/i,
        shoppingBag;//,changebag
    if (json.item_count > 0) {
        if (page == "cart") {
            divId = "cart_page";
        } else {
            divId = "cartSidebarWrap";
        }
    } else {
        divId = "page";
    }
    if (pattern.test(page)) { // we are in checkout and can deal with normal ajax request
        getCheckOutCartContents(page, type, divId); //checkout.js
        //changebag=0;
    } else {
        getQuickCartContents(page, type, json.item_count);//we are on detail, search or wishlist and have items in cart - need json response
    }
    if (page == "idea-center") {
        shoppingBag = getConciergeShoppingBag();
    } else {
        updateShoppingBagNumber();
        if (!pattern.test(page)) {
            if ($('#shoplink')[0]) {
                attach('#shoppingBag', 'click', dropCart);
            }
        }
    }

    return divId;
}

/*********************************
 * Function Name:findLineItemId
 * purpose: to locate the lineItemId of an item that is being removed from the cart
 * @param1: itemForm - the page form of a checkout page or cart page ie... cartForm, shipToForm...
 * returns: lineItemId - the shoppingCartTemp id
 *********************************/
function findLineItemId(itemForm) {
    var lineItemId = "", ind;
    for (var x = 0; x < itemForm.length; x++) {
        if (itemForm.elements[x].name.match(/Qty/)) { //find item with qty = 0
            if (itemForm.elements[x].value == 0) {
                ind = itemForm.elements[x].name.indexOf('Qty');
                lineItemId = itemForm.elements[x].name.substr(0, ind); //grab lineItemId
                x = itemForm.length;
            }
        }
    }
    return lineItemId;
}

/*********************************
 * Function Name:processOrder
 * purpose: to control the addition, edit, and removal of an item to or from the cart or wishlist
 * @param1: itemForm - the form of the item, could be detail page, quickView, cross-sell - contains most everything"online_price_66901=178.95&sku_66901=6690140500&CDFCatId=2&sizecat_desc=66901"
 * returns: null
 *********************************/
function processOrder(E, itemForm) {
    var pars, URL, baseId;
    /*if(page.match("itemOptions")){
        type=window.location.search.toString().replace("?","");
    }*/
    //move the remove request to rfbEvent and call processOrderResponse from there - less confusing/fragile
    /*if(itemForm.name.match(/Form/)){ //we are removing an item from cart.  capital F on Form means checkout and cart page.
        var wishlist=0;
        var lineItemId  = findLineItemId(itemForm);  //ajax.js
        baseId = itemForm.elements[lineItemId+'sku'].value.substr(0,5);
        if(itemForm.name=="wishlistForm"){wishlist=1;}
        pars = "quantity_1=0&cartId_1="+lineItemId+"&page="+page+"&type="+type+"&wishlist="+wishlist;
        URL = '/ModifyCart/';
    }else*/
    {// we are editing or adding
        var cartId = "", wishCartId = "";
        //var baseId = itemForm.elements['sku'].value.substr(0,5);
        if (itemForm.cartId) {
            cartId = itemForm.cartId.value;
        }
        if ($('#listItem_' + cartId)[0]) {
            itemForm.wishlist.value = 1;
            wishCartId = "&listItemId=" + cartId;
        }

        pars = loadUpPars(itemForm); //create a string of all elements of a form  +&form.element.name=value+
        pars += wishCartId;
        baseId = itemForm.parentId.value.toLowerCase(); //name.toString().replace("form","").toLowerCase(); //use parentId so we pass the correct baseId even from different sizecats
        pars += "&page=" + page;
        //if(type!=""){pars+="&"+type;}
        URL = '/p/' + baseId + '/';
    }
    //pars+='&ajax=1';
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (t) {
            processOrderResponse(t, E, itemForm);
        }
    });
}


/*********************************
 * Function Name:promptForMonogram
 * purpose: monogram prompt
 * returns: null
 *********************************/
function promptForMonogram() {
    var widthX = '525', heightY = '400', win;
    var str = "<h2 class=\"teal\">Would You Like to Add a Monogram?</h2>";
    str += "<p>This item has the option to be personalized. Would you like to add a monogram now?</p><br /><br />";
    str += "<p class=\"textCenter\"><a onclick=\"checkForMono(true);\">YES - Please take me to monogramming options</a><br/><br/>";
    str += "or<br/><br/>";
    str += "<a onclick=\"checkForMono(false);\">No Thanks - I do not want to add a monogram at this time</a></p>";
    jDialog({width: widthX, height: heightY, title: 'Monogram Option', content: str});
}

function checkForMono(addMono) {
    if (addMono) {
        $('#detailmono').click();
    } else {
        $('input.hiddenMono').val("NO I DONT WANT TO MONOGRAM");
        $('#aybl').click();
    }
    jDialogClose();
}

function processErrors(errorString, delimator) {
    var errors = "", errorArray = errorString.split(delimator);
    for (var i = 0; i < errorArray.length; i++) {
        errors += '<span class=\"error\">' + errorArray[i] + '</span><br>';
    }
    return errors;
}

/*function validatePersonalize(perForm){ //replace gift card popup with inline 57740
    var pars = loadUpPars(perForm),URL = '/P/'+perForm.sizecat.value+'/',type;
    pars += "&page=persubmit";

    //var ajax = new Ajax.Request(URL,{method: 'POST',requestHeaders:{Accept: 'application/json'},parameters: pars,onComplete: function(t){
    $.ajax({type:'POST',url:URL,dataType:'json',data:pars,success:function(json){
        //var json = t.responseText.evalJSON(true);
        if(json.success.toString()=="true"){
            if($('.ui-dialog-content')[0]){jDialogClose();} //if(Windows.focusedWindow){ Windows.focusedWindow.close(); }
            type="";
            if(page.match("itemOptions")){
                type=window.location.search.toString().replace("?","");
            }
            switch(page){
                case "detail":
                    if(perForm.wishlist !== undefined && perForm.wishlist.value == '1'){
                        window.location = secureBase+"wishlist.php";
                    }else{
                        buildCart(json,page,type);
                    }
                    break;
                case "search":
                    if(perForm.wishlist !== undefined && perForm.wishlist.value == '1'){
                        window.location = secureBase+"wishlist.php";
                    }else{
                        buildCart(json,page,type);
                    }
                    break;
                case "wishlist":
                    buildWishList(type);
                    break;
                default:
                    buildCart(json,page,type);
                    break;
            }
        }else{
            $('#perProcessError').html(processErrors(json.errors,"|"));
        }

    }});
}*/
function toggleQuickGCFields(E) {
    var elem = E.target, frm = elem.form;
    disableBool = (elem.value != 'EMAIL');
    $(frm).find("input.inpGcEmail").prop('disabled', disableBool);
}

/*function editMonogram(productid,qty,parentid,desc){ //not in use 10/15 SR 57228
 winConfirm.close();
 monogramPopUP(productid,qty,parentid,desc);
 }*/

function emailFriend(imgUrl, submitted, desc) {
    var URL = '/ajax/sendtofriend.php', newHeightY = 525,
        pars = "&width=" + 450 + "px" + "&height=" + newHeightY + "px" + "&submitted=" + submitted + "&imgUrl=" + encodeURIComponent(imgUrl) + "&desc=" + desc;
    if (submitted != 0) {
        pars += "&" + loadUpPars(k.emailProductForm) + "&pageUrl=" + encodeURIComponent(window.location.href);
    }
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            //win.getContent().update(t.responseText);
            if (submitted == 0) {
                jDialog({width: 450, height: newHeightY, id: 'winEmFr', title: 'Email a Friend', content: t});
            } else {
                $('#winEmFr').html(t);
            }
            attach('.withLabel', 'focus', inputShowLabel);
        }
    });
    emailFriendClick();
}

function emailFriendClick() { //SR#56056 - adding Element Tag to Email a Friend button
    var prodName;
    if ($('#productName')[0]) {
        prodName = $('#productName').text();
    }
    //callCmCreateElementTag("EmailAFriend", prodName);
}

/*function emitBazaarVoiceProfile(profileURL){
 var URL = '/ajax/bazaarVoiceProfile.php';
 var Height = 600 - 45;
 var pars = "profileURL="+profileURL+"&width=800px&height="+Height+"px";
 var win = new Window({className: "pop", title: "", width:800+"px", height:600+"px", destroyOnClose: true, recenterAuto:false,showEffect:Element.show,hideEffect:Element.hide});
 win.showCenter();
 $(win.getId()+"_top").style.width = 800+"px";
 $(win.getId()+"_content").style.backgroundColor = "#dff4f9";
 var ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) { win.getContent().update(t.responseText); }});
 }*/

/*function createCartIdElement(lineItemIndex){ //no longer used
    var cartId;
    cartId = k.createElement("input");
    cartId.setAttribute("type", "hidden");
    cartId.setAttribute("name", "cartId");
    cartId.setAttribute("value", lineItemIndex);
    return cartId;
}*/

/*function createCartForm(lineItemIndex){ //no longer used
    var docForm,cartId;
    if($('#quickCart')[0] && $('#quickCart')[0].style.display != 'none'){
        //create a form and pass it to process order
        docForm= k.createElement("FORM");
        k.body.appendChild(docForm);
        docForm.method = "POST";
        docForm.name = "cartForm";
        var x = k.getElementsByName(lineItemIndex+'sku');
        sku = x[0];
        docForm.appendChild(sku);
        x = k.getElementsByName(lineItemIndex+'Qty');
        qty = x[0];
        qty.value=0;
        x = k.getElementsByName(lineItemIndex+'per');
        docForm.appendChild(qty);
        if(x[0]!== undefined){ per = x[0]; docForm.appendChild(per);}
        x = k.getElementsByName(lineItemIndex+'cust');
        if(x[0]!== undefined){ cust = x[0]; docForm.appendChild(cust);}
    }else{
        docForm = k.forms[page+"Form"];
        docForm.elements[lineItemIndex+"Qty"].value=0;
    }
    $(docForm).append("<input name='cartId' type='hidden' value='"+lineItemIndex+"'>");

    return docForm;
}*/

/*********************************
 * Function Name:addToListFromCartEvent, formerly sflEvent
 * purpose: handle the save for later onclick event
 *********************************/
function addToListFromCartEvent(E) {
    E.preventDefault();
    var element = E.currentTarget, elementinfo = element.id.split("_"), lineItemId = elementinfo[1],
        itemForm = $(element).closest('form')[0];
    //var lineItemId = findLineItemId(itemForm);
    var sku = itemForm.elements[lineItemId + 'sku'].value.toString();
    var qty = itemForm.elements[lineItemId + 'Qty'].value.toString();
    var baseId = sku.substring(0, 5), color = sku.substring(5, 7), size = sku.substring(7, 10), personalizeId = "",
        customText = "", type = "";

    var URL = '/modifycart/';
    var pars = "parentid=" + baseId + "&quantity=" + qty + "&sku=" + sku + "&wishlist=1&specOne=" + color + "&specTwo=" + size + '&ajax=1';
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
            if (json.success.toString() == "true") {
                refreshWishlistItems("wishlistcart");
                $("#remove_" + lineItemId).click(); //remove from cart
            }
        }
    });
}

//remove from list //replacing rfwEvent //58062
function removeFromList(E) {
    E.preventDefault();
    var element = E.currentTarget, elementinfo = element.id.split("_"), listItemId = elementinfo[1];
    var URL = '/modifycart/';
    var pars = "wishlist=1&ryb=1&listItemId=" + listItemId;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
            if (json.success.toString() == "true") {
                refreshWishlistItems("wishlist");
            } else if (json.errors) {
                $("#listError").html(json.errors);
            }
        }
    });
}

/*********************************
 * Function Name:vywEvent
 * purpose: handle the view your wishlist Event
 * returns: null
 *********************************/
function vywEvent(E) { //view your wishlist Event
    E.preventDefault();
    //callCmCreateElementTag('view','WishList');
    refreshWishlistItems("wishlist", 1); //startWishList(); //changed in 58062
}

/*********************************
 * Function Name:fawEvent
 * purpose: handle the find a wish list Event
 * returns: null
 *********************************/
/*function fawEvent(E){ //find a wish list event //58062 removed searchable functionality - lists are private unless user chooses to share public link
    E.preventDefault();
    //var loc = window.location.toString();
    //startFindWishList(); //consolidated start and build functions into these two lines below
    $.ajax({type:'POST',url:'/ajax/buildFindWishList.php',dataType:'text',data:'',success:function(t){
        jDialog({width:425,height:400,title:"Find A Friend's Wish List",content:t});
    }});
}*/
/*********************************
 * Function Name:startFindWishList
 * purpose: to pop up a window containing a customers wishlist SR 56151
 * returns: null
 *********************************/
/*function startFindWishList(){
 if(Windows.focusedWindow && $("#wishListItemsAjax")[0]){ Windows.focusedWindow.close();}
 win = new Window({className: "pop", title: "Find A Friend's Wish List", width:'425px', height:'400px', destroyOnClose: true, resizable:false, recenterAuto:false,showEffect:Element.show,hideEffect:Element.hide});
 win.showCenter();
 win.id = "findwishlistWin";
 win.element.className='dialog pop';
 win.element.style.height="auto";
 win.setZIndex(6);
 buildFindWishList();
 }*/
/*********************************
 * Function Name:buildFindWishList
 * purpose: buildFindWishList SR 56151
 * returns: null
 *********************************/

/*function buildFindWishList(){
 var type = "wishlist",div = "wishlistView";
 if(Windows.focusedWindow){
 type="win";
 div = win.getId().toString()+"_content";
 }
 var URL = '/ajax/buildFindWishList.php',pars="type="+type;
 //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
 $.ajax({type:'POST',url:URL,dataType:'text',data:pars,success:function(t){
 //$('#'+div).html(t.responseText);
 }});
 }*/
/*********************************
 * Function Name:addToCartFromListEvent //formerly atwEvent 58062
 * purpose: handle the wishlist add to bag, and saveForLater add to bag
 * returns: null
 *********************************/
function addToCartFromListEvent(E) {
    E.preventDefault();
    var element = E.target, elementinfo = element.id.split("_"), listId = elementinfo[1], listItemId = elementinfo[2];
    var frm = $(element).closest('form')[0], err = $('#listError'), type = '', viewother = false;
    if (frm.elements['viewother'] && frm.elements['viewother'].value == 1) {
        viewother = true;
    }
    if (frm.elements['qtyPurch' + listItemId].value == -1) {
        //replace error
        $(err).html('Please enter a quantity before adding item ' + frm.elements[listItemId + 'sku'].value + ' to your cart.');
    } else {
        $(err).html('');
        //callCmCreateElementTag('add_WishList',wishForm.elements[lineItemId+'sku'].value); //deprecating
        var URL = "/list/",
            pars = "action=addToCart&listId=" + listId + "&listItemId=" + listItemId + "&qtyPurch=" + frm.elements['qtyPurch' + listItemId].value;
        if (viewother) {
            pars += "&viewother=1";
        }
        $.ajax({
            type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
                //json = t.responseText.evalJSON(true);
                if (json.success.toString() == "true") {
                    //if(page.match("itemOptions")){type=window.location.search.toString().replace("?","");} //not sure
                    dataLayerPush('addToCart'); //58146 google tag manager
                    buildCart(json, page, type);
                    if (!viewother) { //refresh list to reflect your updated list, unless viewing another list
                        refreshWishlistItems("wishlist");
                    }
                } else if (json.errors) {
                    $(err).html(json.errors);
                }
            }
        });
    }
}

function atwdtEvent(E) {
    E.preventDefault();
    var element = E.target;
    var itemForm = element.parentNode;
    while (itemForm.tagName != "FORM") {
        itemForm = itemForm.parentNode;
    }
    var uniquid = itemForm.elements['uniqid'].value;
    if (itemForm.elements['specOne-' + uniquid].value !== "" &&
        itemForm.elements['specTwo-' + uniquid].value !== "") {
        itemForm.wishlist.value = 1;
        processOrder(E, itemForm);
    } else {
        if ($('#orderProcessError.error')[0]) {
            $('#orderProcessError.error').html("Please select a size and/or color");
        }
    }
}

function atwdtEventPOS(E) {      //this is used to throw an error if an item is being added to wish list but isn't available
    E.preventDefault();              //the item will not be processed and send to wish list SR 56151
    var element = E.target;
    var itemForm = element.parentNode;

    while (itemForm.tagName != "FORM") {
        itemForm = itemForm.parentNode;
    }
    var uniquid = itemForm.elements['uniqid'].value;
    if (itemForm.elements['specOne-' + uniquid].value !== "" &&
        itemForm.elements['specTwo-' + uniquid].value !== "") {
        itemForm.wishlist.value = 1;
    } else {
        if ($('#orderProcessError.error')[0]) {
            $('#orderProcessError.error').html("Please select a size and/or color");
        }
    }
}

/* update wishlist items
 * page = "wishlist" to just refresh the lineitems, "wishlistcart" if on cart and we may need to print the header as well
 * popup = pass 1 if need to open a popup window in which to place
  * */
function refreshWishlistItems(page, popup) {
    var URL = '/cart/', pars = "ajax=1&page=" + page;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (jsonCart) {
            var listText = decodeURIComponent(jsonCart.wishlist).replace(/\+/g, ' ');
            if (popup) { //called from a link that creates a popup to show the wishlist
                var content = '<div id="wishListCart"><form><div id="listItems">' + listText + '</div></form></div>';
                jDialog({width: 800, height: 600, title: 'Wish List', id: 'wishlistWin', content: content});
            } else {
                var divId = (page === "wishlistcart") ? "wishlist_wp" : "listItems";
                $("#" + divId).html(listText);
            }
            attachWishListEvents();
        }
    });
}

/* share your wishlist 58062 */
function popUpWishlistShare(E) {
    E.preventDefault();
    var URL = '/list/', pars = "ajax=1&action=share";
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: 450, height: 360, title: 'Share Your Wish List', content: t});
        }
    });
}

/* display the shop this look items for an item to add to bag 58882 */
function popUpShopThisLookItems(parentId) {
    var URL = '/cart/', pars = "ajax=1&page=stlcart&parentId=" + parentId;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (jsonCart) {
            var stlText = decodeURIComponent(jsonCart.stlcart).replace(/\+/g, ' ');
            var content = '<div id="popStl">' + stlText + '</div>';
            jDialog({width: 800, height: 650, title: 'Shop This Look', content: content});
            attachDetailBeddingEvents();
        }
    });
}

function disabledEvent(E) {
    E.preventDefault();
}

/*********************************
 * Function Name:eybEvent
 * purpose: handle the edit your bag Event
 * @param1: item - baseId
 * @param2: size
 * @param3: color
 * @param4: qty - number ordered
 * @param5: personalization - sku of personalization
 * @param6: customText - the customText of personalization or monogramming
 * @param7: lineItemIndex - the shoppingCartTemp id
 * returns: null
 *********************************/
function eybEvent(E) {  //edit your bag Event
    E.preventDefault();
    var element, elementinfo, lineItemIndex, itemForm, sku, qty, personalization = "", customText = "", color, size,
        item, gwpFlag = 0;//id,loc
    /*if((element.tagName=="IMG"||element.tagName=="SPAN")&& element.parentNode.tagName=="A"){
        element=element.parentNode;
    }*/
    element = $(E.target).closest('a')[0];
    //id=element.id;
    elementinfo = element.id.split("_");
    lineItemIndex = elementinfo[1];
    itemForm = element.parentNode;
    while (itemForm.tagName != "FORM") {
        itemForm = itemForm.parentNode;
    }
    sku = itemForm.elements[lineItemIndex + 'sku'].value;
    qty = itemForm.elements[lineItemIndex + 'Qty'].value;
    if (itemForm.elements[lineItemIndex + 'per'] !== undefined) {
        personalization = itemForm.elements[lineItemIndex + 'per'].value;
    }
    if (itemForm.elements[lineItemIndex + 'cust'] !== undefined) {
        customText = itemForm.elements[lineItemIndex + 'cust'].value;
    }
    if (itemForm.elements[lineItemIndex + 'gwpFlag'] !== undefined) {
        gwpFlag = itemForm.elements[lineItemIndex + 'gwpFlag'].value;
    } //59141
    item = sku.substr(0, 5);
    color = sku.substr(5, 2);
    size = sku.substr(7, 3);
    //loc = window.location.toString();
//        cmCreatePageElementTag("edit","Edit",item,"1",loc);
    startQuickView(E, item, size, color, qty, personalization, customText, lineItemIndex, gwpFlag);
}

function disableFutures(id) {
    var action = id.substring(0, 3), aybl;//winX=0,winY=0;
    if (action == "ayb") {
        aybl = k.getElementById(action + "l");
        if (aybl !== undefined) {
            aybl.src = imagessite + "graphics/processing.gif";
            /*if(Windows.focusedWindow && Windows.focusedWindow.id == "quickWin"){ //vars seem to be unused
             quickPos = Windows.focusedWindow.getLocation();
             winX = quickPos.left.toString().substring(0,quickPos.left.length-2)*1;
             winY = quickPos.top.toString().substring(0,quickPos.top.length-2)*1;
             }*/
        }
    }
}

/*********************************
 * Function Name:aybEvent
 * purpose: handle the add your bag event / edit your bag event
 * @param1: item - baseId
 * returns: null
 *********************************/
function aybEvent(E) {
    E.preventDefault();
    var element = E.target, id = element.id, itemForm = element.parentNode, item, uniqid;
    var elementinfo = element.id.split("_");
    disableFutures(id);
    var loc = window.location.toString();
    while (itemForm.tagName != "FORM") {
        itemForm = itemForm.parentNode;
    }
    if (itemForm.parentId) {
        item = itemForm.parentId.value;
    }
    if (itemForm.uniqid) {
        uniqid = itemForm.uniqid.value;
    }

    /*else{
     item = itemForm.crossSell.value;
     //        cmCreatePageElementTag("add","CROSS SELL PROD",item,"1",loc);  //ADD cross sell to bag
     }*/
    if (item) {
        item = item.toString().substr(0, 5);
    }

    if (item && item.length) {
        var pattern = /(\/P\/|detail)/i;
        var cartId = "", error = "", name, price;
        if (itemForm.cartId) {
            cartId = itemForm.cartId.value;
        }
        var detailpage = pattern.test(loc);
        if (itemForm.wishlist) {
            itemForm.wishlist.value = '0';
        }
        processOrder(E, itemForm);
        if (!detailpage && cartId == "") {
            //callCmCreateElementTag('add_QuickView',item);
            if (typeof (resx) !== 'undefined') {
                resx.event = "addtocart_opr";
                resx.itemid = item;
                resx.pageid = "";
                resx.links = "";
                /* Azure 767 - Remove Certona                certonaResx.run();*/
            }
        }
    }
    return false;
}

/*********************************
 * Function Name:rfbEvent
 * purpose: handle remove from bag Event
 * @param1: lineItemIndex - the shoppingCartTemp id
 * @param2: item - baseId
 * returns: null
 *********************************/
function rfbEvent(E) { //remove from bag Event
    var isElement = E instanceof HTMLElement;
    !isElement && E.preventDefault();
    var element = isElement ? E : E.currentTarget, elementinfo = element.id.split("_"), lineItemId, itemForm, sku,
        baseId, specOne,
        specTwo, pars, URL;
    lineItemId = elementinfo[1];
    itemForm = $(element).parents("form")[0];
    sku = itemForm.elements[lineItemId + 'sku'].value.toLowerCase();
    baseId = sku.substr(0, 5), specOne = sku.substr(5, 2), specTwo = sku.substr(7, 3);
    //docForm = createCartForm(lineItemIndex);
    //item=docForm.elements[lineItemIndex+'sku'].value;
    //processOrder(E,docForm); //instead of passing to processOrder, do the ajax request here and send to processOrderResponse

    var wishlist = 0;
    if (itemForm.name == "wishlistForm") {
        wishlist = 1;
    }
    pars = "ryb=1&cartId=" + lineItemId + "&uniqid=1&sizecat=" + baseId
        //+"&sku="+itemForm.elements[lineItemId+'sku'].value //not used
        + "&specOne=" + specOne + "&specTwo=" + specTwo
        + "&price=1&quantity=0&wishlist=" + wishlist;
    URL = '/p/' + baseId + '/';
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (t) {
            processOrderResponse(t, E, {'cartId': {'value': lineItemId}});
            var lineItems = document.querySelectorAll("#cart > .lineitem").length;
            setTimeout(function () {
                var lineItems = document.querySelectorAll("#cart > .lineitem").length;
                if (lineItems < 1) {
                    location.reload();
                }
            }, 3000);
        } //pass object for 'itemForm' - add elements if necessary but I don't think we need to create a whole artifical form
    });

    //callCmCreateElementTag('remove',sku);
}

/*********************************
 * Function Name:rfw
 * purpose: handle remove from wishlist Event
 * @param1: item - baseId
 * returns: null
 *********************************/
/*function rfwEvent(E){ //replaced by removeFromList
    E.preventDefault();
    var element = E.target,lineItemIndex,cartId;
    while(element.tagName!='A' && element.parentNode) {
        element = element.parentNode;
    }
    elementinfo=element.id.split("_");
    lineItemIndex=elementinfo[1];
    k.wishlistForm.elements[lineItemIndex+"Qty"].value=0;

    $(k.forms.wishlistForm).append("<input name='cartId' type='hidden' value='"+lineItemIndex+"'>");
    if (typeof(resx) !== 'undefined') {
        var itemsku = k.wishlistForm.elements[lineItemIndex+"sku"].value;
        resx.event="wishremove";
        resx.itemid='"'+itemsku+'"';
        resx.pageid="";
        resx.lnks="";
        certonaResx.run();
    }
    processOrder(E,k.wishlistForm);
}*/
//removes the entire wish list("love-its") SR 56151
/*function rfwAllEvent(E){ //no longer used
    E.preventDefault();
    var element = E.target,lineItemIndex,cartId,elementinfo=element.id.split(",");
    for (var i=0; i<elementinfo.length; i++){
        lineItemIndex=elementinfo[1];
        k.wishlistForm.elements[lineItemIndex+"Qty"].value=0;
        if(Prototype.Browser.IE){
            var Input = "<input name='cartId' type='hidden' value='"+lineItemIndex+"'>";
            try{cartId = k.createElement(Input);}
            catch(e){cartId = createCartIdElement(lineItemIndex);}
        }else{
            cartId = createCartIdElement(lineItemIndex);
        }
        if (typeof(resx) !== 'undefined') {
            var itemsku = k.wishlistForm.elements[lineItemIndex+"sku"].value;
            resx.event="wishremove";
            resx.itemid='"'+itemsku+'"';
            resx.pageid="";
            resx.lnks="";
            certonaResx.run();
        }
        k.forms.wishlistForm.appendChild(cartId);
        processOrder(E,k.wishlistForm);
    }
    //lineItemIndex=elementinfo[1];
    //k.wishlistForm.elements[lineItemIndex+"Qty"].value=0;
}*/

function reinstateEvents(E, itemForm) {
    try {
        var ele = E.target;
    } catch (ex) {
        var ele = E.target;
    }
    var id = ele.id;
    var action = id.substring(0, 3);
    if (itemForm != "") {
        if (itemForm.name != "cartForm") {
            aybl = k.getElementById(action + "l");
            aybl.src = imagessite + "graphics/alttext.gif";
        }
    }
}

function processOrderResponse(json, E, itemForm) {
    var isElement = E instanceof HTMLElement;
    //var json = t.responseText.evalJSON(true);
    type = "";
    if (page.match("itemOptions")) {
        type = window.location.search.toString().replace("?", "");
    }
    if (json.success.toString() == "true") {
        if (!$("#wishlistWin")[0] && !$("#mQVWin")[0]) {
            $(".ui-dialog-content").dialog("close"); //Windows.focusedWindow.close();
        }
        var cartId = "", wishlist = 0;
        if (itemForm.wishlist) {
            wishlist = itemForm.wishlist.value;
        }
        if (itemForm.cartId) {
            cartId = itemForm.cartId.value;
        }
        if (wishlist == 0 && cartId == "") {
            baseId = itemForm.name.toString().replace("form", "");
        }
        /*if(json.personalize && json.personalize.length && json.personalize!='false'){ //57740 replace gift card popup with inline
            processPersonliz(json.personalize); //gift card popup
        }else*/
        {
            var wishlistItem = $('#listItem_' + cartId)[0];
            if (wishlist == 1) {
                if ($('#listItems')[0]) { //if page is already displaying items (either on wishlist or on cart)
                    refreshWishlistItems("wishlist"); //just refresh that list
                } else { //ex. we added to wishlist from detail page
                    //window.location = '/list/'; //go to list
                    popUpAddedToWishList(json.productInfo.name); // Task 59277 change wishlist to popup
                }
            }
                /*else if(wishlistItem){ buildWishList();}*/ //58062 replaced popup with on-page below cart
            else {
                var elementinfo = $(isElement ? E : E.target).closest("a,input")[0].id.split("_"); //change from target to closest A or INPUT to handle remove as <a> button when <img> is clicked inside <a>
                if (page.match("detail") && elementinfo[0] != 'remove') {
                    popUpSuggestions(baseId);
                    updateShoppingBagNumber();
                }/*else if(page.match("orderentry")){
                    updateOrderForms(true);
                }*/ else if ($('#mQV')[0]) {
                    $(itemForm).find(".ayblCont").html("This item has been<br/>added to your bag.");
                    updateShoppingBagNumber();
                } else {
                    buildCart(json, page, type);
                }
                //58164 send event to google tag manager
                if (elementinfo[0] == 'edit' || elementinfo[0] == 'eybl') {
                    dataLayerPush('editCart');
                } else if (elementinfo[0] == 'remove') {
                    dataLayerPush('removeFromCart', json.productInfo);
                } else {
                    dataLayerPush("addToCart", json.productInfo);
                }
                addListrakCartItem(json.listrak); //todo
            }
        }
        /*if(json.removed!==undefined && json.removed.toString()==1){
            emitSamplePopUp(json);
        }*/
    } else {
        processOrderErrorsJSON(json);
    }

    //reinstateEvents(E,itemForm);
}

function processOrderErrorsJSON(json) {
    //some type of errors ... figure out where they go and update html
    /*if(itemForm.wishlist && itemForm.wishlist.value == '1'){ //customer wanted to add to wish list but was not logged on //dont do this anymore - errors will tell customer to sign in
        var locString="/List/";
        locString+="?added="+itemForm.elements['sku_'+baseId].value;  //locString - log on then use params to save item
        locString+="&qty="+itemForm.elements['quantity-'+itemForm.uniqid.value].value;
        if(itemForm.elements['personalizeId_'+baseId] !==undefined){
            locString+="&personalizeId="+itemForm.elements['personalizeId_'+baseId].value;
            locString+="&personalization="+itemForm.elements['customText_'+baseId].value;
        }
        locString+="&from="+window.location.pathname.toString();
        window.location = locString;
    }else*/
    {
        $('#orderProcessError.error').html(json.errors.toString());
    }
}

function popUpSuggestions(baseId) {
    var URL = "/suggestion/", pars = "ajax=1";
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({
                width: 609,
                height: 450,
                title: 'Added To Your Bag',
                noBorder: true,
                className: 'addtobagpop dialog pop',
                content: t
            });
            submitted = false;
        }
    });
}

function addElement(domE) {
    var E = k.createElement(domE.tag);
    for (var i = 0; i < domE.attributes.length; i++) {
        var attr = domE.attributes[i];
        E.setAttribute(attr.name, attr.value);
    }
    domE.parent.appendChild(E);
}

/*function getReviewHeader(Height){
    return "<div id=\"popUpContent\" style=\"padding:10px;\" >";
}*/

//Address Validation
function updateToSuggestedAddress(avsObj, frm) { //renamed from pXAVchoice in 58974
    //E.preventDefault();
    var AvCode = 2, address;//element = E.target,id=element.id,selected= 0,valid=true;
    //index = "0"; //k.posAddrs.addrSelect.value; //58521 only displaying one suggestion
    address = avsObj.Addresses[0];
    if (address) {
        frm.streetAddress.value = address.street;
        if (address.apt !== undefined) {
            frm.apt.value = address.apt;
        } else {
            frm.apt.value = "";
        }
        frm.city.value = address.city;
        frm.zip.value = address.zip;
        frm.state.value = address.state.toUpperCase();

        addressValAddElements(frm, AvCode);
    }
}

/*function showAddressSelector(upsObj,frm){ //58974 replace opt-in with force update
    var html="",address,onCloseFn;
    if(frm.name=="shippingForm"||frm.name=="billingForm"){onCloseFn = function(){if(!submitted){submitted=true;frm.submit();}}} //submit form on close so user doesn't get stuck
    //else if(frm.name=="oeshipto"){onCloseFn = stCheck;}
    else{onCloseFn = function(){}}
    //html+= getReviewHeader(485-95);

    html+="<div class=\"popPad\">";
    html+="<p>We're sorry - we were unable to validate the address you entered.</p>";

    html+="<div class=\"ib space\"><p><b>You Entered:</b></p>"; //updated with 58521
    html+="<p>"+frm.streetAddress.value+"<br />";
    if(frm.apt.value!=""){html+=frm.apt.value+"<br />";}
    html+=frm.city.value+", "+frm.state.value+" "+frm.zip.value+"</p>";

    html+="<p class=\"textCenter\"><input id=\"avs_no\" type=\"submit\" class=\"button\" value=\"keep this address\" name=\"submit\" />";
    html+="</div>";
    html+=" &nbsp; &nbsp;";

    html+="<div class=\"ib space\"><p><b>We Suggest:</b></p>";

    address = avsObj.Addresses[0];
    //for(var i=0;i<upsObj.Addresses.length;i++){ //usually only one result anyway - so just show the first
    //}

    html+="<p>"+address.street+"<br />";
    if(address.apt!==undefined){html+=" "+address.apt+"<br/>";}
    else if (frm.apt.value.length){html+=" "+frm.apt.value; address.apt=frm.apt.value;} //58160 avs may not return line 2, so if customer entered it, parrot it back here so it looks right to customer
    html+=address.city+", "+address.state+" "+address.zip+"</p>";

    html+="<p class=\"textCenter\"><input id=\"avs_yes\" type=\"submit\" class=\"button checkout_bt\" value=\"use this suggestion\" name=\"submit\" />";
    html+="</div>";

    html+="</div>";

    //html+="<div id=\"posAddrsErr\"></div>";

    jDialog({width:630,height:360,title:'Address Validation',content:html,close:onCloseFn});
    submitted=false; //global submit var to prevent duplicate submit

    attach('#avs_yes','click',(function(avsObj,frm){return function(e){updateToSuggestedAddress(e,avsObj,frm);};})(avsObj,frm));
    attach('#avs_no','click',(function(avsObj,frm){return function(e){updateToSuggestedAddress(e,avsObj,frm);};})(avsObj,frm));
}*/

function avsVal(E) {
    E.preventDefault();
    var element = E.target, frm = element.form, URL = '/addressvalidation/', AvCode = 0;
    var pars = loadUpPars(frm);
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (avsObj) {
            if (avsObj.error == 0) {
                if (avsObj.Addresses !== undefined) {
                    //showAddressSelector(upsObj,frm); //instead of giving user opt-in choice, force the edit //58974
                    updateToSuggestedAddress(avsObj, frm);
                    frm.submit();
                } else {
                    AvCode = 1;
                    if (frm.AvCode === undefined && frm.AvDate === undefined && avsObj.validated == 1) { //already validated
                        addressValAddElements(frm, AvCode);
                    }
                    //if(frm.name=="oeshipto"){stCheck();}else
                    frm.submit();
                }
            } else {
                if (avsObj.error == 2) {
                    AvCode = 4;
                } //no candidates
                addressValAddElements(frm, AvCode);
                //if(frm.name=="oeshipto"){stCheck();}else
                frm.submit();
            }
        }, error: function () {
            frm.submit();
        }
    });
}

function addressValAddElements(shipTo, AvCode) {
    var today = new Date(), month = parseInt(today.getUTCMonth()) + 1;
    addElement({
        'tag': 'input',
        'parent': shipTo,
        'attributes': [{'name': 'type', 'value': 'hidden'}, {'name': 'value', 'value': AvCode}, {
            'name': 'name',
            'value': 'AvCode'
        }]
    });
    addElement({
        'tag': 'input',
        'parent': shipTo,
        'attributes': [{'name': 'type', 'value': 'hidden'}, {
            'name': 'value',
            'value': today.getUTCFullYear() + "-" + month + "-" + today.getUTCDate()
        }, {'name': 'name', 'value': 'AvDate'}]
    });
}

function popUpGraphic(E, opts) { //grouped function for image popups //SR 57228
    if (E) {
        E.preventDefault();
    }
    var className = 'dialog pop';
    if (opts.id == 'softness') {
        className += ' softness';
    }
    var str = "<div id=\"popUpContent\" style=\"padding:10px;text-align:center;\" >";
    str += opts.str;
    str += "</div>";

    jDialog({
        width: opts.w,
        height: opts.h,
        id: opts.id + 'Pop',
        title: opts.title,
        className: className,
        content: str,
        noTop: opts.noTop
    });
}

function popUpDynamicGraphic(opts) { //used by RelatedLinks table //57836
    opts = {
        id: 'popupGraphic',
        noTop: true,
        w: opts.w,
        h: opts.h,
        title: opts.title,
        str: "<img src=\"" + imagessite + opts.imgUrl + "\" alt=\"" + opts.title + "\" />"
    };
    popUpGraphic(null, opts);
}

function popUpHtml(opts) { //used by RelatedLinks table //58587
    $.ajax({
        type: 'GET', url: opts.url, dataType: 'text', success: function (t) {
            popUpGraphic(null, {w: 600, h: 400, str: t, title: opts.title});
        }
    });
}

function popUpSize(E) {
    //var opts={id:'sizechart',title:'Size Chart',w:'830',h:'640',str:"<img src=\""+imagessite+"graphics/ss_size.gif\" alt=\"Size Chart\" />"};
    var width = 830, docWidth = $(document).width();
    if (docWidth <= width) {
        width = 410/*docWidth * 0.9*/;
    }
    var opts = {id: 'sizechart', title: 'Size Chart', w: width + '', h: '740', str: '<div id="sizechartpopup"></div>'};
    popUpGraphic(E, opts);
    $.ajax({
        type: 'POST',
        url: isMobileOrTabletWidth ? 'sizechart3.html' : 'sizechart2.html',
        dataType: 'text',
        success: function (t) {
            $('#sizechartpopup').html(t);
            // $('#sizechartpopup .dropLink').click(dropBox);
        }
    })

}

function popUpShoes(E) { //SR 56679 - Add shoe size chart to shoe details page
    var opts = {
        id: 'shoesizechart',
        title: 'Size Chart',
        w: '595',
        h: '652',
        str: "<img src=\"" + imagessite + "graphics/shoechart.gif\" alt=\"Size Chart\" />"
    };
    popUpGraphic(E, opts);
}

function popUpGarmentLength(E) {
    //var opts={id:'garmentLength',title:'Length Chart',w:'700',h:'660',str:"<img src=\""+imagessite+"graphics/length_measurements.jpg\" alt=\"Length Chart\" />"};
    //popUpGraphic(E,opts); //replaced with ajax call below in 58601
    var baseId = $(E.target).data('baseid').toString();
    if (baseId.length) {
        $.ajax({
            type: 'POST',
            url: '/p/' + baseId + '/',
            data: 'ajax=1&addlSpecs=1',
            dataType: 'json',
            success: function (t) {
                if (t.popUpHtml && t.popUpHtml.length) {
                    var str = decodeURIComponent(t.popUpHtml).replace(/\+/g, ' ');
                    jDialog({width: 650, height: 750, title: 'Garment Length', content: str});
                }
            }
        });
    }
}

function popUpAddedToWishList(product) { // display popup when user adds item to wishlist - SR 59277

    var opts = {
        id: 'wishListPopUp',
        title: 'Added to Wish List',
        w: '400',
        h: '320',
        content: '<div class="dialog-modal" style="padding: 40px 0px;"><h2 style="font-size:1.3rem; text-align: center;">You\'ve added the ' + product + ' to your wish list!</h2></div><div class="dialog-modal" style="padding: 30px 0px;text-align: center;"><a class="aybl wl_add" href="/list/">View Your Wish List</a></div>'
    };
    jDialog({
        id: opts.id,
        width: opts.w,
        height: opts.h,
        title: opts.title,
        content: opts.content,
        noTop: false,
        noBorder: false
    });
    $("div.ui-dialog").focus();
}

/*function popSoft(E){ //remvoed 59313
    var opts={id:'softness',title:'Softness Chart',noTop:true,w:'504',h:'332',str:"<img src=\""+imagessite+"redesign/softnesspop.gif\" alt=\"Softness Chart\" />"};
    popUpGraphic(E,opts);
}*/

/*function popTime(E){
    var opts={id:'time',title:'Time Saver',noTop:true,w:'504',h:'332',str:"<img src=\""+imagessite+"redesign/graphics/timesaver.gif\" alt=\"Softness Chart\" />"};
    popUpGraphic(E,opts);
}*/

function addErrorToPage(errorMessage) {
    if ($('#error').length) {
        $('#errorBox').html(errorMessage);
    } else {
        $('#page').prepend("<div id='error'><div id='errorBox'>" + errorMessage + "</div></div>");
    }
}


/*function submitLoginCa(E){//submit login for cananda without keynumber popup
    var ele,form;
    E.preventDefault();
    ele = E.target;
    form=ele.form;
    pattern=/login/i;
    pattern2=/billing/i;
    if(pattern.test(ele.id)){form.nextPage.value=0;}
    else{form.nextPage.value=2;}
    if(pattern2.test(ele.id)){form.nextPage.value=0;}
    else{form.nextPage.value=2;}
    k.forms.loginForm.nextPage.value=0;
    k.forms.loginForm.submit();
}*/
/*function findNextPage(E){
    var ele,form;
    E.preventDefault();
    ele = E.target;
    form=ele.form;
    pattern=/login/i;
    pattern2=/billing/i;
    if(pattern.test(ele.id) || pattern2.test(ele.id)){form.nextPage.value=0;}
    else{form.nextPage.value=2;}
    form.submit();
}*/

/*function findNextPageReviewLogin(E){
    var ele,form,answer=false;
    E.preventDefault();
    ele=E.target;
    form=ele.form;
    var pattern=/from=answer/i;
    if(pattern.test(k.location.search)){answer=true;}
    if(ele.id=="checkout_bt_nlogin"){
        if(answer){form.nextPage.value=3;}
        else{form.nextPage.value=1;}
    }else{
        if(answer){form.nextPage.value=2;}
        else{form.nextPage.value=0;}
    }
    form.submit();
}*/
function findPos(obj) {
    var curleft = 0, curtop = 0;
    //bvstring="";
    if (obj.offsetParent) {
        curleft = obj.offsetLeft;
        curtop = obj.offsetTop;
        /*while((obj=obj.offsetParent)) {
         if(obj.id=="undefined" || obj.id!="body"){
         curleft += obj.offsetLeft;
         curtop += obj.offsetTop;
         }
         }*/
    }
    return [curleft, curtop];
}

function isUniqueIdBasedEle(ele) {
    return (ele.id.indexOf("-") > -1);//is always looke like: "type-uid"
}

function getEleUniqueId(ele) {
    return ele.id.split('-')[1];
}

function getEleType(ele) {
    return ele.id.split('-')[0];
}

function getExtSizeByUniqueId(uniqueId) {
    return $('#sizecat-' + uniqueId).value;
}

function getImageId(baseId) {
    baseId = baseId.toString();
    var imgDigitMap = [0, 1, 2, 2, 2, 2, 6, 7, 8, 9];
    return imgDigitMap[baseId.substr(0, 1)].toString() + [baseId.substr(1, 4)].toString();
}

/*function addquickview(array,offsetx,offsety){
    if(typeof offsetx === 'undefined'){offsetx=12;}
    if(typeof offsety === 'undefined'){offsety=16;}
    for(var i=0;i < array.length; i++){
        var ele = $(array[i]).children()[0],pos,parentid,quickimg,parentele=ele,alttext;
        //if(ele.width==167){offsety=16;offsetx=12;}
        if(isUniqueIdBasedEle(ele)){
            var uid=getEleUniqueId(ele);
            parentid=getExtSizeByUniqueId(uid);
        }else{
            pattern=/thumb/i;
            while(!pattern.test(parentele.id)){
                parentele=parentele.parentNode;
            }
            parentid=parentele.id.replace("thumb","");
            parentid=parentid.substring(0,5);
        }

        if(!$(parentele).find('a.quickimg')[0]) { //if it doesn't already have one (avoid duplicates from tab switching)
            pos = findPos(parentele);
            if (pos[0] > 0 || pos[1] > 0) { //only add if position found (img is currently visible)
                //quickimg=new Element('A',{'class':'quickimg'});
                quickimg = $('<a/>',{'class':'quickimg'});
                //alttext=new Element('IMG',{src:imagessite+'redesign/quickview.png',alt:'quick view'});
                alttext = $('<img/>',{src:imagessite+'redesign/quickview.png',alt:'quick view'});
                $(quickimg).html(alttext);
                //var insert = new Insertion.After(ele.parentNode,quickimg);
                $(ele.parentNode).after(quickimg);
                quickimg[0].id="quickimg_"+parentid;
                quickimg[0].style.top = (pos[1]+offsety)+'px';
                quickimg[0].style.left = (pos[0]+offsetx)+'px';
                quickimg[0].title = parentid;
                attach(quickimg,"click",function(){startQuickView(this.event,this.title,"","","","","","",this.id);return false;});
            }
        }
    }
}*/


function switchDetailVideo(img) {
    var selector = ($('#quickWin')[0]) ? '#quickWin' : '#detail';
    if ($('#detailVideo-' + img)[0]) {
        $('#detailVideo-' + img).html('<div id="detailVideoEmbed">Loading Video...</div>');
        var url = videosite + img + '.mp4', qkvwZoom = $('#quickzoom')[0], w, h;
        /*if(qkvwZoom){ //quickview
            w=309;h=463;
        }else{ //detail
            w=400;h=600;
        }*/
        w = $(selector + " .xzoom").width();
        h = $(selector + " .xzoom").height();
        var controls = "";
        if (isiPad) {
            controls = "controls";
        }
        $('#detailVideo-' + img).html('<video id="detailVideoContent" src="' + url + '" width="' + w + '" height="' + h + '" autoplay ' + controls + '>Video not supported</video>'); //add video
        $("#detailVideoContent")[0].addEventListener('ended', function () {
            switchDetailPhoto(img, '', '');
        }); //switch back to main img when video ends
        /*if($('detailZoom-'+img)){
         $('detailZoom-'+img).hide();
         }*/
        /*if($('#flyoutzoom')[0]){
            $('#flyoutzoom')[0].style.visibility="hidden";
        }*/
        $(selector + ' #flyoutzoom').hide();
        $(selector + " .xzoom-preview," + selector + " .xzoom-lens").hide();
    }
    /*else if(swfobject.getFlashPlayerVersion().major>=lowestSupportedFlashVersion){
     if($('detailVideo-'+img)){
     var width=400,height=600;//w=353,h530
     $('detailVideo-'+img).innerHTML='<div id="detailVideoEmbed">Loading Video...</div>';
     var url = mediasite+'video/'+img+'.swf';
     if($('quickzoom')){width=310;height=461;}
     embedFlashVideo(url,width,height,'detailVideoEmbed','detailVideoPlayer');
     videoElementTag();
     if($('flyoutzoom')){
     $('flyoutzoom').style.visibility="hidden";
     }
     }
     }else{
     alert('You need the latest version of Flash Player installed to watch videos.');
     }*/
}

function switchDetailPhoto(img, alt, sold) {
    var selector = ($('#quickWin')[0]) ? '#quickWin' : '#detail';

    //if(sold.length){alt='';} //one of a kind 56162
    img = img.toString().toUpperCase(); //ensure consistency
    if ($(selector + ' .detailVideo')[0]) {
        $(selector + ' .detailVideo').html('');//Delete the flash video. Hiding it causes issues in some browsers
    }

    /*if(typeof(scene7ViewerEnabled)!=='undefined' && scene7ViewerEnabled && !isMobileDevice){
        flyoutViewer.setAsset(getScene7ZoomUrl(img+''+alt+sold));
    }else*/
    if (isMobileDevice && typeof (zoomViewer) !== 'undefined' && zoomViewer) {
        if ($("#flyoutzoom_container").length <= 0) {
            var dtlPhotoDiv = $("#largeDetailPhotoWrapper");
            embedZoomSS(img.toString() + alt.toString(), Math.round(dtlPhotoDiv.width()), Math.round(dtlPhotoDiv.height()));
        } else {
            zoomViewer.setAsset(getScene7ZoomUrl(img + '' + alt + sold));
        }
    } else {
        /*if($(selector+' #flyoutzoom')[0]){//show the product zoom div
            $(selector+' #flyoutzoom')[0].style.visibility="visible";
        }*/
        $(selector + ' #flyoutzoom').show();
        var wid = 550, hei = Math.round(wid * 1.5);
        var imageUrl = getImageUrl(img + alt, '550x826');
        var xzoom = $(selector + " .xzoom");
        if (xzoom.is(":visible")) { //if on desktop and xzoom is being used
            xzoom[0].src = imageUrl;
            xzoom.attr('xoriginal', getImageUrl(img + alt, '1200x1802'));
        } else { //not using zoomviewer
            $('.mbl-loader').show();
            var image = new Image();//preload image before switching, in order to ensure there is no empty image when user switches
            image.onload = function () {
                $('#largeDetailPhotoWrapper').css('background-image', 'url(\'' + imageUrl + '\')').attr('data-imgurl', img.toString() + alt.toString());
                $('.mbl-loader').hide();
            };
            image.src = imageUrl;
        }
    }
}

function altViewEvent(E) {
    E.preventDefault();
    if (isMobileDevice) {
        var ele = E.target.firstChild;
        var activealt = $('.active-alt');
        var parent = $(E.target.parentElement);
        parent.addClass("active-alt");
        activealt.removeClass('active-alt');
    } else {
        var ele = E.target;
    }
    var elementinfo = ele.id.split("_");
    var img = elementinfo[1];
    if ($('#detailZoom-' + img + 'sold')[0]) {
        $('#detailZoom-' + img + 'sold')[0].id = "detailZoom-" + img;
    }
    var alt;
    try {
        if (elementinfo[2].length == 2) {
            alt = elementinfo[2];
        } else {
            alt = "_" + elementinfo[2];
        }
    } catch (s) {
        alt = "";
    }
    if (alt == "_0") {
        alt = "";
    }
    //lastDetailImage=img; //not used?
    if (elementinfo[2] == 'v') {//Change Scene 7 image to video
        switchDetailVideo(img);
    } else { //Switch Scene 7 Image
        switchDetailPhoto(img, alt, '');
    }
    //update pinterest url to pass the new image
    //var media = 'https://softsurroundings.scene7.com/is/image/SoftSurroundings/'+img+alt;
    var media = getImageUrl(img + alt, '550x826');
    refreshPinterestButton(media);
}

/* when there are too many alt imgs to display beside the large photo, this will scroll the visible portion of the alts from the top of the list to the bottom of the list */
function altImgScrollDown() {
    var ul = $('#detailAltImgs'), scrollDist;

    //Toggle the scroll buttons
    $("#dtlAltUpNavWrap").fadeIn();
    $('#dtlAltDownNavWrap').fadeOut();

    //Scroll the imgs
    if (ul.closest("#quickDetailAltImgs").length) { //if in quickview
        //scroll horizontally to the end of the ul, based on the width difference with its wrapper
        var li = ul.find("li"), liWid = li.length * li.outerWidth(); //unlike detail page logic below, calculate width by summing li's b/c for some reason ul.width() does not include hidden portion even tho ul.height() does
        scrollDist = liWid - $("#dtlAltImgWrap").width();
        ul.animate({left: '-=' + scrollDist});
    } else { //if on detail page
        //scroll vertically to the end of the ul, based on the height difference with its wrapper
        scrollDist = ul.height() - $("#dtlAltImgWrap").height();
        ul.animate({top: '-=' + scrollDist});
    }
}

/* when there are too many alt imgs to display beside the large photo, this will scroll the visible portion of the alts from the bottom of the list back to the top of the list */
function altImgScrollUp() {
    var ul = $('#detailAltImgs');

    //Toggle the scroll buttons
    $('#dtlAltUpNavWrap').fadeOut();
    $('#dtlAltDownNavWrap').fadeIn();

    //Scroll the imgs
    if (ul.closest("#quickDetailAltImgs").length) { //if in quickview
        //reset scroll horizontally
        ul.animate({left: 0});
    } else { //if on detail page
        //reset scroll vertically
        ul.animate({top: 0});
    }
}

function switchEmbedVideo(E) {
    var element = E.target;
    while (element.tagName != 'A' && element.parentNode) { //in case img is clicked, cascade up to anchor tag
        element = element.parentNode;
    }

    $('#dtlEmbedVideo > source').attr('src', $(element).data('video'));
    var embedVideo = $('#dtlEmbedVideo')[0];
    embedVideo.load();
    embedVideo.play();

    $('.dtlVideoThumbCol > div').removeClass('selected');
    $(element).closest('div').addClass('selected');
}


function loadThumbs(frm) { //load in next page of thumbs
//Azure 730 - Change number of images to load during lazyload \/
//    var thumbsPerAjaxPage=50, //number also defined in ThumbModel
    var thumbsPerAjaxPage = 24, //number also defined in ThumbModel
//Azure 730 /\
        pageUrlSplitByQueryStrings = window.location.href.split("?"),
        pageUrlWithOutAnyQueryStrings = pageUrlSplitByQueryStrings[0],
        urlQueryStringArray = pageUrlSplitByQueryStrings.length > 1 ? pageUrlSplitByQueryStrings[1].split("&") : '',
        pageNum = frm.page.value, pars = 'ajax=1&docKeys=' + frm.docs.value + '&count=' + frm.currentThumbCount.value;
    var regexp = /\/page-?([1-9]|([0-9][0-9]))\/?$/;
    if (regexp.test(pageUrlWithOutAnyQueryStrings)) {
        var URL = pageUrlWithOutAnyQueryStrings.replace(regexp, '/page-' + pageNum + '/')
    } else {
        var URL = pageUrlWithOutAnyQueryStrings + 'page-' + pageNum + '/';
    }
    if (urlQueryStringArray.length > 0) {
        var validAddedQueries = getNarrowByQueryString(urlQueryStringArray);
        if (validAddedQueries != '') {
            URL += "?" + validAddedQueries.slice(0, -1);
        }
    }
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (t) {
            $('#thumbs').append(t.content);
            //Add quick view listener to the new thumbs
            $('input[name=currentThumbCount]').each(function () {
                $(this).val(t.count);
            });//update the current thumb count
            attach($("a.qkvw").slice(thumbsPerAjaxPage * (pageNum - 1)), "click", (function () {
                return function (e) {
                    startQuickView(e, this.title, "", "", "", "", "", "");
                };
            })());
            //changeThumbHeights(pageNum);
            if (detectHistorySupport()) {
                window.history.replaceState('', "", URL);
            }
        }
    });
}

function getNarrowByQueryString(queryString) {
    var addedQueryString = '';
    for (var i = 0; i < queryString.length; i++) {
        if (queryString[i].match('extsize|color|pricemin|pricemax|brand|productsfor|rated/i')) {
            addedQueryString += queryString[i] + "&";
        }
    }

    return addedQueryString;
}

// Simple feature detection for History Management
function detectHistorySupport() {
    return !!(window.history && history.replaceState);
}

/*function smoothPhone(E){ //58515 use one phone field
    var ele = E.target,num=ele.id.substr(11,1);
    if(num<2){
        if(ele.value.length == 3){
            ele.blur();
            var id='phoneNumber'+((num*1)+1);
            $('#'+id).focus();
            $('#'+id).value="";
        }
    }
}*/

/*******************************************************************************************************/
/*  SCENE 7 */
/*******************************************************************************************************/
/*function S7ConfigObject()
{
    this.isVersion		= "2.8";
    // These root variables should be altered to reflect the server VIP you are on
    // For example, if you call sample.scene7.com/is/image, you should use that here
    this.isViewerRoot	= "https://softsurroundings.scene7.com/s7viewers/";
    this.isRoot		= "https://softsurroundings.scene7.com/is/image/";
}
var S7ConfigClient = new S7ConfigObject();*/

/*******************************************************************************************************/
/*  SCENE 7 */

/*******************************************************************************************************/
function getScene7ZoomUrl(img) {
    //var modifiers='?fmt=jpeg&qlt=90,1&op_sharpen=0&resMode=bilin&op_usm=0,0,0,0&iccEmbed=0&printRes=72';
    var modifiers = '?fmt=jpeg&op_sharpen=0&resMode=bilin&iccEmbed=0&printRes=72&wid=400&hei=600';
    return 'SoftSurroundings/' + img + modifiers;
}

//flyoutViewer='';
//zoomViewer='';
function embedZoomSS(img, width, Height, quickview) {
    //var imgId = img.substr(0,5); //img may contain inv5 or inv7, imgId must be inv5, either can add 'sold' below
    //if($('#detailZoom-'+imgId)[0] || $('#detailZoom-'+imgId+'sold')[0]){ //one of a kind 56162
    //if ($('#detailZoom-'+imgId+'sold')[0]){imgId=imgId+'sold';img=img+'sold';}
    //if(scene7ViewerEnabled){//Non-HTTPS normal usage //58739 migrate from scene7 - replace desktop with xzoom and mobile with custom
    try {
        if (quickview) {
            //flyoutViewer = new s7viewers.FlyoutViewer();
            //flyoutViewer.setContainerId("quickflyoutzoom");
            $("#quickWin .xzoom").xzoom({zoomWidth: width, tint: '#333', Xoffset: 15});
        } else if (isMobileDevice) {
            //zoomViewer = new s7viewers.BasicZoomViewer();
            //zoomViewer.setContainerId("flyoutzoom");
            setupMblFullScreen('.mbl-detail-photo-zoom');
        } else {
            //flyoutViewer = new s7viewers.FlyoutViewer();
            //flyoutViewer.setContainerId("flyoutzoom");
            $("#detail .xzoom").xzoom({zoomWidth: width, tint: '#333', Xoffset: 20});
        }
        //var serverurl = ('https:' == document.location.protocol) ? 'https://softsurroundings.scene7.com/is/image/' : 'http://s7d1.scene7.com/is/image/';
        /*if(!isMobileDevice) {
                flyoutViewer.setParam("serverurl", serverurl);
                flyoutViewer.setParam("flyouttransition", "fade");
                flyoutViewer.setParam("zoomfactor", "3.5");
                flyoutViewer.setAsset(getScene7ZoomUrl(img));
                flyoutViewer.init();
            }else {
                zoomViewer.setParam("serverurl", serverurl);
                zoomViewer.setParam("zoomfactor", "3.0");
                zoomViewer.setAsset(getScene7ZoomUrl(img, width, Height));
                zoomViewer.init();
            }*/

        //add an alt tag to the zoomable image - sr 57899
        /*$('#flyoutzoom, #quickflyoutzoom').on('DOMNodeInserted', function(e) {
                var imgAltTitle = $('#productName').text();//set the alt tag for the dynamically loaded image

                if(quickview) {
                    //quickview alt tag - main/zoomable image
                    $("#quickflyoutzoom_flyout img").prop('alt',imgAltTitle);
                } else {
                    //regular view alt tag - main/zoomable image
                    $("#flyoutzoom_flyout img").prop('alt',imgAltTitle);
                }

            });
            $('#detailZoom-'+imgId).addClass(flyoutViewer.id);*/
    } catch (error) {
        console.log(error);
    }
    //}
}

function setupMblFullScreen(selector) {
    var $photoWrapper = $('#largeDetailPhotoWrapper');
    $(selector).on('click', function () {
        var $this = $(this);
        if ($this.hasClass('isInFullScreen')) {
            $photoWrapper.removeClass('fullscreen');
            $this.removeClass('isInFullScreen');
            $('body').unbind('touchmove').removeClass('stop-scrolling');
            $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1, maximum-scale=1'); //return to maximum-scale to limit zoom
        } else {
            $('.mbl-loader').show();

            var img = $photoWrapper.attr('data-imgurl');
            if (!img) {
                img = $('#parentId').val();//gett the default inv5
            }
            //var imageUrl=productImageSite+img+"?wid=670&hei=1005";
            var imageUrl = getImageUrl(img, '550x826');
            var image = new Image();//preload image before switching, in order to ensure there is no empty image when user switches
            image.onload = function () {
                $photoWrapper.css({'background-image': 'url(\'' + imageUrl + '\')'}).addClass('fullscreen');
                $('.mbl-loader').hide();
                $('body').bind('touchmove', function (e) {
                    e.preventDefault()
                }).addClass('stop-scrolling');
                $this.addClass('isInFullScreen');
            };
            image.src = imageUrl;
            $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1'); //remove maximum-scale so use can zoom
        }
    });
}

function attachDetailEvents(clickfromwin) {
    var selector = ".dtlForm", quickview = 0;
    if ($('#quickWin')[0] && clickfromwin) {
        selector = "#quickWin"; //"#"+Windows.focusedWindow.element.id+"_content";
        quickview = 1;
    }
    //attach('.detailRelatedLink','click',throwCoreMetricsRelatedLink);
    /*$('.detailRelatedLink-video').click(function() {//send a related links video click to google
        var urlLongDesc = $(this).data('longdesc');
        sendGoogleAnalyticsEvent('Videos', 'Play', urlLongDesc + ' Related Links Video', '');
    });//sr 58124*/ //event moved to google tag manager 58146
    attach(selector + ' a.size', 'click', detailSpecChange);
    attach(selector + ' a.size', 'keypress', detailSpecChange); //Enter key on size - ADA compliance
    attach(selector + ' img.color', 'click', detailSpecChange);
    //attach(selector+' .swatchHover','click',detailSpecChange); //SR 58932
    attach(selector + ' img.color', 'keypress', detailSpecChange); //Enter key on swatch - ADA compliance
//SR 57267	attachstuff(1,selector+' input.tbs','click',detailSpecChange,'INPUT');
    attach(selector + ' a.tbs', 'click', detailSpecChange); //SR 57267
    attach(selector + ' a.tbs-gc', 'click', detailGiftCardChange); //SR 57267
    attach(selector + ' a.tbs', 'keypress', detailSpecChange); //Enter key on misses, petites, etc - ADA compliance
    attach(selector + ' .max-countdown', 'keyup', updateMaxCounter); //SR 58942
    //attachstuff(1,selector+' input.aybl','click',aybEvent,'');
    attach(selector + ' input.aybl', 'click', checkForAssociations);
    attach('#detailOutfits input.aybl', 'click', checkForAssociations);
    attach('#wgw', 'click', checkForWhiteGlove);
    attach(selector + ' input.eybl', 'click', aybEvent);
    if (selector == ".dtlForm" && $('#relatedinfo')[0]) {
        attach('.relatedInfoTab', 'click', toggleRelatedInfo);
        attach('.relatedInfoTab', 'keypress', toggleRelatedInfo); // Enter key - ADA compliance
    }
    attach('.tabWrapper .tabs', 'click', toggleTabs);
    attach(selector + ' .shoesizechart', 'click', popUpShoes);
    //attachstuff(1,selector+' .monolink','click',monogramPopUP,'');
    //attachstuff(1,selector+' .monoimage','click',monogramPopUP,'');
    //attachstuff(1,selector+' .monoPopUp','click',monogramPopUP,'');
    attach('.monoPop', 'click', monogramPopUP); //consolidated above into one call
    attach(selector + ' input.wl_add_dtl', 'click', atwdtEvent);
    attach('#detailOutfits input.wl_add_dtl', 'click', atwdtEvent);
    attach(selector + ' input.wl_add_dtlPOS', 'click', atwdtEventPOS);
    attach('#detailOutfits input.wl_add_dtlPOS', 'click', atwdtEventPOS);
    attach('a.detailAltVideo', 'click', altViewEvent);
    //attach('#detailWriteReview','click',detailWriteReview);
    if (!isMobileDevice) {
        //attachSwatchHoverEvents();
    }
    attach('.dropLink:not(.payment)', 'click', dropBox);
    //attach('#clock','click',popTime);
    //attach('.cloud','click',popSoft);
    attach('.sizechart', 'click', popUpSize);
    attach('#garmentLength.cop', 'click', popUpGarmentLength);
    attach('.poplink', 'click', faqPopUp);
    if (quickview) {
        attach('#size select,#color select', 'change', detailSpecChange);
    }
    if ($('#detailOutfits')) {
        attachShopThisLookItemEvents();
    }
    attach(".lucy", "click", callMePop);
    attach('p.storeInventoryExpandedContent .storeInventoryStatusBlue', 'click', itemInquiryPop);
    if (isMobileDevice) {
        mobileDetailPhotoSlider();
        attachAtlViewEvents();
        attach('.largeDetailLeftArrow img', 'click', function (e) {
            updateAltImgOnSwipe(e, 'right');
        });
        attach('.largeDetailRightArrow img', 'click', function (e) {
            updateAltImgOnSwipe(e, 'left');
        });
    }
    handlePRReadReviews();
}

function attachSwatchHoverEvents() {
    if (!('ontouchstart' in document.documentElement)) {//hover on touch device blocks the click event until you tap again - confusing to user
        $('a.swatchlink').hover(function () {
            $(this).find('img.color').nextAll().fadeIn(300);
        }, function (x) {
            $(this).find('img.color').nextAll().fadeOut(300);
        });
    }
}

/*function detailWriteReview(){
}*/
function attachWishListEvents() {
    attach('a.wl_remove', 'click', removeFromList);
    ///attach('a.wl_removeall','select',rfwAllEvent);
    attach('a.wl_edit', 'click', eybEvent);
    attach('.wl_add', 'click', addToCartFromListEvent);
    attach('a.wl_share', 'click', popUpWishlistShare);
}

function attachCollectionItemEvents() {
    attach('.dtl_bd_sel', 'change', QuickSellItemChange);
    //attach('.collectDetailLink','click',startViewDetailsQuickView);
    attach('a.collect_desc', 'click', startViewDetailsQuickView);
}

function attachShopThisLookItemEvents() {
    attach('a.collect_desc', 'click', startViewDetailsQuickView);
    attach('.dtl_bd_sel', 'change', QuickSellItemChange);
}

function attachMultiQVEvents() {
    attach('.dtl_bd_sel', 'change', QuickSellItemChange);
    attach('.ayblMQV', 'click', aybEvent);
}

function attachGWPEvents() {
    attach('.dtl_bd_sel', 'change', QuickSellItemChange);
}

function attachDetailBeddingEvents() {//used for other sections like shop this look
    createQuickOrderUids();
    //observeMulti('input.checkbox','click',beddingSizeChange); //not used anymore? 10/15
    attach('a.tbs', 'click', QuickSellItemChange); //size cat selections
    attach('.dtl_bd_sel', 'change', QuickSellItemChange);
    //attachstuff(1,'.monolink','click',monogramPopUP,'');
    //attachstuff(1,'.monoimage','click',monogramPopUP,'');
    attach('.monoPop', 'click', monogramPopUP); //consolidated above into one call
    attach('.collect_desc', 'click', startViewDetailsQuickView);
    //attach('.collectDetailLink','click',startViewDetailsQuickView);
    //if(!$('#detailBeddingButtons')[0] && (!$('#ocont')[0])){ //these forms used to use single buttons that submitted the whole form but not with 58738 redesign
    attach('input.aybl', 'click', checkForAssociations);
    //}
    attach('input.inpGcDel', 'click', toggleQuickGCFields);
    attach('.dropLink:not(.payment)', 'click', dropBox);
    //attach('.cloud','click',popSoft);
}

function startViewDetailsQuickView(E) {
    var ele = E.target;
    while (!ele.id && ele.parentNode) { //if the event registered on a child element, go up until we find the right one
        ele = ele.parentNode;
    }
    var eleIdParts = ele.id.split("_");
    var parentId = eleIdParts[1];
    startQuickView(E, parentId, '', '', '', '', '', '')
}

function attachAtlViewEvents() {
    attach('.alt_dtl', 'click', altViewEvent);
    attach('#dtlAltUpNav', 'click', altImgScrollUp);
    attach('#dtlAltDownNav', 'click', altImgScrollDown);
}

function attach(el, action, func, add) {
    if (!add) $(el).off(action, func); //default to remove existing listener unless we explicitly tell it to add this function to existing events
    $(el).on(action, func);
}

function attachDelegate(el, action, func, add) {
    if (typeof el !== "string") {
        throw new Error("Elements of delegated events should be a string of CSS selector!");
    }

    if (!add) {
        $(document).off(action, el, func);
    } //default to remove existing listener unless we explicitly tell it to add this function to existing events
    $(document).on(action, el, func);
}

/*function observeMulti(cssSelector,eventType,functionToCall){
    //This function can do 90% of what we use function attachStuff() for without the complexity
    //$$(cssSelector).each(function(el){el.observe(eventType,functionToCall);});
    attach(cssSelector,eventType,functionToCall); //temp transition
}*/
/*function attachstuff(cla,item,action,funct,limit){ //should be fully deprecated now - jah 5/16
    attach(item,action,funct); //temp transition
    //Try using function observeMulti above. It is much simpler.
    /*var Links;
     if(cla && $$(item)){
     Links=$$(item);
     }else if(!cla && $(item)){
     Links=$(item).childNodes;
     }
     if(Links && Links.length) {
     for (var i = 0; i < Links.length; i++) {
     if (limit == "" || Links[i].tagName == limit) { //no limit, or this tag is the limit
     Event.observe(Links[i], action, funct);
     }
     }
     }*/

//}

function attachCartEvents() {
    var Links = $('a.action_link'), element, elementinfo, action, lineId;
    $(document)
        .off("click", "[id^='save_']", addToListFromCartEvent)
        .on("click", "[id^='save_']", addToListFromCartEvent);

    for (var i = 0; i < Links.length; i++) {
        element = Links[i];
        elementinfo = element.id.split("_");
        action = elementinfo[0];
        lineId = elementinfo[1];
        //if (isMobileDevice) {

        if (action === "remove") {
            attach(element, 'click', rfbEvent);
        }
        if (!isMobileDevice) {
            if (action === "edit") {
                attach(element, 'click', eybEvent);
            }
        }

        /*if (!0) {
            if (action === "remove") {
                attach(element, 'click', rfbEvent);
            }
            if (!isMobileDevice){
                if (action === "edit") {
                    attach(element, 'click', eybEvent);
                }
            }
        } else {
            if (action === "edit") {
                attach(element, 'click', eybEvent);
            } /!*else if (action === "remove") {
                attach(element, 'click', rfbEvent);
            }*!/ else if (!["later", "remove"].includes(action)) {
                attach(element, 'click', addToListFromCartEvent);
            }
        }*/

    }
    //if($('#contShop')[0]){attach('#cnt_shp_bt','click',continueShopping);}
    if ($('.gwpPromo')[0]) {
        attach('.gwpPromo', 'click', giftWithPurchasePopupEvent);
    }
    attach('#vyw', 'click', vywEvent);
    attach('.stlLink', 'click', function () {
        popUpShopThisLookItems($(this).data('parentid'));
    });
    attach('.poplink', 'click', faqPopUp);
}

/* *GIFT WITH PURCHASE FUNCTIONS*/

//the click event - separated from the actual ajax call so we can also trigger the ajax call on page load via auto pop //58339
function giftWithPurchasePopupEvent(E) {
    var el = E.target;
    while (el.tagName != 'A' && el.parentNode) { //in case img is clicked, cascade up to anchor tag
        el = el.parentNode;
    }
    //extract the gwp id so we can trigger the popup
    var gwpid = $(el).data('gwpid');
    giftWithPurchasePopup(gwpid);
}

function giftWithPurchasePopup(gwpid) {
    var URL = '/popup/giftwithpurchase/',
        pars = "gwpid=" + gwpid, onCloseFn; //pass id to distinguish between multiple active offers //SR 58339
    if ($('input.gwpHandled')[0]) {
        onCloseFn = function () {
            $('input.gwpHandled').val('1');
            $('#aybl').click();
        }
    } else {
        onCloseFn = function () {
        };
    }
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (t) {
            if (t.valid) {
                jDialog({
                    width: 700,
                    height: 728,
                    content: t.popBody,
                    title: 'Congratulations',
                    className: 'dialog pop',
                    close: onCloseFn
                });
                attachGWPEvents();
            }

            /*if($(".popBorder").length > 0) {
                var largestHeight = 0, textHidden = false;
                var thumbElements = $("#giftWithPurchase .product");
                //check for content not showing correctly or hidden
                thumbElements.each(function () {
                    if (this.scrollHeight > $(this).innerHeight()) {
                        textHidden = true;
                        largestHeight = (this.scrollHeight > largestHeight ? this.scrollHeight : largestHeight);
                    }
                });
                if (textHidden) {//there is hidden text in one of the thumbs so resize appropriately based on the largest thumb
                    var heightDiff = largestHeight - thumbElements.first().innerHeight();
                    //change the height of the divs
                    thumbElements.css('height', largestHeight + "px");
                    if (heightDiff < 40) {
                        //the height of the popup box needs to be adjusted
                        var element = $('.popBorder #dialog-modal');
                        element.css("height", element.height() + heightDiff + "px");
                    }
                }
            }*/
        }
    });
}

function submitGiftWithPurchase(form) {
    var URL = "/popup/giftwithpurchase/", pars = loadUpPars(form);
    pars += "&validategwp=1&page=" + page;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
            if (json.success == "1") {
                if (!$('input.gwpHandled')[0]) { //detail gwp actions will be handled by onCloseFn triggered below //58984
                    buildCart(json, page, '');
                }
                jDialogClose(form);
            } else {
                if (json.errors) {
                    $("#gwpErrors").html(json.errors);
                }
                form.submit.disabled = false;//reset to allow another try
            }
        }
    });
}

/*if(window.location.toString().match("myAccount")){
    attach('#cnt_shp_bt','click',continueShopping);
}*/
//sr#56481 - eamil popup for pinterest visitors
//check if user comes from pinterest here
/* 5/23/2016: Removed both of these per gail. we are using listrak for our email modals currently.
$(function(){ //document.observe("dom:loaded", function(){
    if(ref.match(/^https?:\/\/([^\/]+\.)?pinterest/i)){
        popupFlag = (localStorage) ? localStorage.ssPinterestEmailPopup : readCookie('pinPopupCookie');
        switch(popupFlag){
            case '1'://have been here once, call pop up
                pinterestPopup();
                (localStorage) ? localStorage.ssPinterestEmailPopup=2 : setCookie('pinPopupCookie',2,365);
                break;
            case '2'://have been here and seen popup, dont call
                break;
            default://first time here from pinterest, flag for 1 visit
                (localStorage) ? localStorage.ssPinterestEmailPopup=1 : setCookie('pinPopupCookie',2,365);
                break;
        }
    }
    //sr 56869 - email pop up - now in tealium - moved back out SR 57412
    if(window.location['href'] == 'https://www.softsurroundings.com/'){
        popupFlag = (localStorage) ? localStorage.ssSubEmailPopup : readCookie('emailSubPopupCookie');
        switch(popupFlag){
            case '1'://have been here once, dont call
                break;
            default://first time, flag for 1 visit
                emailSubPopup();
                (localStorage) ? localStorage.ssSubEmailPopup=1 : setCookie('emailSubPopupCookie',1,365);
                break;
        }
    }
});
*/
/*function canadaPop(){
    var popupFlag = (localStorage) ? localStorage.canadaPopUp : readCookie('canadaPopupCookie');
    switch(popupFlag){
        case '1'://have been here once, dont call
            break;
        default://first time, flag for 1 visit
            canadaShipPopUp();
            (localStorage) ? localStorage.canadaPopUp=1 : setCookie('canadaPopupCookie',1,365);
    }
}*/

if (window.location.toString().match(checkString)) {
    attachCartEvents();
    attachWishListEvents();
}

/* On thumb nav checkbox filters, reload the page based on the link next to the checkbox */
function loadNarrowBy(E) {
    var url = $(E.target).closest('li').find('a').prop('href');
    if (url.length) {
        window.location.href = url;
    }
}

if (page == "thumbs") {
    var offset = 220, scrollTopOn = false, backToTop = $('#backToTop');
    $(function () { //document.observe("dom:loaded", function(){
        //attach("a.qkvw","click",function(){startQuickView(this.event,this.title,"","","","","","");return false;});
        attach("a.qkvw", "click", (function () {
            return function (e) {
                startQuickView(e, this.title.substr(0, 5), "", this.title.substr(5, 2), "", "", "", "");
            };
        })());

        if ($(backToTop)[0]) {
            attach(window, "scroll", function () {
                var scrollPos = $(window).scrollTop(); //document.viewport.getScrollOffsets()['top'];
                if (scrollPos > offset) {
                    if (!scrollTopOn) {
                        $(backToTop).fadeIn(500);
                        scrollTopOn = true;
                    } //"$('#backToTop').appear({duration:.5});
                } else {
                    if (scrollTopOn) {
                        $(backToTop).hide();
                        scrollTopOn = false;
                    }
                }
            }, 1);

            attach('#backToTop', 'click', function (e) {
                e.preventDefault();
                $('html,body').animate({scrollTop: 0}, 200); //Effect.ScrollTo('wrapper',{duration:.2});
                return false;
            });
        }

        //listen for loading in next page of thumbs via ajax
        var thumbOffset = 2000, loadedThumb = [], frmNextPage = $(".thumbscroll")[0];
        if (frmNextPage) {
            attach(window, "scroll", function () {
                var scrollPos = $(window).scrollTop(); //document.viewport.getScrollOffsets()['top'];
                if (scrollPos > thumbOffset) {
                    var scrollPage = Math.floor(scrollPos / thumbOffset) + 1;
                    var frmNextPage = $("#frmThumbPage" + scrollPage)[0];
                    if (frmNextPage && !loadedThumb[scrollPage]) {
                        loadThumbs(frmNextPage);
                        loadedThumb[scrollPage] = true;
                    }
                }
            }, 1);
        }

        //changeThumbHeights(0); //not needed with flex

        initFixedNav();
        initFixedThumbNav();
        attach('.nbChk', 'click', loadNarrowBy);
        attach('.nbChk', 'keypress', function (e) {
            if (e.which === 13) {
                loadNarrowBy(e)
            }
        }); //check box on enter key
    });
    /*mobile thumb view events*/
    if (isMobileDevice) {

        $('#sortSelect').change(function (event) {
            //throwThumbsSortBy(event);
            document.getElementById('sortOption').value = event.target.value;
            $('#thumbForm').submit();
        });
        $('.narrowBySelect').change(function (event) {
            if (event.target.value != 'none') {
                window.location = urlDecode(window.location.href + event.target.name + '/' + event.target.value + '/');
                if (event.target.name = 'Color') {
                    //throwThumbsNarrowColor(event)
                } else if (event.target.name = 'ExtSize') {
                    //throwThumbsNarrowSize(event)
                }
            }
        });
        $('.categorySelect').change(function (event) {
            if (event.target.value != 'none') {
                window.location = urlDecode(window.location.href + event.target.value + '/');
            }
        });
        //mobileCategorySelect();
    }
}

function urlDecode($value) {
    return $value.replace('&amp;', '&');
}

/*function changeThumbHeights(offset){*/
/* 5/26/16-kjb:This just changes the left nav boxes to line up correctly with the thumbs.
       Removed dynamic heights in favor of static thumb heights
       beauty thumbnails have an extended height compared to regular so may have to tweak nav heights for beauty only*/
/*var products =  $('.prodnoqv');
    if(products.length){
        var tierDividedSection = $('#tierDivided')[0];
        var haveChangedNavHeight=false;
        var thumbHeaderHeight = $('#thumbheader').height();
        if(tierDividedSection){
            thumbHeaderHeight += $(tierDividedSection).next(1).height()
        }
        var navHeight = $('#nav').height(),navSection = $('.section');
        var longerNav = false, longerThumbHeader=false;
        if(navHeight < thumbHeaderHeight) {
            longerThumbHeader = thumbHeaderHeight - navHeight;
        }else{
            longerNav = navHeight - thumbHeaderHeight;
        }
        var thumbsPerRow = 4;
        if(tierDividedSection && isiPad){
            thumbsPerRow -= 2;
        }//initial thumbers per row on ipad/tierdivided, should be 3
        else if(isiPad || tierDividedSection){
            thumbsPerRow--;
        }
        products.each(function(index) {
            if ((!isiPad && thumbsPerRow == 4) || ((isiPad || tierDividedSection) && (thumbsPerRow == 3 || thumbsPerRow == 2))) { //if we might be at the end of a short row
                if (longerNav) {
                    longerNav = longerNav - 407;
                }
                if (longerNav !== false && longerNav <= 0 && !haveChangedNavHeight) {
                    haveChangedNavHeight = true;
                    //if(isiPad){thumbsPerRow=4}
                    //else{thumbsPerRow=5;}
                    thumbsPerRow++; //past nav now, increment thumbs per row
                    if (tierDividedSection) {
                        thumbsPerRow++;
                    }
                    if ($(navSection)[0]) { //if offset, already adjusted on initial page load
                        var bottomSectionNav = $(navSection).last()[0];
                        longerNav = (longerNav * -1) + $(bottomSectionNav).height() + 42;
                        bottomSectionNav.style.marginBottom = '7px';
                        bottomSectionNav.style.height = longerNav + 'px';
                        return false;
                    }
                } else if (longerThumbHeader !== false) {
                    if ($(navSection)[0]) { //if offset, already adjusted on initial page load
                        var bottomSectionNav = $(navSection).last()[0];
                        //navHeight = (navHeight * -1) + $(bottomSectionNav).height() + 42;
                        navHeight = $(bottomSectionNav).height() + longerThumbHeader;
                        bottomSectionNav.style.marginBottom = '7px';
                        bottomSectionNav.style.height = navHeight + 'px';
                        return false;
                    }
                }
            }
        });


        if(!haveChangedNavHeight){
            var origNavHeight = $('#nav').height();
            navHeight = thumbHeaderHeight - origNavHeight;
            if($(navSection)[0]){
                var bottomSectionNav = $(navSection).last()[0];
                var bottomSectionNavHeight = bottomSectionNav.offsetHeight;
                navHeight = navHeight + bottomSectionNavHeight;
                bottomSectionNav.style.marginBottom = '0px';
                if(navHeight < 70){navHeight=bottomSectionNavHeight;}
                bottomSectionNav.style.height = navHeight + 'px';
            }
        }
    }
}*/

initMenuHover();
if (page.match("address-book")) {
    attach('#ma_bt_ab', 'click', avsVal);
}
if (page.match("myaccount/")) {
    $('#epn').on('click', function () {
        $('#profileNameForm').removeClass("hide");
        $('#profileName').hide();
        return false;
    });
    $('#efs').on('click', function () {
        startFavoriteStorePopup();
        return false;
    });
    $('#rfs').on('click', function () {
        deleteFavoriteStore();
        return false;
    });
    /*$('#epe').on('click',function(){
        $('#profEmailForm').removeClass("hidden");$('#profEmail').hide();
    });
    $('#epp').on('click',function(){
        $('#profpwForm').removeClass("hidden");$('#profpw').hide();
    });
    $('#epb').on('click',function(){
        $('#billingInfoForm').removeClass("hidden");$('#billingInfo').hide();
    });*/
}

if (isMobileDevice) {
    if (page.match("orderstatus/") || page.match("manageemailpreferences/") || page.match("myaccount/")) {
        if ($('.searchMenu')) {
            $('.dropLink').on('click', dropBox);
        }
    }
    $('.footercolheader').each(function () {
        $(this).on('click', handleMobileFooter);
    });
    // Adding to Fix Mobile Header, This will start the code that adjusts the header.
    initFixedNav();
}

if (page == "search") {
    page = "";
    page = window.location.pathname.toString().replace("checkout/", "");
    /*if(page=="/ForgotPassword/"){
        selectCmElementTag('forgotPassword',''); //todo move this line to View and remove this whole if statement
    }*/
}

if (!isMobileDevice) {
    attach('#shoplink', 'click', dropCart);
}
/*attach('#signInPopLink','click',function(E){ //no sign in popup 58738 redesign
    if ($(this).data('page') !== 'myAccount') {//make sure we are not already on the myaccount page, was causing issues when the popup box was loaded on the myaccount page.
        E.preventDefault();
        signInPop(1,true);
        //selectCmEventTag('newAccountIni');
    }
});*/
attach('#vyw', 'click', vywEvent);
//attach('#faw','click',fawEvent);
attach('.manageEmailLink', 'click', manageEmailEvent);
attach('#ma_bt_addr_pop', 'click', manageEmailEvent);
//attach('#ME','click',checkForManageEmail);
//if($('janrainButtons')){attachstuff(1,'#janrainButtons a','click',sendCmJanrain,'')}
attach('.poplink', 'click', faqPopUp);
attach('#pop_giftCert', 'click', faqPopUp); //to be called by a footer link with id set
attach('.popPressRm', 'click', pressPopUp);
/* Doesn't look like this is used, onclicks commented out on subscribeview.php as well jlm 8/24/2016

if(page=="subscribe"){ //SR 56620 Implement Tealium
    if($('TopFooterCom')){
        selectCmEventTag('topFooterCom');
    } else
    if($('faqSubscribeCom')){
        selectCmEventTag('faqSubscribeCom');
    }
    // if($$('.csplpu')){Event.observe($$('.csplpu')[0],'click',emailCheckPopUp);}
}

if(window.location.toString().match("myAccount")){
    if($('#NavigationCreateNewAccount')[0]){
        selectCmEventTag('NavigationCreateNewAccount');
    }
} //end Tealium*/

if (page == 'faq') {
    if ($('.gendropit')) {
        $('.gendropit').dropit();
    }
}
/** Lookbooks */
if (typeof runLookbook === 'function') {
    runLookbook();
} //defined in Lookbooks generated via WebAdmin
if ($("#lbScroll")[0]) { //57655
    attach(window, 'scroll', function () {
        if (typeof fadeLBScroll !== "undefined") {
            clearTimeout(fadeLBScroll);
        }
        $("#lbScroll").fadeIn(500);
        fadeLBScroll = setTimeout(function () {
            $("#lbScroll").fadeOut(500);
        }, 3000);
    });
}

//SR#55973 Update Certona Resonace JS
//After updating our certona JS, added a resx callback function in certona.php to help ensure quickview was loaded
function resonanceResponseLoadedAll() {
    //addQuickViewIcons(); //not after 58738 redesign
}

/*function addQuickViewIcons(){
    try {
        if($('#suggItems')[0]){addquickview($('div.detailsugg > a'));}
    } catch (ex) {}
}*/

//57862 functions for CertonaService
function leftPad(input, length) {
    try {
        var str = input + "";
        while (str.length < length) {
            str = "0" + str;
        }
        return str;
    } catch (ignore) {
    }
}

function getPageId() {
    try {
        var pageId, localDate, year, month, day, hour;
        pageId = Math.floor(Math.random() * 1000000000000000) + "";
        localDate = new Date();
        year = localDate.getFullYear() + "";
        month = (localDate.getMonth() + 1) + "";
        day = localDate.getDate() + "";
        hour = localDate.getHours() + "";
        pageId = "res" + year.substring(2) + leftPad(month, 2) + leftPad(day, 2) + leftPad(hour, 2) + pageId;
        return pageId;
    } catch (ignore) {
    }
    return "";
}

//if($('suggItems')){attachstuff(1,'div.detailsugg > a','mouseover',quickview,'A');}
//if($('sugg_flat')&&$('cart_rr')==undefined&&$('cart2_rr')==undefined){attachstuff(1,'div.sugg_thumb > a','mouseover',quickview,'A');}//disabled on cart page per SR 55876 - &&$('cart_rr')==undefined
if (page == "detail") {
    attachDetailEvents(0);
    var formImg = k.forms[1].name.replace("form", ""); //should be inv5
    //var outlier = (k.forms[1].elements['outlier']) ? k.forms[1].elements['outlier'].value : '';
    var uniq = (k.forms[1].uniqid) ? k.forms[1].uniqid.value : null;
    photoImg = getGenericImage(formImg); //convert first digit if necessary
    if (uniq && k.forms[1].elements['specOne-' + uniq].value !== "" && $('#color_' + k.forms[1].elements['specOne-' + uniq].value)[0]) { //use un-converted inv5 to extract color digits if necessary
        photoImg = photoImg + k.forms[1].elements['specOne-' + uniq].value; //add color digits to converted inv5
    }
    var zoomWidth = $("#detail .dtlHeader").width() + 20, /* width should be the adjacent column plus some of the margin */
        zoomHeight = $("#detail #zoom").height(); /* height should be the image */
    embedZoomSS(photoImg, zoomWidth, zoomHeight);
    attachAtlViewEvents();
    var videos = $('.alt_vid');
    if (videos.length && !isiPad) {
        var id_info = videos[0].id.split("_"), item = id_info[1], vidWidth = 63, vidHeight = 96,
            urlthumb = videosite + item + '.mp4';
        //embedFlashVideo(urlthumb,vidWidth ,vidHeight,'video_'+item,'thumb_video');
        //embedHTML5Video(urlthumb, vidWidth, vidHeight, 'video_'+item, 'thumb_video',true,photoImg);

    }
    attach('.dtlEmbedVideoLink', 'click', switchEmbedVideo);
    checkForReviewsLoaded();

}

if (window.page === "thumbs") {
    setCookie("last_visited_category", location.pathname, 30, "/");
}

function mobileDetailPhotoSlider() {
    if (isSwipeEventsAttached) return;

    var photo = $('#largeDetailPhotoWrapper');
    if (photo.length) isSwipeEventsAttached = true;

    //the below is the preferred solution for swipe events, but it is commented out for the time being and swipe events are handled by handleTouchStart and handleTouchMove
    /*photo.on("swipeleft", updateAltImgOnSwipe('left')
    ).on("swiperight", updateAltImgOnSwipe('right')
    ).on('movestart', function(e) {
        // If the movestart heads off in a upwards or downwards
        // direction, prevent it so that the browser scrolls normally.
        if ((e.distX > e.distY && e.distX < -e.distY) ||
            (e.distX < e.distY && e.distX > -e.distY)) {
            e.preventDefault();
        }
        // // To allow the slide to keep step with the finger,
        // // temporarily disable transitions.
        // wrap.addClass('notransition');
    });*/
    photo.on('movestart', function (e) {
        // If the movestart heads off in a upwards or downwards
        // direction, prevent it so that the browser scrolls normally.
        if ((e.distX > e.distY && e.distX < -e.distY) ||
            (e.distX < e.distY && e.distX > -e.distY)) {
            e.preventDefault();
        }
        // // To allow the slide to keep step with the finger,
        // // temporarily disable transitions.
        // wrap.addClass('notransition');
    });

    var touchstartX = 0;
    var touchendX = 0;
    photo.on('touchstart', function (e) {
        touchstartX = e.changedTouches[0].screenX;
    });
    photo.on('touchend', function (e) {
        touchendX = e.changedTouches[0].screenX;
        var difference = touchendX - touchstartX;
        var offset = 50;
        //swipes must travel 200 pixels to register
        if (difference > offset) {
            // left swipe
            updateAltImgOnSwipe(e, 'right');
        }
        if (difference < -offset) {
            // right swipe
            updateAltImgOnSwipe(e, 'left');
        }
    });
}

function updateAltImgOnSwipe(e, direction) {
    //when direction = left
    var nextFunc = 'next', wrapFunc = 'first';
    if (direction === 'right') {
        nextFunc = 'prev';
        wrapFunc = 'last';
    }
    var activealt = $('.active-alt');
    if (activealt[nextFunc]()[0]) {
        activealt[nextFunc]().find("a").trigger('click');
    } else {
        var nextAlt = $('#detailAltImgs').children()[wrapFunc]();
        nextAlt.find("a").trigger('click');
    }
}

function checkForReviewsLoaded() {
    setTimeout(function () {
        var test = $('.pr-snapshot-no-ratings').length, test2 = $('#answersinfo')[0],
            test3 = $('#answersinfo')[0] && $('#answersinfo')[0].style.display == "block";
        if ((!test && !test2) || (!test && !test3)) {
            $('#reviewsImgReThumb').show();
            $('#reviewItemDesc').show();
        }
    }, 1500);
}

//if($('.canada')[0]){canadaPop();}
attach('.sizechart', 'click', popUpSize);
if ($('#wishListCart')[0]) {
    attachWishListEvents();
}
if ($('#swimBuilderItemOptions')[0]) {
    attachCollectionItemEvents();
} else if ($('#detailOutfits')[0] || $('#olist')[0]) {
    attachDetailBeddingEvents();
}

function removeErrorInput(E) {
    $(this).removeClass("errorInput");
}

attach('.errorInput', 'focus', removeErrorInput);

function inputShowLabel(E) {
    $(E.target).prop("placeholder", "");//css("paddingTop","10px");
    $(E.target).next('label').css("visibility", "visible");
}

attach('.withLabel', 'focus', inputShowLabel);
//if($('classificationQuestions')){bvLoadQA();}

var searchBox = $('#searchBox');
if ($(searchBox)[0]) {
    if (!isMobileDevice || ($('#tbSearch').is(":visible"))) {
        //Note: check visibility for the tablet icon instead of the width-based variable in case of browser differences btwn js width and css width, if we check visibility of css-triggered element we ensure consistency around boundary with desktop width
        attach('.menuSearch,#tbSearch', 'mouseover', function (E) {
            $(".ddSearch").show();
            $("#searchBox").focus();
        });
        attach('.menuSearch,#tbSearch', 'click', function (E) {
            $("#searchBox").focus();
        });

        attachDelegate('#ddSearchClsBox', 'click', function (E) {
            $(".ddSearch").hide();
        });
    }


    /*$(searchBox).autocomplete({
        minLength: 2,
        source: function(request,response){
            $.get("/ajax/autoComplete.php",{query:request.term},function(json){
                response(json.suggestions);
            })
        },
        select:function(event,ui){
            $(this).val(ui.item.label);
            this.form.submit();
        }
    });*/ //57862 replace jquery autocomplete with Certona Visual Search
    /* Azure 767 - Remove Certona \/
    var newVs = CertonaVisualSearch.newVisualSearch({
        inputId: "searchBox",
        appid: "softsurroundings01",
        catalogId: "softsurroundingsC04",
        anchorElement: "searchBoxCertona",
        arrangement: "vertical",
        scheme: "vsprodrec1_rr",
        attributeMappings: {
            suggestion: {
                name: "productname",
                detailUrl: "detailurl",
                itemId: "parentid"
            },
            recDetailLink: {
                detailUrl: "detailUrl"
            },
            recImage: {
                name: "productname",
                imageUrl: "imageUrl"
            },
            recName: {
                name: "productname"
            },
            recPrice: {
                currentPrice:"Saleprice"
            }
        }
    });
Azure 767 /\ */

}
/* BEGIN SWIM BUILDER */
if ($('#swimBuilderPage')[0]) {
    selectSwimDefaults();
    renderSwimSelected();
    attach('.swThm', 'click', swimThumbClick);
    attach('#swimBuy', 'click', swimBuy);
}

function swimBuy() {
    window.location = page + '?getItemOptions=1&' + buildSwimItemUrlVars();
}

function selectSwimDefaults() {
    swimSelected[1] = swimItems[1][0];
    swimSelected[4] = swimItems[4][0];
}

function swimThumbClick(e) {
    var el = e.target;
    idParts = el.id.split("_");
    var clothingType = idParts[1];
    var newInv = idParts[2];
    switch (clothingType) {
        case '1'://bottom
            swimSelected[0] = 0;//remove 1 peice
            swimSelected[3] = 0;//remove bottom-skirt
            swimSelected[2] = 0;//remove skirt
            swimSelected[5] = 0;//remove sarong
            if (swimSelected[4] == 0) {//If we lost our top, put on the default
                swimSelected[4] = swimItems[4][0];
            }
            break;
        case '4'://top
            swimSelected[0] = 0;//remove 1 peice
            swimSelected[6] = 0;//remove coverup
            if (swimSelected[1] == 0 && swimSelected[3] == 0) {//If we lost our bottom, put on the default
                swimSelected[1] = swimItems[1][0];
            }
            break;
        case '0'://onePiece
            swimSelected[3] = 0;//remove bottom-skirts
            swimSelected[1] = 0;//remove bottom
            swimSelected[4] = 0;//remove top
            swimSelected[2] = 0;//remove skirt
            swimSelected[5] = 0;//remove sarong
            swimSelected[6] = 0;//remove coverup
            break;
        case '3'://bottom-skirts
            swimSelected[0] = 0;//remove 1 piece
            swimSelected[5] = 0;//remove sarong
            swimSelected[2] = 0;//remove skirt
            swimSelected[1] = 0;//remove bottom
            if (swimSelected[4] == 0) {//If we lost our top, put on the default
                swimSelected[4] = swimItems[4][0];
            }
            break;
        case '6'://coverup
            if (swimSelected[clothingType] == newInv) {//Allow to toggle on and off when clicked
                newInv = 0;
            }
            break;
        default ://sarong & skirt
            if (swimSelected[clothingType] == newInv) {//Allow to toggle on and off when clicked
                newInv = 0;
            } else {
                swimSelected[5] = 0;//remove sarong
                swimSelected[2] = 0;//remove skirt
            }
            if (swimSelected[3] != 0) {//bottom skirts cannot have an added sarong/skirt.
                swimSelected[3] = 0;//removes the bottom skirt
                swimSelected[1] = swimItems[1][0];//adds the default bottom
            }
            break;
    }
    swimSelected[clothingType] = newInv;
    renderSwimSelected();
}

function renderSwimSelected() {
    $('.swThm').removeClass('sel');
    for (var i = 0; i < swimSelected.length; i++) {
        if (swimSelected[i] != 0) {
            $('#swimDiv_' + i + '_' + swimSelected[i]).addClass('sel');
        }
    }
    $('#swimModel')[0].src = page + '?getImage=1&' + buildSwimItemUrlVars();
    clearSelection();/*fixes the images looking bad in safari if you try to drag one*/
}

function buildSwimItemUrlVars() {
    var vars = '';
    for (var i = 0; i < swimSelected.length; i++) {
        vars += 'item' + i + '=' + swimSelected[i] + '&';
    }
    vars = vars.slice(0, -1);
    return vars;
}

/* END SWIM BUILDER */
function clearSelection() {
    if (document.selection) document.selection.empty();
    else if (window.getSelection) window.getSelection().removeAllRanges();
}

/*********************************
 * Function Name:giftCertSubmit
 * purpose: to process the giftCertificate form that is located in the faq popup
 * @param1: gcform - the gift certificate form to be processed
 * returns: null
 *********************************/
function gcInq(resp, gcform) {
    var URL = '/ajax/gcinqq.php', submitted = "true", pars;
    if (gcform.name == "mcInquiry") {
        pars = "mcAction=1&merchCreditNo=" + gcform.merchCreditNo.value + "&submitted=" + submitted;
    } else {
        pars = "gca=1&gcno=" + gcform.giftCertNo.value + "&submitted=" + submitted;
    }
    pars += '&capt=' + resp;
    dataLayerPush('gcInq');
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            $('#winFAQ').html(t);
        }
    });
}

function renInqCaptcha(resp) {
    gcInq(resp, $('#gcInquiry')[0]);
}

/*function toggleShippingCountry(){
    $('#countryPicker').toggle();
}*/

/*function chosenShippingCountry(element) {
    element.checked = true;
    //var countrySelector = document.getElementById('countrySelector');
    //countrySelector.action = location.href;
    //countrySelector.submit();
    var url = location.href.split("?");
    url = url[0]+"?"+element.name+"="+element.value;
    window.location.href=url;
}*/

function callMePop() {
    var widthX = 600, heightY = 700, win, pars = '', URL = "/ajax/callMe.php";
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: widthX, height: heightY, title: 'Have Questions? Need Help?', content: t});
        }
    });
}

function itemInquiryPop(e) {
    e.preventDefault();
    var element = e.target, itemForm = $(element).closest('form')[0], uniqid = itemForm.uniqid.value,
        baseId = itemForm.parentId.value;
    var specOne = $('#specOne-' + uniqid).val(), specOneDesc = $('#specOne-' + uniqid).data("desc"),
        pars = 'inquiryBaseId=' + baseId + '&inquirySpecOneNo=' + specOne + '&inquirySpecOneDesc=' + specOneDesc;
    var widthX = 600, heightY = 700, win, URL = "/iteminquiry/inquiryform/";
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            jDialog({width: widthX, height: heightY, title: 'In-Store Item Lookup', content: t});
            attach('#inquiry_submit', 'click', processItemInquiry);
            attach('.withLabel', 'focus', inputShowLabel);
        }
    });
}

function processItemInquiry(e) {
    e.preventDefault();
    var element = e.target, itemForm = $(element).closest('form')[0], pars = loadUpPars(itemForm);
    var widthX = 600, heightY = 700, win, URL = "/iteminquiry/processinquiry/";
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            $('#inquiryItemContainer').html(t);
            attach('#inquiry_submit', 'click', processItemInquiry);
        }
    });
}

function postCallMe(form) {
    submitted = true;
    var URL = '/ajax/callMe.php', pars = loadUpPars(form);
    //ajax = new Ajax.Request(URL, {method: 'POST', parameters: pars,onComplete: function(t){
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            submitted = false;
            $(form).closest(".ui-dialog-content").html(t);
        }
    });
    return false;
}

function dropBox(e, closeOthers) {
    e.preventDefault();
    var link = e.currentTarget;
    var children = $(link).children(), sibling = $(link).next(), right = $(link).find(".right");
    if ($(children).hasClass('selected')) {
        setCookie("promoCodeExpanded", "false", 365, "/");
        $(children).removeClass("selected");
        $(right).text("+");
        $(sibling).slideUp(300);
    } else {
        setCookie("promoCodeExpanded", "true", 365, "/");
        if (closeOthers) {
            $('.dropLink .selected').parent().each(function () { //close any other open boxes
                var closelinkInner = $(this).children(), closeDropBox = $(this).next();
                $(closelinkInner).removeClass('selected');
                $(right).text("+");
                $(closeDropBox).slideUp(300);
            });
        }

        $(children).addClass("selected");
        $(right).text("-");
        $(sibling).slideDown(300);
    }
    //Effect.toggle($(sibling),'slide', { duration: 0.3 });
    //$(sibling).toggle('slide',{direction:'up'},300);
}

function handleMobileFooter(e) {
    if (this.nextSibling.style.display == 'none' || this.nextSibling.style.display == '') {
        if (event.target.className == 'footerPlusIcon') { //user clicked on icon, logic is a little different
            $(event.target.parentNode).siblings().css('display', 'block');
        } else {
            $(event.target).siblings().css('display', 'block');  //show footer elements
        }
        $('.socialFtrHeader').css('display', 'none');
        this.children[0].innerHTML = '&#8722;';
    } else {
        if (event.target.className == 'footerPlusIcon') { //user clicked on icon, logic is a little different
            $(event.target.parentNode).siblings().css('display', 'none');
        } else {
            $(event.target).siblings().css('display', 'none');  //hide footer elements
        }
        $('#socialFtr').css('display', 'block'); //don't want to hide the social media icons
        this.children[0].innerHTML = '&#43;';
    }
}

/*
Using this on the store info page where we want the store content to show some of it but hide the rest, and expand when clicked on.
This is so we don't hide this content on page load and it can be crawled better.
Using CSS transitions to change the max-height of the div in order to get the same visual results as dropBox()
 */
function dropBoxExpand(e) {
    e.preventDefault();
    var link = e.currentTarget;
    var children = $(link).children(), sibling = $(link).next();
    if ($(children).hasClass('selected')) {
        $(children).removeClass("selected");
        $(sibling).removeClass("selected");
    } else {
        $(children).addClass("selected");
        $(sibling).addClass("selected");
    }
}

/*function signInPop(loc,needWindow){
    var URL = '/myaccount/',pars;
    if(loc==1){
        pars="signIn=1";
    }else{pars="signIn=0"}
    pars+="&ajax=1";
    loadDynamicJS('https://apis.google.com/js/api:client.js');
    $.ajax({type:'GET',url:URL,dataType:'text',data:pars,success:function(t){
        if(needWindow){
            jDialogClose();
            jDialog({width:532,height:491,id:'winSignIn',noTop:true,noBorder:true,content:t});
            //if($('[name="newUserName"]')[0]){ $('[name="newUserName"]').on('blur',fireKnPixel); }
        }else{
            var div = $('#signInPop')[0];
            if($('#signInPop')[0]) {
                div = $('#signInPop').parent();
            } else if ($('#createAcctPop')[0]) {
                div = $('#createAcctPop').parent();
            }
            div.html(t);
        }
    }});
}*/
function loadDynamicJS(url) {
    var js = k.createElement('script');
    js.src = url;
    k.body.appendChild(js);
}

/*********************************
 * Function Name:createNewLogIn
 * purpose: to process new login from guest checkout on reciept page or account page
 * @param1: form - new login form on page
 * returns: null
 *********************************/
function createNewLogIn(form) {
    var URL = '/ajax/newLogin.php',
        pars = "newUserName=" + form.newUserName.value + "&newUserName2=" + form.newUserName2.value + "&newPassword1=" + form.newPassword1.value + "&newPassword2=" + form.newPassword2.value;
    $.ajax({
        type: 'POST', url: URL, dataType: 'text', data: pars, success: function (t) {
            $('#newAccount').html(t);
        }
    });
}

//IDEA CENTER CODE
/* Azure 767 - Remove Certona \/
function addToIdeaCenter(baseId){
    //This function handles the "add to my ideas" button on the product pages
    //Will show sign in popup if they are not signed in, otherwise will display list ideas or add to new idea. addProduct
    var win="", URL='/ideacenter/', pars='addProduct='+baseId;
    //ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({type:'POST', url:URL, dataType:'text',data:pars,success:function(t) {
        jDialog({width:485,height:285,title:'Add to My Idea Center',id:'winSignIn',noTop:false,noBorder:false,content:t});
    }});
}
function handleAddToIdeaCenter(E){
    var idea = E.idea.value;
    var baseId= E.ideainv5.value;
    var createIdea = E.createIdea.value;
    var win="", URL='/ideacenter/', pars='addProduct='+baseId+'&addToIdea='+idea+'&createIdea='+createIdea;
    // ajax = new Ajax.Request(URL,{method: 'POST', parameters: pars,onComplete: function(t) {
    $.ajax({type:'POST', url:URL, dataType:'json',data:pars,success:function(t) {
        var success = t.success;
        if(success){
            $('#ideaBox').html(t.msg);
        }else{
            $('#errorBox').html(t.msg);
        }
    }});
}
*/

//LISTRAK FUNCTIONS
function addListrakCartItem(listrak) {
    if (typeof (_ltk) !== 'undefined') {
        //add/removes/edits item in the listrak cart every time something happens to it
        var sku, lineItemId, baseId, qty, price, productName, imageurl, productUrl, itemCount;
        itemCount = listrak.items.length;
        if (itemCount > 0) {
            for (var i = 0; i < itemCount; i++) {
                sku = listrak.items[i].sku;
                qty = listrak.items[i].qty;
                price = listrak.items[i].price;
                productName = listrak.items[i].productName;
                imageurl = decodeURIComponent(listrak.items[i].imageUrl);
                productUrl = decodeURIComponent(listrak.items[i].productUrl);
                _ltk.SCA.AddItemWithLinks(sku, qty, price, productName, imageurl, productUrl);
            }
            _ltk.SCA.Meta1 = listrak.cartId;
            _ltk.SCA.Meta2 = listrak.siteCode;
            _ltk.SCA.Submit();
        } else {
            _ltk.SCA.Meta1 = listrak.cartId;
            _ltk.SCA.Meta2 = listrak.siteCode;
            _ltk.SCA.ClearCart();
        }
    }
}

/** //replaced with GTM
 *  Generates a google analytics event
 *  @param eCategory - Category of the event
 *  @param eAction - Action of the event, eg 'play, next, etc.'
 *  @param eLabel - name of the event eg 'Beauty and Fragrance Slide Show'
 *  @param eValue - numeric value associated with the event
 */
/*function sendGoogleAnalyticsEvent(eCategory, eAction, eLabel, eValue)
{
    try {
        ga('send', 'event', eCategory, eAction, eLabel);
        //console.log("Sending Google Analytics Event Data: category=" + eCategory + " action=" + eAction + " label=" + eLabel + " value=" + eValue);
    } catch (err) {
        //console.log(err.message);//uncomment when testing
    }
}*/

//menu hover for desktop
function initMenuHover() {
    if (!isMobileOrTabletWidth) {
        $('.menuWrap li.dropdown').hover(function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(200);
        }, function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(200);
        });
    }
}

function initFixedNav() {
    //window.onload = function() {//waiting till page is loaded before determining some of these vars
    //total header height - the actual nav menu height
    //shrinkHeader = marginTop - header.find('.classMenu').height();
    //var scroll = getCurrentScroll();
    return;
    var promoBar = $('#hdrPromoBarRow');
    var promoBarHeight = promoBar.height();
    var searchBox = $('.menuSearch #searchBox');
    var menuSearch = $('.menuSearch'); // Menu Search Box to adjust so it is fixed to top on mobile

    var lastScrollTop = 0;
    var topMenuHeader = !isMobileDevice ? $('.menuWrap') : $('#headtop');
    var marginTop = topMenuHeader.height();

    var delta = 5;
    var topofnav = topMenuHeader.offset().top; //gets offset of header

    if (isMobileDevice && promoBarHeight > 0) {
        var $pageDiv = $('#page');
        var margin = marginTop - parseInt($pageDiv.css('padding-top'));
        $pageDiv.css('margin-top', margin);//make sure the header has enough spacing if promo bar is active
    }
    /*if ( scroll >= shrinkHeader) {
        addFixedClass();
    } else {
        removeFixedClass();
    }*/
    /*$(window).scroll(function() {
        var scroll;
        if (!isMobileDevice) {//don't worry about on mobile
            scroll = getCurrentScroll();
            if ( scroll >= shrinkHeader) {
                addFixedClass();
            } else {
                removeFixedClass();
            }
        } else {
            if (promoBar.is(':visible') || promoBar.hasClass('close')) {
                scroll = getCurrentScroll();
                if (scroll >= promoBarHeight) {//css3 animations to slide up/down
                    promoBar.addClass('close');
                    searchBox.removeClass('mblSearch');//hide search box
                } else {
                    promoBar.removeClass('close');
                    searchBox.removeClass('mblSearch');//hide search box
                }
            }
        }
    });*/
    var didScroll;
    var counter = 0;
    $(window).scroll(function () {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolledMenu(topMenuHeader, lastScrollTop);
            didScroll = false;
            counter++;
            //console.log('top menu counter = '+counter);
        }
    }, 100);

    function hasScrolledMenu() {
        var navbarHeight = topMenuHeader.outerHeight();
        var st = $(this).scrollTop();
        if (Math.abs(lastScrollTop - st) <= delta) {
            return;
        }
        // I am not sure how the below code is supposed to work so I have commented it out and made my own. RJB 11/30/2022
        // If current position > last position AND scrolled past navbar...
        // if (st > lastScrollTop && st > navbarHeight+500){
        //     // Scroll Down
        //     // navbar.removeClass(‘nav-down’).addClass(‘nav-up’);
        //     //removeHeaderFixedClass(true);
        //     setTimeout(function(){
        //         topMenuHeader.removeClass('nav-down').addClass('nav-up');
        //     },100)
        //
        //
        // } else {
        //     // Scroll Up
        //     // If did not scroll past the document (possible on mac)...
        //     if(((st + $(window).height()) < $(document).height()) && ((st-500) > (topofnav + navbarHeight))) {
        //         setTimeout(function(){
        //             if(!isMobileDevice) {
        //                 if ($('#thmContent #nav').css("position") === "fixed") {
        //                     $('#thmContent #nav').css({
        //                         top: (navbarHeight + 8)+"px"
        //                     });
        //                 }
        //             }
        //             addHeaderFixedClass();
        //         },100)
        //
        //
        //         //navbar.removeClass(‘nav-up’).addClass(‘nav-down’);
        //     }else {
        //         //fixed header up
        //         topMenuHeader.removeClass('fixed menu-top-fixed nav-down nav-up').addClass('prw');
        //     }
        // }
        // Add or remove the fixed header for mobile based on scroll position
        if (st > 50) {
            // Scroll Down
            topMenuHeader.removeClass('prw').addClass('menu-top-fixed');
            menuSearch.removeClass('prw').addClass('menu-top-fixed');
        } else {
            // Scroll Up
            topMenuHeader.removeClass('menu-top-fixed').addClass('prw');
            menuSearch.removeClass('menu-top-fixed').addClass('prw');
        }
        lastScrollTop = st;

    }

    function addHeaderFixedClass() {
        if (!topMenuHeader.hasClass('nav-down') && !$('#chkBar')[0]) {//only add if doesn't have class or not in checkout
            marginTop = topMenuHeader.height();//recalc header height before adding fixed position
            topMenuHeader.addClass('fixed menu-top-fixed nav-up').addClass('nav-down').removeClass('nav-up prw');
            var page = $('#page');
            //page.css('padding-top', marginTop + 1 + 'px');
            /*$window.on('resize', function() {
                marginTop = header.height();
                page.css({'padding-top': (marginTop + 1 + 'px') + 'px'});
            });*/
        }
    }
}

/*function removeHeaderFixedClass(addNavUp) {
    if(true){
        header.removeClass('nav-down').addClass('nav-up');
    }else{
    }
    //$('#page').css('padding-top', '0');
}*/
function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}


/*thumbnail navigator locked image*/
function initFixedThumbNav() {
    var navigation = $('#thmContent #nav');
    if (!navigation[0]) {
        return; //do not proceed if the page does not have the expected content
    }
    window.onload = function () {//waiting till page is loaded before determining some of these vars
        var topAnchor = $('#thmContent');
        var botAnchor = ($('#pagination').length) ? $('#pagination') : $('#footer');
        //total header height - the actual nav menu height
        //var marginTop = header.height();
        //shrinkHeader = marginTop - header.find('.classMenu').height();
        //var scroll = getCurrentScroll();

        var lastScrollTop = 0;
        var hdrMenu = $('#menuWrap');
        var delta = 5;
        var winHeight = $(window).innerHeight() - 50;

        /*if (isMobileDevice && promoBarHeight > 0) {
            var $pageDiv = $('#page');
            var margin = marginTop - parseInt($pageDiv.css('padding-top'));
            $pageDiv.css('margin-top',margin);//make sure the header has enough spacing if promo bar is active
        }*/

        var didScroll = false;
        var counter = 0;
        if (navigation.outerHeight() > topAnchor.outerHeight()) {
            topAnchor.css({height: navigation.outerHeight()});
        }
        if (!isMobileDevice) {
            $(window).scroll(function () {
                didScroll = true;
            });
        }
        setInterval(function () {
            if (didScroll) {
                didScroll = false;
                hasScrolledNav(hdrMenu, lastScrollTop);
                counter++;
                //console.log("left nav scroll counter:" + counter);
            }
        }, 100);

        function hasScrolledNav() {
            var scrollTop = $(this).scrollTop();
            /*offset top, how much has been scrolled since*/
            var addOffsetValue = 75;//additional pixesl to add to offset

            var elemTop = topAnchor.offset().top;//anchor for when we are at the top of the page
            var elemBottom = botAnchor.offset().top;//ancor for when we are at the bottom
            var distance = elemBottom - scrollTop - addOffsetValue;//distance between bottom anchor - how far we have scrolled - 100 margin buffer
            var navHeight = navigation.outerHeight();
            if (Math.abs(lastScrollTop - scrollTop) <= delta) {
                return;
            }

            //check if the space between the top of the nav and the top of hte pagination is short enough that we need to use that value
            if (elemBottom - navigation.offset().top < distance) {
                distance = elemBottom - navigation.offset().top - addOffsetValue;
            }

            // If current position > last position AND scrolled past navbar...
            var navWidth = (topAnchor.outerWidth() * .18) + "px";//thmConent * 20% = nav width;

            if (scrollTop > elemTop) {
                var top = "10px";
                if (hdrMenu.hasClass('nav-down')) {
                    top = (hdrMenu.outerHeight() + 8) + "px";
                }
                navigation.css({
                    position: "fixed",
                    top: top,
                    "width": navWidth,
                    "max-height": winHeight + "px",
                    "overflow-y": "auto"
                })
            } else if (scrollTop < elemTop) {
                navigation.css({
                    position: "absolute",
                    top: "inherit" /* changed from initial to fix on IE */
                })
            }
            if ((scrollTop + elemTop + navHeight) >= elemBottom) {

                navigation.css({
                    "max-height": distance + "px",
                    "overflow-y": "scroll",
                    "width": navWidth
                });
                navigation.addClass('atbottom');
            } else {
                if (navigation.hasClass('atbottom')) {
                    navigation.css({
                        "max-height": "initial",
                        "overflow-y": "auto",
                        "width": navWidth
                    });
                    navigation.removeClass('atbottom');
                }

            }

            lastScrollTop = scrollTop;
        }

        attach('.dropLink:not(.payment)', 'click', function (e) {
            dropBox(e, true);
            /*if(!navigation.hasClass('atbottom')) {
                setTimeout(function () {
                    var navHeight = navigation.outerHeight();
                    var navWidth = (topAnchor.outerWidth() * .18)+"px";//thmConent * 20% = nav width;
                    if (navHeight > winHeight) {
                        navigation.css({
                            "max-height": winHeight + "px",
                            "overflow-y": "scroll",
                            "width": navWidth
                        })
                    } else {
                        navigation.css({
                            "max-height": "initial",
                            "overflow-y": "auto",
                            "width": navWidth
                        })
                    }
                }, 300);
            }*/
        });
    }
}

//menu bar for mobile
if (isMobileOrTabletWidth) {
    var headMenu = $('#menubar');
    var searchBar = $('li.mblMenuHead > .ddSearch');
    var searchIcon = $('#headMenuSearchIcon');

    function closeAllExpandedMenuSubcategories() {
        headMenu.find('li.clMn.selected').removeClass("selected");
    }

    $('#headMenuImg,#tbMenuLink').on('click', function (e) {
        if (headMenu.is(":hidden")) {
            headMenu.addClass("selected");
            /*$('body').animate({marginLeft:"+=278"},150,function(){
                headMenu.toggle();
            });*/
            headMenu.css({'display': 'block', 'left': '-50%'});
            headMenu.animate({left: '0%'}, 200, function () {
                headMenu.css('left', '0');
                searchIcon.css('display', 'none;');
                searchBar.css('display', 'none');
            });
            //$('body').css({position:"absolute",overflow:"hidden"});
            //$(document).css({position:"absolute",overflow:"hidden"});
        } else {
            headMenu.removeClass("selected");
            headMenu.css('display', 'none');
            /*            headMenu.toggle();
            $('body').animate({marginLeft:"-=278"},150);*/
            //$('body').css({position:"relative",overflow:"visible"});

        }
    });

    searchIcon.on('click', function (e) {
        var searchBar = $('div.menuSearch');
        if (searchBar.is(':hidden')) {
            searchBar.css('display', 'block');
            $('#mobileSearchClsBox').on('click', function (e) {
                $('div.menuSearch').css('display', 'none');
            });
        } else {
            searchBar.css('display', 'none');
        }
    });

    $('#mblMenuClsBox').on('click', function (e) {
        headMenu.removeClass("selected");
        headMenu.toggle();
        closeAllExpandedMenuSubcategories();
        //$('body').animate({marginLeft:"-=278"},150);
        //$('body').css({position:"relative",overflow:"visible"});
    });
    //var backBtn = $('.mbl-back-btn');
    headMenu.find('li.clMn a').on('click', function (e) {
        var $this = $(this).parent('.clMn');
        if ($this.find('ul.categories').length) {//if category submenus exist otherwise just go to link
            e.preventDefault();
            if (!$this.hasClass('selected')) {
                closeAllExpandedMenuSubcategories();
            }
            $this.toggleClass('selected');//add selected to li.classMenu
            headMenu.toggleClass('isopen');//add class to main #menubar
            $this.parent('ul').toggleClass('selected');//add selected class to any parent ul element, .subcategories or .categories
            //backBtn.show();
        } else if ($this.find('ul.subcategories').length) {
            e.preventDefault();
            $this.toggleClass('selected');
        }

        /*var subcatMenu = $this.find("ul.categories");
        if(subcatMenu.is(":hidden")){
            //$this.addClass("selected");
            $this.addClass("selected");
            //$this.prev().addClass("selected");
            subcatMenu.addClass("selected");
            subcatMenu.show();
            $this.parent().find('>li.classMenu').not($this).hide();
            backBtn.show();
        }else{
            //$this.removeClass("selected");
            $this.removeClass("selected");
            //$this.prev().removeClass("selected");
            subcatMenu.removeClass("selected");
            subcatMenu.hide();
        }*/
        //subcatMenu.slideToggle('fast');
    });
    /* backBtn.on('click', function (e) {//go up one level in the nav
         var navElement = headMenu.find('.categories.selected');
         if (!navElement[0]) {
             //not in subcat level, try cat level
             navElement = headMenu.find('.clMn.dropdown.selected');
         }
         if (navElement[0]) {
             //category level
             navElement.removeClass('selected');
             navElement.find('li.selected').removeClass('selected');
             if (navElement.parent('ul').hasClass('isopen')) {//top level need to remove isopen from menu
                 headMenu.removeClass('isopen');
                 backBtn.hide().removeClass('show');
             }
         }
     });*/

}
if ($('.soGSAP-slideshow').length > 0) {
    try {
        var ctrl = new ScrollMagic.Controller({loglevel: 3});
        initSlideshowEvents();
    } catch (err) {
        console.log(err);
    }
}

function initSlideshowEvents() {
    var slides = $('.soGSAP-slideshow > .slide'),
        activeSlide = $('.soGSAP-slideshow > .slide.active'),
        slideshowWrapper = $('.soGSAP-slideshow');

    if (!window.TweenLite) {
        throw new Error("Tweenlite library is not loaded");
        return;//cancel execution
    }

    //check if the slideshow class is in the page
    if (slideshowWrapper.length > 0) {
        TweenLite.set(slides.not(activeSlide), {opacity: 0});
        slideshowWrapper.each(function () {
            var currentElement = $(this),
                fadeDuration = (currentElement.attr('data-fadeduration') ? currentElement.data('fadeduration') : 3),
                slideInterval = (currentElement.attr('data-slideinterval') ? currentElement.data('slideinterval') : 4000),
                currentInterval = 1;

            new ScrollMagic.Scene({triggerElement: this, triggerHook: 'onEnter'})
                .on('enter', function () {
                    if (currentInterval < 2) {
                        currentInterval++;
                        changeSlide(fadeDuration, currentElement);
                    }
                    currentInterval = window.setInterval(
                        function () {
                            changeSlide(fadeDuration, currentElement);
                        }, slideInterval)
                })
                .on('leave', function (e) {
                    window.clearInterval(currentInterval);
//for clearing all intervals
                })
                .addTo(ctrl);

        });
    }
}

//change to the next slide
function changeSlide(fadeDuration, currentSlideshow) {
    var currentSlide = currentSlideshow.find('.slide.active'), nextSlide = currentSlide.next('.slide'),
        timeline = new TimelineLite({repeat: 0});
    if (!nextSlide[0]) {
        nextSlide = currentSlide.parent().find('.slide').first();
    }

    timeline
        // remove class active from the currently active slide (slideOut)
        .set(currentSlide, {className: '-=active'}, 0)

        // animate new slide up (from out of the viewport)
        .set(nextSlide, {className: '+=active'}, 0)
        .to(nextSlide, fadeDuration, {autoAlpha: 1}, 0)
        // animate active slide up (out of the viewport)
        .to(currentSlide, fadeDuration, {autoAlpha: 0}, 0)

    timeline.play();
}

//function to fix the image map issue with IE where CP doesn't add a default area, and instead wraps the image itself in an
//anchor tag, for some reason IE link doesn't work so this adds an area tag the same size as the  image
function fixImgMapForIE() {
    //thumb headers
    $("a img[usemap]").each(function () {
        var element = $(this);
        var link = element.parent('a').attr("href");
        var mapId = element.attr('usemap');
        mapId = mapId.replace("#", "");//remove the #
        var width = element.outerWidth();
        var height = element.outerHeight();

        var defaultArea = "<area title='default' shape='rect' coords='0,0," + width + "," + height + "' href='" + link + "' alt=''>";
        $("map[name=" + mapId + "]").append(defaultArea);
    });
}

/**
 * 58188 - switch out base for inv7 on swatch hover -
 * looking for img with data-base and data-spec attr - runs in thumb view
 */
function switchThumbImg(specImg, E, switchBack) {
    var $thumbImg = specImg.parent().parent().parent().find('.thmImg');
    if (!switchBack) {//whether we are on mouseout or mouseover
        var newImgSpec = specImg.data('base');
    } else {
        var newImgSpec = specImg.data('base') + '' + specImg.data('spec');
    }

    if ($thumbImg[0]) {
        $thumbImg = $thumbImg[0];
        var srcTag = $thumbImg.src.split("?")[1]; //extract $thumb14$ or equivalent after the ?
        $thumbImg.src = getImageUrl(newImgSpec, '270x405'); //create the new img src string
    }

}

/* update detail image after hovering over a color, or clicking to change spec*/
function detailSpecChangeImage(E) {
    var element = E.target, frm = $(element).closest('form');
    var baseId = $(frm).data("parentid");
    baseId = getGenericImage(baseId.toString());
    var spec = $(element).data('value') || $(frm)[0].elements['specOne'].value; //if value in element (hovering to preview) use it, else use the selected spec

    switchDetailPhoto(baseId, spec, '');
}

/* revert detail image to either default or selected color after mouseout from the swatch container div */
function detailSpecChangeImageBack(E) {
    var element = E.target, frm = $(element).closest('form'), spec = "";
    var baseId = $(frm).data("parentid");
    baseId = getGenericImage(baseId.toString());
    var uniqueId = frm.find('input[name=uniqid]').val();
    var selectedSpec = frm.find('input[name=specOne-' + uniqueId + ']').val();
    if (selectedSpec !== '') {
        spec = selectedSpec;//use the current selected spec
    }
    switchDetailPhoto(baseId, spec, '');
}

function addClassToNortonKicker() {//add the class norton-wrap to the kicker parent div, since there isn't anyway to select it
    var kicker = $('.socialButtonWrap span.norton-kicker');
    if (kicker[0]) {
        kicker.parent('div').addClass('norton-wrap');
    }
}

function getStateName(abbreviation) {
    switch (abbreviation) {
        case 'AL':
            return "Alabama";
        case 'AK':
            return "Alaska";
        case 'AZ':
            return "Arizona";
        case 'AR':
            return "Arkansas";
        case 'CA':
            return "California";
        case 'CO':
            return "Colorado";
        case 'CT':
            return "Connecticut";
        case 'DE':
            return "Delaware";
        case 'DC':
            return "Arlington, VA";
        case 'FL':
            return "Florida";
        case 'GA':
            return "Georgia";
        case 'HI':
            return "Hawaii";
        case 'ID':
            return "Idaho";
        case 'IL':
            return "Illinois";
        case 'IN':
            return "Indiana";
        case 'IA':
            return "Iowa";
        case 'KS':
            return "Kansas";
        case 'KY':
            return "Kentucky";
        case 'LA':
            return "Louisiana";
        case 'ME':
            return "Maine";
        case 'MD':
            return "Maryland";
        case 'MA':
            return "Massachusetts";
        case 'MI':
            return "Michigan";
        case 'MN':
            return "Minnesota";
        case 'MS':
            return "Mississippi";
        case 'MO':
            return "Missouri";
        case 'MT':
            return "Montana";
        case 'NE':
            return "Nebraska";
        case 'NV':
            return "Nevada";
        case 'NH':
            return "New Hampshire";
        case 'NJ':
            return "New Jersey";
        case 'NM':
            return "New Mexico";
        case 'NY':
            return "New York";
        case 'NC':
            return "North Carolina";
        case 'ND':
            return "North Dakota";
        case 'OH':
            return "Ohio";
        case 'OK':
            return "Oklahoma";
        case 'OR':
            return "Oregon";
        case 'PA':
            return "Pennsylvania";
        case 'PR':
            return "Puerto Rico";
        case 'RI':
            return "Rhode Island";
        case 'SC':
            return "South Carolina";
        case 'SD':
            return "South Dakota";
        case 'TN':
            return "Tennessee";
        case 'TX':
            return "Texas";
        case 'UT':
            return "Utah";
        case 'VT':
            return "Vermont";
        case 'VA':
            return "Virginia";
        case 'WA':
            return "Washington";
        case 'WV':
            return "West Virginia";
        case 'WI':
            return "Wisconsin";
        case 'WY':
            return "Wyoming";
        default:
            return abbreviation;
    }
}

fixImgMapForIE();
$(document).ready(function () {
    if (!readCookie('browser')) { //store cookie in browser since cloudfront hides it from us
        setSoftCookie('browser', encodeURIComponent(navigator.userAgent), 30);
    }

    //if (page.match('thumbs')) {
    //addClassToNortonKicker(); //no longer used
    //}

    if ($('img[usemap]')[0]) {
        $('img[usemap]').jMap();
    }//makes image maps responsive

    //begin store pages app
    var storeMapModule = (function () {
        //if (typeof google === 'object' && typeof google.maps === 'object') {
        var config = {
            centerLat: 39,
            centerLng: -98,
            zoom: 10,
            mapElement: 'storeGoogleMap',
            markerIcon: '/media/images/stores/marker_main.png',
            errorContainer: '#storeError',
            storeResultsContainer: '#storeResults',
            showLabelsOnMarkers: true
        };
        var isZoomed = false;//set to true if we have filtered results
        var center = ''/*new google.maps.LatLng(config['centerLat'], config['centerLng'])*/;
        var mapOptions = ''/*{zoom: config['zoom'], mapTypeId: google.maps.MapTypeId.ROADMAP, center: center}*/;
        var label = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';//for marker labels
        var labelIndex = 0;
        var map, markers = [];
        var geocoder = ''/*new google.maps.Geocoder()*/;

        var init = function (newConfig) {
            geocoder = new google.maps.Geocoder();
            setConfig(newConfig);
            map = new google.maps.Map(document.getElementById(config['mapElement']), mapOptions);
            infowindow = new google.maps.InfoWindow({pixelOffset: new google.maps.Size(-13, 0)});//adds a popup when each window is clicked
        };

        var setConfig = function (newConfig) {
            if (typeof newConfig === "object") {
                for (var key in newConfig) {
                    if (newConfig.hasOwnProperty(key)) {
                        config[key] = newConfig[key];
                    }
                }
            }
            //reevaluate center and mapOptions since they are tied to config
            center = new google.maps.LatLng(config['centerLat'], config['centerLng']);
            mapOptions = {zoom: config['zoom'], mapTypeId: google.maps.MapTypeId.ROADMAP, center: center};
        };

        var loadSingleStore = function (storeId) {
            var URL = "/stores/", pars = "googleMaps=1&storeId=" + storeId, store = '';
            $.ajax({
                type: 'POST', url: URL, dataType: 'json', data: pars, success: function (data) {
                    processErrors(data);
                    codeStoreAddress(data.store);
                }
            });
        }

        var loadAllStores = function () {
            var URL = "/stores/", pars = "googleMaps=1";
            $.ajax({
                type: 'POST', url: URL, dataType: 'json', data: pars, success: function (data) {
                    codeAllStoresAddress(data.stores);
                }
            });
        };

        var getNearestStores = function (searchTerm) {
            if (searchTerm.length == 2) {
                searchTerm = getStateName(searchTerm.toUpperCase());
            }
            var url = "/stores/", pars = 'searchTerm=' + searchTerm + '&calcStoreDistance=1';
            geocoder.geocode({'address': searchTerm}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    pars += '&lat=' + results[0].geometry.location.lat() + '&lng=' + results[0].geometry.location.lng();
                    if (results[0].address_components[0].long_name || 0 !== results[0].address_components[0].long_name.length) {//send formatted address
                        pars += '&fAddress=' + results[0].address_components[0].long_name;
                    }

                    $.ajax({
                        type: 'POST', url: url, dataType: 'json', data: pars, success: function (data) {
                            processErrors(data);
                            updateGoogleMap(data);
                            updateStoreResults(data.boxString);
                        }
                    });
                }
            });
        };

        var updateGoogleMap = function (data) {
            //don't redraw whole map, just re-center
            var myLatlng = new google.maps.LatLng(data.closestStore.lat, data.closestStore.lng);
            clearMarkers();
            codeAllStoresAddress(data.stores);//redo all of the map markers
            map.setCenter(myLatlng);
            map.setZoom(8);
        };

        var updateStoreResults = function (data) {
            $(config['storeResultsContainer']).html(data);
            //hide the click info once data is loaded
            $('.store-results-help').hide();

            $('.marker > a, #storeResults .storeName').on('click', function (e) {
                e.preventDefault();
                var markerIndex = $(this).data('marker');
                var marker = markers[markerIndex];
                var latLng = marker.getPosition(); // returns LatLng object
                //map.panTo(latLng); // setCenter takes a LatLng object
                google.maps.event.trigger(marker, 'click');
                map.panTo(latLng);
            });//add the marker click events
        };

        //removes all of the google markers
        var clearMarkers = function () {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
            markers = [];
        };

        var processErrors = function (data) {
            if (data.success == 'false') {
                addErrorMessage(data.error);
            } else {
                addErrorMessage('');
            }
        };

        var addErrorMessage = function (error) {
            $(config['errorContainer']).html(error);
        };

        var setMarker = function (address, myLatLng) {
            var image = {
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(13, 40),
                size: new google.maps.Size(50, 43),
                url: config['markerIcon'],
                labelOrigin: new google.maps.Point(13, 15)
            };
            markerConfig = {map: map, position: myLatLng, icon: image};
            if (config['showLabelsOnMarkers']) {//add letters to marker if set
                markerConfig['label'] = {
                    text: label[labelIndex++ % label.length],
                    color: 'white'
                };
            }
            var marker = new google.maps.Marker(markerConfig);
            google.maps.event.addListener(marker, 'click', function () {
                /*infowindow.setContent(contentString);
                     infowindow.open(map,marker);*/
                map.panTo(myLatLng);
                if (!config['showLabelsOnMarkers']) {//only update map if we are not already zoomed in
                    setConfig({showLabelsOnMarkers: true, markerIcon: '/media/images/stores/marker_shadow.png'});
                    getNearestStores(address);
                }
                map.setZoom(8);
            });
            markers.push(marker);//add to array so we can utilize later
        };

        var codeStoreAddress = function (store) {
            var myLatlng = new google.maps.LatLng(store['latitude'], store['longitude']);
            map.setCenter(myLatlng);
            setMarker(store['storeAddress'], myLatlng);
        };

        var codeAllStoresAddress = function (stores) {
            labelIndex = 0;//reset label index
            for (var i = 0; i < stores.length; i++) {
                var myLatlng = new google.maps.LatLng(stores[i]['latitude'], stores[i]['longitude']);
                setMarker(stores[i]['storeAddress'], myLatlng);
            }
        };

        //creates the coming soon slideshow
        var slideshowInterval;
        var comingSoonStoreSlideshow = function () {
            $('.left-nav').on('click', function () {
                setComingSoonAutoplay();
                var activeSlide = $('.comingSoonSlideshowContainer .comingSoonStore').first();
                var nextSlide = $('.comingSoonStore').last();
                activeSlide.fadeOut(500);
                nextSlide.css({
                    'left': '-100%',
                    'display': 'block'
                }).prependTo('.comingSoonSlideshowContainer').animate({left: '0'}, 1500);//move it to the left
            });

            $('.right-nav').on('click', function () {
                setComingSoonAutoplay();
                var activeSlide = $('.comingSoonSlideshowContainer .comingSoonStore').first();
                var nextSlide = activeSlide.next();
                activeSlide.fadeOut(500).appendTo('.comingSoonSlideshowContainer');
                nextSlide.css({'left': '100%', 'display': 'block'}).animate({left: '0'}, 1500);                      //move it to the left
            });
            setComingSoonAutoplay();
        };

        //autoplays the slideshow
        var setComingSoonAutoplay = function () {
            if (slideshowInterval !== "") {
                clearInterval(slideshowInterval);//remove the interval
            }
            slideshowInterval = setInterval(function () {
                $('.right-nav').trigger('click');
            }, 5000);
        };

        /*var setupPartStoresContHeight = function() { //deprecating
                var imgHeight = $('.eventImg img').outerHeight();
                var storesHeight = $('.partStores').outerHeight();
                var difference = imgHeight - storesHeight;
                var partStoreEvents = $('.partStoreEvents');
                var overflowContHeight = partStoreEvents.outerHeight() + difference;
                if (overflowContHeight > 100) {
                    partStoreEvents.height(overflowContHeight);
                }
            };*/

        return {
            init: init,
            setConfig: setConfig,
            loadAllStores: loadAllStores,
            getNearestStores: getNearestStores,
            addErrorMessage: addErrorMessage,
            loadSingleStore: loadSingleStore,
            comingSoonStoreSlideshow: comingSoonStoreSlideshow,
            //setupPartStoresContHeight: setupPartStoresContHeight,
        };
        //}
    })();

    if (page.match("stores")) {
        if ($('form[name=findastore]')[0]) {//search page
            $('form[name=findastore]').on('submit', function (e) {
                e.preventDefault();
                var searchTerm = $(this).find('input[name=location]').val();
                if (searchTerm !== '') {
                    storeMapModule.setConfig({
                        showLabelsOnMarkers: true,
                        markerIcon: '/media/images/stores/marker_shadow.png'
                    });
                    storeMapModule.getNearestStores(searchTerm);
                } else {
                    storeMapModule.addErrorMessage('Please enter in a city, state, or zip code to find a store near you.');
                }
            });
            storeMapModule.init({zoom: 4, showLabelsOnMarkers: false});
            if ($('input[name=location]').val() !== '') {
                $('form[name=findastore]').trigger('submit');//trigger submit because the value gets set if has cookie
            } else {
                storeMapModule.loadAllStores(); //moved this in OP 417 b/c shouldn't need to run it on the same page load as a search trigger
            }
            storeMapModule.comingSoonStoreSlideshow();

            $('.resetStorePage').on('click', function () {
                //reset the store pages to the default stores
                //$('#location').val('');
                document.cookie = 'lastStoreSearch' + "=" + ";path=/;domain=softsurroundings.com;expires=Thu, 01 Jan 1970 00:00:01 GMT";
                location.reload();//refresh the page
            });
        } else if ($('#storeContentMain').hasClass('singleStorePage')) {
            //single store page
            var storeId = $('input[name=storeId]').val();
            storeMapModule.init({
                zoom: 18,
                showLabelsOnMarkers: true,
                markerIcon: '/media/images/stores/marker_shadow.png'
            });
            storeMapModule.loadSingleStore(storeId); //for custom store content //58898
            attach('.dropLink:not(.payment)', 'click', dropBoxExpand);
        } else if ($('.eventImg')[0]) {//event page
            /*var isLoaded = false;
            if (!isMobileDevice) {
                $('.eventImg img').load(function() {
                    storeMapModule.setupPartStoresContHeight();//dynamically adjust the height of the participating stores container
                    isLoaded = true;
                });
                if (!isLoaded) {
                    storeMapModule.setupPartStoresContHeight();
                }
            }*/
        }
    }


});

function setFavoriteStore(retailFacilityId, page) {
    if (page == null) {   //gulp does not like default parameter values, set here
        page = "storeDetail";
    }
    var URL = '/stores/setfavoritestore/', pars = 'action=JSON&page=' + page + '&favoriteStore=' + retailFacilityId;
    $.ajax({
        type: 'POST', url: URL, dataType: 'json', data: pars, success: function (json) {
            var text = decodeURIComponent(json.text);
            var link = decodeURIComponent(json.link);
            var oldFavoriteStoreId = decodeURIComponent(json.oldFavoriteStoreId);
            switch (page) {
                case "storeDetail":
                    $('.favoriteStoreTextWrapper').html(text);
                    $('#favoriteStoreLink').html(link);
                    $('#favoriteStoreHeartIcon').removeClass('hideHeartIcon');
                    $('.favoriteStoreBtn').remove();
                    break;
                case "storeResults":
                    $('#yourFavoriteStore').remove();   //remove "Your Favorite Store:" text at old favorite store
                    $('#favoriteStoreHeartIconResultsPage').remove();   //remove heart icon on old favorite store
                    $('#favoriteStoreLink').html(link); //replace navbar link with "Your Store: <new fav store>"
                    $('#store' + retailFacilityId + ' li.storeName').before(text);  //add "Your favorite store:" li to new favorite store result
                    $('#store' + retailFacilityId + ' ul').before('<div id=\'favoriteStoreHeartIconResultsPage\'>&#10084;</div>');  //add heart icon
                    if (oldFavoriteStoreId !== "0") {    //oldFavoriteStoreId will equal 0 when there wasn't a favorite store set previously
                        var oldFavoriteStoreButton = decodeURIComponent(json.oldFavoriteStoreButton);
                        $('#store' + oldFavoriteStoreId + ' ul').append(oldFavoriteStoreButton); //add "set as favorite store" button to old favorite store result
                    }
                    $('#store' + retailFacilityId + ' .favoriteStoreBtn').parent('li').remove();   //remove "set favorite store" button on current favorite store
                    break;
            }
        }
    });
}

/* Facebook sdk for sharing (no longer used for social login) */
window.fbAsyncInit = function () {
    FB.init({
        appId: facebookKey,
        cookie: true,  // enable cookies to allow the server to access
        // the session
        xfbml: true,  // parse social plugins on this page
        version: 'v2.2' // use version 2.2
    });
};
(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = facebookSrc;
    //js.src = "//connect.facebook.net/en_US/sdk/debug.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

function fbShare() {
    FB.ui({
        method: 'share',
        href: window.location.href
    }, function (response) {
    })
}

/**
 * This popup lets user override email validation through briteVerify in case of false positive (58863)
 */
function displayEmailValidationOverridePopUp(element) {
    var emailAddress = $(element).val(); //support different forms
    var html = "<p>You entered: <strong>" + emailAddress + "</strong>.  Is this correct?</p><br/><p><a class='button' onclick='submitEmailValidationOveride(\"" + element + "\");'>Yes</a> &nbsp; &nbsp; <a class='button' onclick='jDialogClose()'>No</a></div>";
    jDialog({id: 'invalidEmailPop', width: 600, height: 'auto', title: "Invalid Email", content: html});
}

function submitEmailValidationOveride(element) {
    var emailForm = $(element).closest('form'); //support different forms
    $(emailForm).append('<input type="hidden" name="overrideEmailValidation" value="1" />');//add hidden input to notify server that store has turned off the validation
    $(emailForm).find("input[type=submit]").trigger('click'); //resubmit the form
}

//test comment
//generic function to prevent double clicks
function preventDoubleSubmit(form) {
    if (submitted !== true) {
        submitted = true;
        return true;
    }
    return false;
}

function updateOtherSwatchesView(updatedView) {
    var view = updatedView[0] || updatedView;
    var parent = view.closest(".thumb");
    var allSwatches = parent.querySelectorAll(".swt");
    var otherSwatches = [].slice.call(allSwatches).find(function (swt) {
        return swt !== view;
    });
    if (otherSwatches) otherSwatches.replaceWith(view.cloneNode(true));
}

function getCookie(name) {
    var allCookies = document.cookie;
    allCookies = allCookies.split(";");
    var cookie = allCookies.find(function (item){
        return item.split("=")[0].trim() === name;
    });
    if (cookie) return cookie.split("=")[1].trim();
    return null;
}

function lastSavedSpecTwoHandler() {
    var lastSavedSpecTwo = getCookie("lastSavedSpecTwo");
    if (!lastSavedSpecTwo) return;

    var size = document.getElementById("size_" + lastSavedSpecTwo);
    if (!size) return;

    if (!size.classList.contains("sel")) {
        size.click();
    }
}

function attachPageTypeToBody() {
    var pathname = location.pathname.toLowerCase();
    var type = "";
    if (pathname.includes("/cart/")) {
        type = "cart";
    } else if (pathname.includes("/p/")) {
        type = "product";
    }
    type && document.body.setAttribute("data-page-type", type);
}

(function () {
    var baseImageURL = "https://images.softsurroundings.com/products/550x826/";
    var activeClass = "active-swt";

    $("#thumbs").on("click", ".thumb .thmImgWrap .arrow", function () {
        var currentArrow = this;
        var left = currentArrow.classList.contains("arrow-left");
        var right = currentArrow.classList.contains("arrow-right");

        if (right || left) {
            var container = currentArrow.closest(".thmImgWrap");
            var otherArrow = [].slice.call(container.querySelectorAll(".arrow")).find(function (arrow) {
                return arrow !== currentArrow;
            });
            var rightArrow = right ? currentArrow : otherArrow;
            var leftArrow = left ? currentArrow : otherArrow;
            var baseProductURL = container.getAttribute("data-product-base-url");
            var baseImageSRC = container.getAttribute("data-img-base-src");
            var parent = container.closest(".thumb");
            var swatches = parent.querySelector(".swt");
            var swatchElements = [].slice.call(swatches.querySelectorAll("a[href].swt-link:not(.swt-link-more)"));
            var activeSwatchIndex = swatchElements.findIndex(function (el) {
                return el.classList.contains(activeClass);
            });
            var toBeActiveSwatch = right ? swatchElements[activeSwatchIndex + 1] : swatchElements[activeSwatchIndex - 1];
            if (activeSwatchIndex === -1) {
                left && (toBeActiveSwatch = swatchElements[swatchElements.length - 1]);
            } else {
                swatchElements[activeSwatchIndex].classList.remove(activeClass);
            }
            if (toBeActiveSwatch) {
                toBeActiveSwatch.classList.add(activeClass);
                var toBeActiveSwatchImage = toBeActiveSwatch.querySelector("img");
                var swatchBase = toBeActiveSwatchImage.getAttribute("data-base");
                var swatchSpec = toBeActiveSwatchImage.getAttribute("data-spec");
                var newImageLink = baseImageURL + swatchBase + swatchSpec + ".jpg";
                var newProductLink = baseProductURL + swatchSpec + "/";
                var image = container.querySelector(".thmImg");
                if (image) {
                    image.setAttribute("src", newImageLink);
                    parent.querySelectorAll("a[href]:not(.swt-link)").forEach(function (anchor) {
                        anchor.setAttribute("href", newProductLink);
                    });
                }
            } else {
                var image = container.querySelector(".thmImg");
                if (image) {
                    image.setAttribute("src", baseImageSRC);
                    parent.querySelectorAll("a[href]:not(.swt-link)").forEach(function (anchor) {
                        anchor.setAttribute("href", baseProductURL);
                    });

                }
            }

            var showRightArrow = (right && activeSwatchIndex + 1 < swatchElements.length) || (left && activeSwatchIndex < swatchElements.length);
            var showLeftArrow = (right && activeSwatchIndex >= -1) || (left && toBeActiveSwatch);
            rightArrow.classList.toggle("d-none", !showRightArrow);
            leftArrow.classList.toggle("d-none", !showLeftArrow);
            updateOtherSwatchesView(swatches)
        }
    });

    $("#thumbs").on("click", ".thumb .thmInfo .swt a.swt-link:not(.swt-link-more)", function (e) {
        var swatch = this;
        var toBeActiveSwatchImage = swatch.querySelector("img");
        if (!toBeActiveSwatchImage) return;

        e.preventDefault();
        var activeClass = "active-swt";
        if (swatch.classList.contains(activeClass)) return;

        var thumb = swatch.closest(".thumb");
        var parent = swatch.closest(".swt");
        var container = thumb.querySelector(".thmImgWrap");
        var baseProductURL = container.getAttribute("data-product-base-url");
        var swatchBase = toBeActiveSwatchImage.getAttribute("data-base");
        var swatchSpec = toBeActiveSwatchImage.getAttribute("data-spec");
        var newImageLink = baseImageURL + swatchBase + swatchSpec + ".jpg";
        var newProductLink = baseProductURL + swatchSpec + "/";
        var image = container.querySelector(".thmImg");
        if (image) {
            image.setAttribute("src", newImageLink);
            thumb.querySelectorAll("a[href]:not(.swt-link)").forEach(function (anchor) {
                anchor.setAttribute("href", newProductLink);
            });
        }
        var activeSwatch = parent.querySelector("a." + activeClass);
        activeSwatch && activeSwatch.classList.remove(activeClass);
        swatch.classList.add(activeClass);
        container.querySelectorAll(".arrow.d-none").forEach(function (arrow) {
            arrow.classList.remove("d-none");
        });
        updateOtherSwatchesView(parent);
    });

    $(".detailItemCopy").on("click", "[aria-hidden]:not(.homeBeauty)", function () {
        var isHidden = this.getAttribute("aria-hidden") === "true";
        if (!isHidden) return;
        document.querySelectorAll(".detailItemCopy [aria-hidden]")
            .forEach(function (el){
                el.setAttribute("aria-hidden", "true");
            });
        this.setAttribute("aria-hidden", "false");
    });
    lastSavedSpecTwoHandler();
    attachPageTypeToBody();
})();

if (typeof window.checkout_step === "string") {
    (function () {
        document.body.setAttribute("data-checkout-step", window.checkout_step);

        var cart_sidebar_toggle = document.getElementById("cart_sidebar_toggle");
        var cartPageWrapper = document.getElementById("cartpageWrapper");
        if (cart_sidebar_toggle && cartPageWrapper) {
            cart_sidebar_toggle.addEventListener("click", function () {
                var text = this.querySelector("span:first-of-type");
                if (!text) return;

                if (cartPageWrapper.classList.contains("show")) {
                    cartPageWrapper.classList.remove("show");
                    text.innerText = text.innerText.replace(/close/i, "View");
                } else {
                    cartPageWrapper.classList.add("show");
                    text.innerText = text.innerText.replace(/view/i, "Close");
                }
            });
            var observer = new MutationObserver(function () {
                var price = cart_sidebar_toggle.querySelector("span:last-of-type");
                var priceOfTruth = cartPageWrapper.querySelector("#totals .prices");
                if (price && priceOfTruth) {
                    if (priceOfTruth.innerText.trim() !== price.innerText.trim()) {
                        price.innerText = priceOfTruth.innerText.trim();
                    }
                }
            });
            observer.observe(cartPageWrapper, {childList: true, subtree: true});
        }

        function getForm() {
            var formWrapper = '<section class="formWrapper" id="formWrapper"></section>';
            $('#sign-in').after(formWrapper);

            var pageUrl = '/login';
            $.get(pageUrl, function (data) {
                var form = $(data).find('#loginPage form[name="loginForm"]');
                $.when(form.clone(true, true).appendTo('section.formWrapper')).then(function () {
                    $("#stayLoggedIn").closest("div").css({
                        "display": "flex",
                        "justify-content": "flex-start",
                        "align-items": "center",
                        "margin-bottom": "10px",
                        "width": "100%"
                    });
                    //submit form manually
                    $('section.formWrapper form[name="loginForm"]').submit(function (event) {
                        event.preventDefault();
                        var $form = $(this);
                        var url = $form.attr('action');

                        //this is an array of objects of type {name: 'propertyKey', value: 'propertyValue'}
                        var formData = $form.serialize();

                        var posting = $.post(url, formData);

                        posting.done(function (html) {

                            //put html in jQuery object and find #errorBox .error
                            var content = $(html);
                            var error = content.find('#errorBox .error').text();

                            var loginError = '';
                            loginError += '<div id="errorBox" class="error">';
                            loginError += '<div class="error">';
                            loginError += error;
                            loginError += '</div>';
                            loginError += '</div>';

                            if (error.length > 0) {
                                // show an error
                                $('#formWrapper').append(loginError);

                            } else {
                                //redirect to /payment page
                                window.location.replace('/payment');
                            }

                        });
                    });
                });
            });

            var closeFormIcon = '';
            closeFormIcon += '<div class="closeFormIcon">';
            closeFormIcon += '<?xml version="1.0" encoding="UTF-8"?>';
            closeFormIcon += '<svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">';
            closeFormIcon += '<g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">';
            closeFormIcon += '<g id="icons/plus" transform="translate(5.142136, 5.142136) rotate(45.000000) translate(-5.142136, -5.142136) translate(-4.857864, -4.857864)" fill="#62878F" fill-rule="nonzero">';
            closeFormIcon += '<rect id="Rectangle" x="4.28571429" y="9.28571429" width="11.4285714" height="1.42857143" rx="0.714285714"></rect>';
            closeFormIcon += '<path d="M5,9.28571429 L15,9.28571429 C15.3944891,9.28571429 15.7142857,9.60551089 15.7142857,10 C15.7142857,10.3944891 15.3944891,10.7142857 15,10.7142857 L5,10.7142857 C4.60551089,10.7142857 4.28571429,10.3944891 4.28571429,10 C4.28571429,9.60551089 4.60551089,9.28571429 5,9.28571429 Z" id="Rectangle" transform="translate(10.000000, 10.000000) rotate(90.000000) translate(-10.000000, -10.000000) "></path>';
            closeFormIcon += '</g>';
            closeFormIcon += '</g>';
            closeFormIcon += '</svg>';
            closeFormIcon += '</div>';
            $('#formWrapper').append(closeFormIcon);
        }

        var signIn = document.querySelector("#sign-in a");
        if (signIn) {
            signIn.addEventListener("click", function (e) {
                var _this = this;
                e.preventDefault();
                $(_this).parents('#sign-in').fadeOut(300);
                getForm();

            });

            $(document).on('click', '.closeFormIcon svg', function () {
                $('#sign-in').fadeIn(300);
                $(this).parents('#formWrapper').fadeOut(300, function () {
                    this.remove();
                });
            });
        }
    })();
}

(function () {
    var continue_shopping = document.getElementById("continue_shopping");
    var saveForLaterTopSpan = document.querySelector("#saveForLater-top span");

    function attachContinueShoppingEvent(element) {
        if (element) {
            element.addEventListener("click", function () {
                var last_category = getCookie("last_visited_category");
                location.href = last_category ? last_category : document.referrer.indexOf(window.location.host) !== -1 ? document.referrer : window.location.host;
            });
        }
    }

    function attachSaveForLaterTopEvent(element) {
        if (element) {
            element.addEventListener("click", function () {
                var saveForLaterTab = document.querySelector(".items_tabs .myaccHdr.saveForLater");
                if (!saveForLaterTab) return;

                saveForLaterTab.click();
                saveForLaterTab.scrollIntoView({behavior: "smooth"});
            });
        }
    }

    if (window.page === "cart") {
        attachContinueShoppingEvent(continue_shopping);
        attachSaveForLaterTopEvent(saveForLaterTopSpan);
    }

    var bannerTimer = null;
    var savedItemsTimer = null;

    var observer = new MutationObserver(function () {
        if (bannerTimer) clearTimeout(bannerTimer);
        bannerTimer = setTimeout(function () {
            var banner = document.querySelector(".preloaded_lightbox");
            var placeholder = document.querySelector("[id^='banner-spacer-']");
            if (banner && placeholder) {
                var bannerHeight = banner.getBoundingClientRect().height;
                var placeholderHeight = placeholder.getBoundingClientRect().height;
                if (bannerHeight !== placeholderHeight) {
                    document.documentElement.style.setProperty("--bannerHeight", bannerHeight + "px");
                }
            }
        }, 100);

        if (window.page === "cart") {
            if (savedItemsTimer) clearTimeout(savedItemsTimer);
            savedItemsTimer = setTimeout(function () {
                var saveForLaterTop = document.getElementById("saveForLater-top");
                var saveForLaterBottom = document.querySelector("#my_saved_items.saveForLater");
                var wishList = document.querySelector("#listItems.wishList")
                if (saveForLaterTop && saveForLaterBottom) {
                    saveForLaterTop.classList.toggle("show", !!saveForLaterBottom.querySelector(".lineitem"));
                }

                if (wishList) {
                    var wishListItems = !!wishList.querySelector(".lineitem");
                    var title = document.querySelector(".myaccHdr.wishList");
                    var saveForLaterTab = document.querySelector(".items_tabs .myaccHdr.saveForLater");
                    if (!wishListItems && title && !title.classList.contains("hide")) {
                        title.classList.add("hide");
                        title.classList.remove("selected");
                        if (saveForLaterTab && !!saveForLaterBottom.querySelector(".lineitem")) {
                            saveForLaterTab.click();
                        }
                    }
                }
            }, 100);

            var continue_shoppingNew = document.getElementById("continue_shopping");
            var saveForLaterTopNew = document.querySelector("#saveForLater-top span");
            if (continue_shoppingNew !== continue_shopping) attachContinueShoppingEvent(continue_shoppingNew);
            if (saveForLaterTopNew !== saveForLaterTopSpan) attachSaveForLaterTopEvent(saveForLaterTopNew);
        }
    });
    observer.observe(document.body, {subtree: true, childList: true, attributes: true});
})();

(function () {

    var timer1 = null;

    function expandPromoCode(force) {
        if (timer1) clearTimeout(timer1);

        timer1 = setTimeout(function () {
            if (!force) return;

            var sidebarPromo = document.getElementById("sidebarPromo");
            if (!sidebarPromo) return;

            var sign = sidebarPromo.querySelector(".right");
            if (!sign) return;

            if (!sign.classList.contains("selected")) {
                var trigger = sidebarPromo.querySelector(".dropLink");
                if (!trigger) return;

                trigger.click();
            }
        }, 100);
    }

    expandPromoCode(isMobileOrTabletWidth);


    window.addEventListener("resize", function () {
        isMobileDevice = this.innerWidth <= 767;
        isMobileOrTabletWidth = this.innerWidth <= 1024;
        expandPromoCode(isMobileOrTabletWidth);
    });

    window.addEventListener("beforeunload", function () {
        setCookie("promoCodeExpanded", "false", 365, "/");
    });
})();
